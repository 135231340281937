<template>
    <div>
        <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
            <strong>Cadastrar</strong>
        </button>  
        <br>
        <br>

        <div class="modal" v-bind:class="{ 'is-active': isProcessing }">
            <div class="modal-background"></div>
            <div class="modal-content">
                <progress class="progress is-large is-primary" max="100">50%</progress>
            </div>
        </div>        

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title" v-if="!this.mostraImportarModelo">Cadastrar Versão</p>
                <p class="modal-card-title" v-if="this.mostraImportarModelo">Importar Versão</p>
                <button class="delete" aria-label="close" v-on:click="resetFlags()"></button>
                </header>
                <section class="modal-card-body">
                    <form v-if="!this.mostraImportarModelo">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <input class="input" type="text" placeholder="Informe a descrição da versão" v-model="versaoPlanoContas.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Vigência</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <input class="input" type="date" placeholder="Informe a vigência" v-model="versaoPlanoContas.vigencia">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentários</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Comente sobre essa versão" v-model="versaoPlanoContas.comentario">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </form>  

                    <form v-if="this.mostraImportarModelo">
                        <div class="field">
                            <label class="label"><small>Selecione um modelo para importação</small></label>

                            <div class="control">
                                <select class="select is-fullwidth" v-model="cmbModeloParaImportacao">
                                    <option value="-1">ANS (Modelo)</option>
                                    <option value="-2">Gerencial (Modelo)</option>
                                    <option :disabled="versao.id==versaoPlanoContasSelecionado.id" v-for="versao in versoesPlanosContas" :key="versao.id" :value="versao.id">
                                        {{versao.descricao}}
                                    </option>
                                </select>
                            </div>
                        </div>    
                    </form>  

                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" @click="save()" v-if="!mostraImportarModelo">Salvar</button>
                    <button class="button is-success" @click="importar()" v-if="mostraImportarModelo">Importar</button>
                    <button class="button" v-on:click="resetFlags()">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns" v-show="this.showInfo">
            <div class="column"></div>
            <div class="column is-four-fifths">
                <div class="card">
                    <header class="panel-heading has-background-link-dark has-text-light">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Versões</small>
                                </p>
                            </div>    
                        </nav>    
                    </header>
                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                                <thead>
                                    <tr>
                                        <th><small>Descrição</small></th>
                                        <th><small>Vigência</small></th>
                                        <th colspan="3" class="has-text-centered" style="width:15px"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(versaoPlanoContas, index) in versoesPlanosContas" :key="versaoPlanoContas.id">
                                        <td><small>{{versaoPlanoContas.descricao}}</small></td>
                                        <td><small>{{versaoPlanoContas.vigencia|dateDDMMYYYY}}</small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Editar" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Excluir" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Importar modelo" @click="importarModelo(versaoPlanoContas)"><span class="fas fa-solid fa-file-import"></span></button></small></td>
                                    </tr> 
                                </tbody>    
                            </table>
                        </div>  
                    </div> 
                </div>
            </div>

            <div class="column"></div>

        </div>    

    </div>        

</template>

<script>
    import {http} from '../../services/config'
    import moment from 'moment'

    export default {
        name: "PlanoDeContas",

        data(){
            return{
                versaoPlanoContas: {
                    id: null,
                    descricao: null,
                    vigencia: null,
                    usuario: null,
                    comentario: null
                },
                versoesPlanosContas: [],
                isShowModal: false,
                isProcessing: false,
                versaoPlanoContasSelecionado: null,
                descricaoVersaoPlanoContas: null,
                mostraImportarModelo: false,
                cmbModeloParaImportacao: '-1',
                showInfo: true
            }
        },

        methods: {
            callListAPI: function(nome) {
                http.get(nome).then(response =>{
                    this.versoesPlanosContas = response.data.results
                })
            },

            resetFlags: function() {
                this.isShowModal = false
            },  
            
            importarModelo: function(versaoPlanoContas) {
                this.versaoPlanoContasSelecionado = versaoPlanoContas
                this.mostraImportarModelo = true
                this.isShowModal = true
            },

            clearFields: function() {
                this.versaoPlanoContas = { id: null,
                    descricao: null,
                    vigencia: null,
                    usuario: null,
                    comentario: null,
                    index: null
                }
            },

            importar: function() {
                if (window.confirm('Confirma importação do modelo selecionado?')) {
                    this.isProcessing = true
                    this.isShowModal = false
                    http.put('/versoesPlanoContas/importarModelo/'+this.versaoPlanoContasSelecionado.id, this.cmbModeloParaImportacao).then(response =>{
                        if (response.status == 200) {
                            alert('Importação do modelo selecionado foi concluída!')
                        } else if (response.status == 204) {
                            alert('Arquivo não carregado! Possíveis motivos: Não informado ou não são do tipo XLSX.')
                        }
                        this.isProcessing = false
                        this.isShowModal = true
                    }).catch(e => {
                        this.isProcessing = false
                        this.isShowModal = true
                        console.log(e);
                    })
                }
            },

            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                this.versaoPlanoContas.id = this.versoesPlanosContas[index].id
                this.versaoPlanoContas.descricao = this.versoesPlanosContas[index].descricao
                this.versaoPlanoContas.vigencia = this.versoesPlanosContas[index].vigencia
                this.versaoPlanoContas.comentario = this.versoesPlanosContas[index].comentario
                this.versaoPlanoContas.usuario = this.versoesPlanosContas[index].usuario
                this.versaoPlanoContas.index = index
                this.isShowModal = true
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    http.delete('/versoesPlanoContas/excluir/'+this.versoesPlanosContas[index].id).then(response =>{
                        this.versoesPlanosContas.splice(index, 1)
                        if (response.status == 204) {
                            alert('Registro excluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            save: function(){
                var data

                if (this.versaoPlanoContas.descricao == null) {
                    alert('Descrição da versão não preenchida!')
                    return
                }

                if (this.versaoPlanoContas.vigencia == null || this.versaoPlanoContas.vigencia == "Invalid date") {
                    alert('Vigência não preenchida!')
                    return
                }

                data = {id: this.versaoPlanoContas.id,
                    descricao: this.versaoPlanoContas.descricao,
                    vigencia: moment(this.versaoPlanoContas.vigencia).format('DD/MM/YYYY'),
                    comentario: this.versaoPlanoContas.comentario,
                    usuario: this.$store.state.idUser
                }

                let formData = new FormData()

                formData.append('id', data.id)
                formData.append('descricao', data.descricao)
                formData.append('vigencia', data.vigencia)
                formData.append('comentario', data.comentario)
                formData.append('usuario', data.usuario)

                if (this.versaoPlanoContas.id == null) {
                    http.post('/versoesPlanoContas/adicionar', formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }}).then(response =>{
                        if (response.data.id != undefined && response.data.id != '') {
                            this.versaoPlanoContas.id = response.data.id

                            this.versoesPlanosContas.push(this.versaoPlanoContas)

                            this.showInfo = true
                            this.clearFields()
                        } else {
                            alert(response.request.responseText)
                        }
                    }).catch(e => {
                        console.log(e);
                    })

                } else {
                    http.put('/versoesPlanoContas/editar/'+this.versaoPlanoContas.id, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }}).then(response =>{
                        this.versoesPlanosContas[this.versaoPlanoContas.index].comentario = this.versaoPlanoContas.comentario
                        this.versoesPlanosContas[this.versaoPlanoContas.index].descricao = response.data.descricao
                        this.versoesPlanosContas[this.versaoPlanoContas.index].vigencia = this.versaoPlanoContas.vigencia
                    }).catch(e => {
                        console.log(e);
                    })
                }

            },

        },
        mounted: function() {
            this.callListAPI('versoesPlanoContas/listar')
        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>