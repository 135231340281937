import { render, staticRenderFns } from "./ValorReferenciaIndicador.vue?vue&type=template&id=13ca7a74&scoped=true"
import script from "./ValorReferenciaIndicador.vue?vue&type=script&lang=js"
export * from "./ValorReferenciaIndicador.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ca7a74",
  null
  
)

export default component.exports