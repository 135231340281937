<template>
    <div>
        <br>
        <h5 class="title is-5">Períodos
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
                Criar
            </button>  
        </h5>

        <hr>
        
        <form>
            <div class="field is-horizontal">
                <div class="field">
                    <input class="input" type="date" placeholder="Informe a data inicial" v-model="dataInicial">
                </div>

                <div class="field">
                    <input class="input" type="date" placeholder="Informe a data final" v-model="dataFinal">
                </div>

                <div class="field">                    
                    <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                </div>    
            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isProcessing }">
            <div class="modal-background"></div>
            <div class="modal-content">
                <progress class="progress is-large is-primary" max="100">50%</progress>
            </div>
        </div>        

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Criar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form v-if="!this.mostraFechamentoMensal">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Período</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <input class="input" type="date" placeholder="Informe a data inicial" v-model="periodo.periodo" :disabled="periodo.isFechado">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Última Negociação (BR)</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <input class="input" type="date" placeholder="Informe a data inicial" v-model="periodo.ultimoDiaNegociacaoBR" :disabled="periodo.isFechado">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Última Negociação (US)</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <input class="input" type="date" placeholder="Informe a data inicial" v-model="periodo.ultimoDiaNegociacaoUS" :disabled="periodo.isFechado">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Dólar</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe a cotação do dólar" v-model="periodo.usdbrl" :disabled="periodo.isFechado">
                                </div>
                            </div>
                        </div>

                    </form>  

                    <form v-if="this.mostraFechamentoMensal">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Ativo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="fechamento.ativo" :disabled="fechamento.isAutomatico || periodoSelecionado.isFechado">
                                            <option v-for="ativo in ativos" :key="ativo.id" :value="ativo">
                                                {{ativo.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Quantidade</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe a qunatidade" v-model="fechamento.quantidade" :disabled="fechamento.isAutomatico || periodoSelecionado.isFechado">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Patrimônio</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor do ativo no período" v-model="fechamento.totalAtivo" :disabled="(fechamento.isAutomatico && fechamento.cotacao != null) || periodoSelecionado.isFechado">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Compras</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor das aplicações no período" v-model="fechamento.totalCompra" :disabled="fechamento.isAutomatico || periodoSelecionado.isFechado">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Vendas</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor dos resgates no período" v-model="fechamento.totalVenda" :disabled="fechamento.isAutomatico || periodoSelecionado.isFechado">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Fator (Próx. Período)</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe fator do próximo período" v-model="fechamento.fatorProximoPeriodo" :disabled="periodoSelecionado.isFechado">
                                </div>
                            </div>
                        </div>

                    </form>  
                    
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>     

        <div class="columns" v-show="this.showInfo">
            <div class="column">
                <div class="table-container">                
                    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                                <th><small>Mês/Ano</small></th>
                                <th><small>Última Negociação (BR)</small></th>
                                <th><small>Última Negociação (US)</small></th>
                                <th><small>Mês/Ano</small></th>
                                <th><small>Status</small></th>
                                <th colspan="6" class="has-text-centered"><small>Ações</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(periodo, index) in periodos" :key="periodo.id">
                                <td><small>{{periodo.mesAno}}</small></td>
                                <td><small>{{periodo.ultimoDiaNegociacaoBR|dateDDMMYYYY}}</small></td>
                                <td><small>{{periodo.ultimoDiaNegociacaoUS|dateDDMMYYYY}}</small></td>
                                <td v-if="!periodo.isFechado"><small>Aberto</small></td>
                                <td v-if="periodo.isFechado"><small>Fechado</small></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)" :disabled="mostraModelos"><span class="fas fa-edit"></span></button></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" :disabled="mostraModelos"><span class="fas fa-trash-alt"></span></button></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="processarPeriodo(index)" :disabled="mostraModelos"><span class="fas fa-play-circle"></span></button></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="fecharPeriodo(index)" :disabled="mostraModelos"><span class="fas fa-lock"></span></button></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="reabrirPeriodo(index)" :disabled="mostraModelos"><span class="fas fa-unlock"></span></button></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="callModelos(periodo)" :disabled="mostraModelos"><span class="fas fa-th-list"></span></button></td>
                            </tr>
                        </tbody>    
                    </table>
                </div>    
                <br>
            </div>

            <div class="column" v-show="this.mostraModelos">

                <div class="card">

                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Modelos</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraModelos = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    

                    </header>

                    <div class="card-content">

                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidthtable is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Modelo</small></th>
                                        <th colspan="1" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="modeloCarteira in modelosCarteiras" :key="modeloCarteira.id">
                                        <td class="has-text-left"><small>{{modeloCarteira.descricao}}</small></td>
                                        <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="callFechamentoListAPI(modeloCarteira)" :disabled="mostraFechamentoMensal"><span class="fas fa-th-list"></span></button></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>

                <br>

                <article  class="panel has-text-light" v-show="this.mostraFechamentoMensal" >
                    <header class="panel-heading">
                        <nav class="level">
                            <div class="level-left">
                                <small>{{titlePanelFechamento}}</small>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraFechamentoMensal = false"><span class="fa fa-times has-text-black"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <content>
                        <div class="panel-block has-background-light">
                            <div class="table-container">                
                                <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th class="has-text-left"><small>Ativo</small></th>
                                            <th class="has-text-centered"><small>Quantidade</small></th>
                                            <th class="has-text-centered"><small>Patrimônio</small></th>
                                            <th class="has-text-centered"><small>Compras</small></th>
                                            <th class="has-text-centered"><small>Vendas</small></th>
                                            <th colspan="2" class="has-text-centered"><small>Ações</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(fechamento, index) in fechamentos" :key="fechamento.id">
                                            <td class="has-text-left"><small>{{fechamento.ativo.descricao}}</small></td>
                                            <td class="has-text-centered"><small>{{fechamento.quantidade|numberFormat}}</small></td>
                                            <td class="has-text-centered"><small>{{fechamento.totalAtivo|numberFormat}}</small></td>
                                            <td class="has-text-centered"><small>{{fechamento.totalCompra|numberFormat}}</small></td>
                                            <td class="has-text-centered"><small>{{fechamento.totalVenda|numberFormat}}</small></td>
                                            <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></td>
                                            <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" :disabled="periodoSelecionado.isFechado"><span class="fas fa-trash-alt"></span></button></td>
                                        </tr>
                                    </tbody>    
                                </table>
                            </div>    
                        </div>
                    </content>
                </article > 

            </div>
            
            <div class="column" v-show="!this.mostraFechamentoMensal"></div>

        </div>    

    </div>        

</template>

<script>
    import {http} from '../../services/config'
    import moment from 'moment'

    export default {
        name: "Periodo",

        data(){
            return{
                periodo: {
                    id: null,
                    periodo: null,
                    mesAno: null,
                    mes: null,
                    bimestre: null,
                    trimestre: null,
                    quadrimestre: null,  
                    semestre: null,  
                    ano: null,
                    usdbrl: null,
                    isFechado: null,
                    ultimoDiaNegociacaoBR: null,
                    ultimoDiaNegociacaoUS: null,
                    index: null
                },
                periodos: [],
                fechamento: { id: null,
                    periodo: null,
                    ativo: null,
                    quantidade: null,
                    cotacao: null,
                    totalAtivo: null,
                    totalAtivoAnterior: null,
                    valorProvento: null,
                    totalCompra: null,
                    TotalVenda: null,
                    valorRenda: null,
                    resultado: null,
                    rentabilidade: null,
                    ganhoPerdaCapital: null,
                    precoMedioCompra: null,
                    precoMedioSaldo: null,
                    isAutomatico: null,
                    fator: null,
                    fatorProximoPeriodo: null,
                    index: null
                },
                fechamentos: [],
                ativos: [],
                dataInicial: null,
                dataFinal: null,
                previous: null,
                next: null,
                page: null,
                numpages: null,
                isShowModal: false,
                showInfo: false,
                mostraFechamentoMensal: false,
                mostraModelos: false,
                isSemEdicao: false,
                isProcessing: false,
                periodoSelecionado: null,
                titlePanelFechamento: null,
                modeloSelecionado: null
            }
        },

        methods: {
            clearFields: function() {
                if (!this.mostraFechamentoMensal) {
                    this.periodo = { id: null,
                        periodo: null,
                        mesAno: null,
                        mes: null,
                        bimestre: null,
                        trimestre: null,
                        quadrimestre: null,  
                        semestre: null,  
                        ano: null,
                        usdbrl: null,
                        isFechado: false,
                        ultimoDiaNegociacaoBR: null,
                        ultimoDiaNegociacaoUS: null,
                        periodoAnterior: null,
                        proximoPeriodo: null,
                        index: null }
                    }
                else {
                    this.fechamento = { id: null,
                        periodo: null,
                        ativo: null,
                        quantidade: 0,
                        cotacao: null,
                        totalAtivo: 0,
                        totalAtivoAnterior: 0,
                        valorProvento: 0,
                        totalCompra: 0,
                        totalVenda: 0,
                        valorRenda: 0,
                        resultado: 0,
                        rentabilidade: 0,
                        ganhoPerdaCapital: 0,
                        precoMedioCompra: 0,
                        precoMedioSaldo: 0,
                        isAutomatico: false,
                        fator: 1,
                        fatorProximoPeriodo: 1,
                        usuario: this.$store.state.idUser,
                        index: null }
                }
            },

            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                if (this.mostraFechamentoMensal) {
                    this.fechamento.id = this.fechamentos[index].id
                    this.fechamento.periodo = this.fechamentos[index].periodo
                    this.fechamento.ativo = this.fechamentos[index].ativo
                    this.fechamento.quantidade = this.fechamentos[index].quantidade
                    this.fechamento.cotacao = this.fechamentos[index].cotacao
                    this.fechamento.totalAtivo = this.fechamentos[index].totalAtivo
                    this.fechamento.totalAtivoAnterior = this.fechamentos[index].totalAtivoAnterior
                    this.fechamento.valorProvento = this.fechamentos[index].valorProvento
                    this.fechamento.totalCompra = this.fechamentos[index].totalCompra
                    this.fechamento.totalVenda = this.fechamentos[index].totalVenda
                    this.fechamento.valorRenda = this.fechamentos[index].valorRenda
                    this.fechamento.resultado = this.fechamentos[index].resultado
                    this.fechamento.rentabilidade = this.fechamentos[index].rentabilidade
                    this.fechamento.ganhoPerdaCapital = this.fechamentos[index].ganhoPerdaCapital
                    this.fechamento.precoMedioCompra = this.fechamentos[index].precoMedioCompra
                    this.fechamento.precoMedioSaldo = this.fechamentos[index].precoMedioSaldo
                    this.fechamento.isAutomatico = this.fechamentos[index].isAutomatico
                    this.fechamento.fator = this.fechamentos[index].fator
                    this.fechamento.fatorProximoPeriodo = this.fechamentos[index].fatorProximoPeriodo
                    this.fechamento.usuario = this.fechamentos[index].usuario
                    this.fechamento.index = index
                    this.isShowModal = true
                } else {
                    this.periodo.id = this.periodos[index].id
                    this.periodo.periodo = this.periodos[index].periodo
                    this.periodo.usdbrl = this.periodos[index].usdbrl 
                    this.periodo.isFechado = this.periodos[index].isFechado   
                    this.periodo.ultimoDiaNegociacaoBR = this.periodos[index].ultimoDiaNegociacaoBR
                    this.periodo.ultimoDiaNegociacaoUS = this.periodos[index].ultimoDiaNegociacaoUS
                    this.periodo.periodoAnterior = this.periodos[index].periodoAnterior
                    this.periodo.proximoPeriodo = this.periodos[index].proximoPeriodo

                    this.periodo.index = index

                    if (! this.isSemEdicao) {
                        this.isShowModal = true
                    }
                }
            },    

            processarPeriodo: function(index){
                if (this.periodos[index].isFechado) {
                    alert("Período fechado!")
                    return
                }
                if (window.confirm('Confirma o processamento deste período?')) {
                    this.isProcessing = true
                    http.put('/periodos/processar/'+this.periodos[index].id).then(response =>{
                        if (response.status == 204) {
                            this.isProcessing = false
                            alert('Processamento concluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            fecharPeriodo: function(index){
                if (this.periodos[index].isFechado) {
                    alert("Período fechado!")
                    return
                }
                if (window.confirm('Confirma o fechamento deste período?')) {
                    this.isProcessing = true
                    http.put('/periodos/fechar/'+this.periodos[index].id).then(response =>{
                        if (response.status == 204) {
                            this.periodo.isFechado = true
                            this.periodos[index].isFechado = true
                            this.isProcessing = false
                            alert('Fechamento concluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            reabrirPeriodo: function(index){
                if (!this.periodos[index].isFechado) {
                    alert("Período já está aberto!")
                    return
                }
                if (window.confirm('Confirma a reabertura deste período?')) {
                    this.isSemEdicao = true
                    this.edit(index)
                    this.periodo.isFechado = false
                    this.save()
                    this.isSemEdicao = false
                }
            },

            erase: function(index){
                if (!this.mostraFechamentoMensal) {
                    if (this.periodos[index].isFechado) {
                        alert("Período fechado!")
                        return
                    }

                    if (window.confirm('Confirma exclusão?')) {
                        http.delete('/periodos/excluir/'+this.periodos[index].id).then(response =>{
                            this.periodos.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                } else {
                    if (window.confirm('Confirma exclusão?')) {
                        http.delete('/fechamentos/excluir/'+this.fechamentos[index].id).then(response =>{
                            this.fechamentos.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }

                }    
            },

            save: function(){
                var data

                if (!this.mostraFechamentoMensal) {

                    if (this.periodo.periodo == null) {
                        alert('Período não informado!')
                        return
                    }

                    if (this.periodo.ultimoDiaNegociacaoBR == null) {
                        alert('Último dia de negociação no mercado brasileiro não informado!')
                        return
                    }

                    if (moment(this.periodo.periodo).format('YYYY/MM') != moment(this.periodo.ultimoDiaNegociacaoBR).format('YYYY/MM')) {
                        alert('Último dia de negociação no mercado brasileiro fora do período informado!')
                        return
                    }

                    if (this.periodo.usdbrl == null) {
                        alert('Valor do dólar mensal não informado!')
                        return
                    }

                    if (this.periodo.ultimoDiaNegociacaoUS == null && this.periodo.usdbrl != 0) {
                        alert('Último dia de negociação no mercado americano não informado!')
                        return
                    }

                    if (this.periodo.ultimoDiaNegociacaoUS != null && this.periodo.usdbrl == 0) {
                        alert('Valor da cotação do dólar não informado!')
                        return
                    }


                    if (moment(this.periodo.periodo).format('YYYY/MM') != moment(this.periodo.ultimoDiaNegociacaoUS).format('YYYY/MM') && this.periodo.ultimoDiaNegociacaoUS != null ) {
                        alert('Último dia de negociação no mercado americano fora do período informado!')
                        return
                    }

                    data = {id: this.periodo.id,
                        periodo: moment(this.periodo.periodo).format('DD/MM/YYYY'),
                        mesAno: moment(this.periodo.periodo).format('YYYY/MM'),
                        mes: moment(this.periodo.periodo).format('MM'),
                        bimestre: null,
                        trimestre: null,
                        quadrimestre: null,
                        semestre: null,
                        usdbrl: this.periodo.usdbrl,
                        isFechado: this.periodo.isFechado,
                        ultimoDiaNegociacaoBR: moment(this.periodo.ultimoDiaNegociacaoBR).format('DD/MM/YYYY'),
                        ultimoDiaNegociacaoUS: moment(this.periodo.ultimoDiaNegociacaoUS).format('DD/MM/YYYY'),
                        periodoAnterior: moment(this.periodo.periodo).add(-1, 'M').format('DD/MM/YYYY'),
                        proximoPeriodo: moment(this.periodo.periodo).add(1, 'M').format('DD/MM/YYYY'),
                        ano: null
                    }

                    // Definindo o campo BIMESTRE
                    if (data.mes == "01" || data.mes == "02") {
                        data.bimestre = "1B"+moment(this.periodo.periodo).format('YYYY')
                    } else if (data.mes == "03" || data.mes == "04") {
                        data.bimestre = "2B"+moment(this.periodo.periodo).format('YYYY')
                    } else if (data.mes == "05" || data.mes == "06") {
                        data.bimestre = "3B"+moment(this.periodo.periodo).format('YYYY')
                    } else if (data.mes == "07" || data.mes == "08") {
                        data.bimestre = "4B"+moment(this.periodo.periodo).format('YYYY')
                    } else if (data.mes == "09" || data.mes == "10") {
                        data.bimestre = "5B"+moment(this.periodo.periodo).format('YYYY')
                    } else {
                        data.bimestre = "6B"+moment(this.periodo.periodo).format('YYYY')
                    }

                    // Definindo o campo TRIMESTRE
                    if (data.mes == "01" || data.mes == "02" || data.mes == "03") {
                        data.trimestre = "1T"+moment(this.periodo.periodo).format('YYYY')
                    } else if (data.mes == "04" || data.mes == "05" || data.mes == "06") {
                        data.trimestre = "2T"+moment(this.periodo.periodo).format('YYYY')
                    } else if (data.mes == "07" || data.mes == "08" || data.mes == "09") {
                        data.trimestre = "3T"+moment(this.periodo.periodo).format('YYYY')
                    } else {
                        data.trimestre = "4T"+moment(this.periodo.periodo).format('YYYY')
                    }

                    // Definindo o campo QUADRIMESTRE
                    if (data.mes == "01" || data.mes == "02" || data.mes == "03" || data.mes == "04") {
                        data.quadrimestre = "1Q"+moment(this.periodo.periodo).format('YYYY')
                    } else if (data.mes == "05" || data.mes == "06" || data.mes == "07" || data.mes == "08") {
                        data.quadrimestre = "2Q"+moment(this.periodo.periodo).format('YYYY')
                    } else {
                        data.quadrimestre = "3Q"+moment(this.periodo.periodo).format('YYYY')
                    }

                    // Definindo o campo SEMESTRE
                    if (data.mes <= "06") {
                        data.semestre = "1S"+moment(this.periodo.periodo).format('YYYY')
                    } else {
                        data.semestre = "2S"+moment(this.periodo.periodo).format('YYYY')
                    }

                    // Definido o campo ANO
                    data.ano = moment(this.periodo.periodo).format('YYYY')

                    if (this.periodo.id == null) {
                        http.post('/periodos/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.periodo.id = response.data.id
                                this.periodo.mesAno = data.mesAno
                                this.periodo.mes = data.mes
                                this.periodo.bimestre = data.bimestre
                                this.periodo.trimestre = data.trimestre
                                this.periodo.quadrimestre = data.quadrimestre
                                this.periodo.semestre = data.semestre
                                this.periodo.ano = data.ano
                                this.periodo.usdbrl = data.usdbrl
                                this.periodo.isFechado = data.isFechado
                                this.periodo.ultimoDiaNegociacaoBR = data.ultimoDiaNegociacaoBR
                                this.periodo.ultimoDiaNegociacaoUS = data.ultimoDiaNegociacaoUS
                                this.periodo.periodoAnterior = data.periodoAnterior
                                this.periodo.proximoPeriodo = data.proximoPeriodo
                                this.periodos.push(this.periodo)
                                this.showInfo = true
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else {
                        http.put('/periodos/editar/'+this.periodo.id, data).then(response =>{
                            this.periodos[this.periodo.index].ultimoDiaNegociacaoBR = response.data.ultimoDiaNegociacaoBR
                            this.periodos[this.periodo.index].ultimoDiaNegociacaoUS = response.data.ultimoDiaNegociacaoUS
                            this.periodos[this.periodo.index].periodoAnterior = response.data.periodoAnterior
                            this.periodos[this.periodo.index].proximoPeriodo = response.data.proximoPeriodo
                            this.periodos[this.periodo.index].usdbrl = response.data.usdbrl
                            this.periodos[this.periodo.index].isFechado = response.data.isFechado
                        }).catch(e => {
                            console.log(e);
                        })
                    }

                } else {
                    if (this.fechamento.ativo == null) {
                        alert('Ativo não informado!')
                        return
                    }

                    if (this.fechamento.quantidade == null || this.fechamento.quantidade == '') {
                        this.fechamento.quantidade = 0
                    }

                    if (this.fechamento.totalAtivo == null || this.fechamento.totalAtivo == '') {
                        this.fechamento.totalAtivo = 0
                    }

                    if (this.fechamento.totalCompra == null || this.fechamento.totalCompra == '') {
                        this.fechamento.totalCompra = 0
                    }

                    if (this.fechamento.totalVenda == null || this.fechamento.totalVenda == '') {
                        this.fechamento.totalVenda = 0
                    }

                    data = {id: this.fechamento.id,
                        periodo: this.periodoSelecionado.id,
                        modeloCarteira: this.modeloSelecionado.id,
                        ativo: this.fechamento.ativo.id,
                        quantidade: this.fechamento.quantidade,
                        cotacao: null,
                        totalAtivo: this.fechamento.totalAtivo,
                        totalAtivoAnterior: this.fechamento.totalAtivoAnterior,
                        valorProvento: this.fechamento.valorProvento,
                        totalCompra: this.fechamento.totalCompra,
                        totalVenda: this.fechamento.totalVenda,
                        valorRenda: this.fechamento.valorRenda,
                        resultado: this.fechamento.resultado,
                        rentabilidade: this.fechamento.rentabilidade,
                        ganhoPerdaCapital: this.fechamento.ganhoPerdaCapital,
                        precoMedioCompra: this.fechamento.precoMedioCompra,
                        precoMedioSaldo: this.fechamento.precoMedioSaldo,
                        isAutomatico: this.fechamento.isAutomatico,
                        fator: this.fechamento.fator,
                        fatorProximoPeriodo: this.fechamento.fatorProximoPeriodo,
                        usuario: this.$store.state.idUser
                    }

                    if (this.fechamento.cotacao != null) {
                        data.cotacao = this.fechamento.cotacao.id
                    } else {
                        data.cotacao = null
                    }

                    if (this.fechamento.id == null) {
                        http.post('/fechamentos/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.fechamento.id = response.data.id
                                this.fechamentos.push(this.fechamento)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })

                    } else {
                        http.put('/fechamentos/editar/'+this.fechamento.id, data).then(response =>{
                            this.fechamentos[this.fechamento.index].ativo = this.fechamento.ativo
                            this.fechamentos[this.fechamento.index].quantidade = response.data.quantidade
                            this.fechamentos[this.fechamento.index].totalAtivo = response.data.totalAtivo
                            this.fechamentos[this.fechamento.index].totalCompra = response.data.totalCompra                           
                            this.fechamentos[this.fechamento.index].totalVenda = response.data.totalVenda
                            this.fechamentos[this.fechamento.index].fatorProximoPeriodo = response.data.fatorProximoPeriodo
                            this.fechamentos[this.fechamento.index].usuario = response.data.usuario
                        }).catch(e => {
                            console.log(e);
                        })
                    }                    

                }

            },

            callListAPI: function(nome) {
                http.get(nome, {params: {
                    dataInicial: this.dataInicial,
                    dataFinal: this.dataFinal
                }}).then(response => {
                    this.periodos = response.data
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages
                    if (this.periodos.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })
            },

            nextPage: function(){
                if (this.page != this.numpages) {
                    this.callListAPI(this.next)
                }
            },
            previousPage: function(){
                if (this.page != 1 ) {
                    this.callListAPI(this.previous)
                }
            },
            filterRecords: function(){
                if (this.dataInicial != null && this.dataFinal == null) {
                    alert('Data final não preenchida!')
                    return;
                } else if (this.dataInicial == null && this.dataFinal != null) {
                    alert('Data inicial não preenchida!')
                    return;
                }
                this.callListAPI('periodos/listar/')
            },
            callModelos: function(periodo) {
                this.mostraModelos = true
                this.periodoSelecionado = periodo
            },
            
            callFechamentoListAPI: function(modeloCarteira) {
                var listarTodos = 'N'
                if (confirm("Deseja listar todos os ativos?")) {
                  listarTodos = 'S'
                } 
                this.mostraFechamentoMensal = true
                this.titlePanelFechamento = "Fechamento - " + this.periodoSelecionado.mesAno
                this.modeloSelecionado = modeloCarteira
                http.get('fechamentos/listar', {params: {
                    idPeriodo: this.periodoSelecionado.id,
                    todosAtivos: listarTodos,
                    idModeloCarteira: modeloCarteira.id
                }}).then(response => {
                    this.fechamentos = response.data.results
                })
            }
        },

        mounted: function() {
            http.get('ativos/listar', {
                params: {
                    paginacao: 'N'
                }
            }).then(response =>{
                this.ativos = response.data.results
            })

            http.get('modelagemCarteira/modeloCarteiraListar').then(response =>{
                this.modelosCarteiras = response.data
            })

        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>