<template>
    <div>
        <br>

        <h5 class="title is-5">Aportes
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()" :disabled="mostraClassesDeAtivos">
                Criar
            </button>  
        </h5>

        <form>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbModelo">
                                    <option value="0">TODOS OS MODELOS</option>
                                    <option v-for="modeloCarteita in modelosCarteiras" :key="modeloCarteita.id" :value="modeloCarteita.id">
                                        {{modeloCarteita.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPeriodo">
                                    <option value="0">TODOS OS PERIODOS</option>
                                    <option v-for="periodo in periodos" :key="periodo.id" :value="periodo.id">
                                        {{periodo.mesAno}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>
            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title" v-if="!this.mostraClassesDeAtivos">Aporte no Período</p>
                    <p class="modal-card-title" v-if="this.mostraClassesDeAtivos">{{titleModal}}</p>
                    <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <!-- Criar/Editar Aporte no Período -->
                    <form v-if="!this.mostraClassesDeAtivos">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Modelo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="modeloCarteiraAporte.modeloCarteira">
                                            <option v-for="modeloCarteira in modelosCarteiras" :key="modeloCarteira.id" :value="modeloCarteira">
                                                {{modeloCarteira.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Periodo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="modeloCarteiraAporte.periodo">
                                            <option v-for="periodo in periodos" :key="periodo.id" :value="periodo">
                                                {{periodo.mesAno}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Renda Ativa</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="modeloCarteiraAporte.valorRendaAtiva">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Renda Passiva</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="modeloCarteiraAporte.valorRendaPassiva"  disabled>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Renda Ações</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="modeloCarteiraAporte.valorRendaPassivaAcoes">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Renda FIIs</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="modeloCarteiraAporte.valorRendaPassivaFIIs">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Cupons de Renda Fixa</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="modeloCarteiraAporte.valorRendaPassivaRendaFixa">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Total Aporte</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="modeloCarteiraAporte.valorAporte" disabled>
                                </div>
                            </div>
                        </div>
                    </form>  

                    <!-- Editar Aporte por Classe de Ativo -->
                    <form v-if="this.mostraClassesDeAtivos">

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Realizado</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="modCarClasseAtivoAporte.valorAporteRealizado">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentário</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <textarea class="textarea" placeholder="Comentário sobre o aporte nessa classe de ativo" v-model="modCarClasseAtivoAporte.comentario"></textarea>
                                </div>
                            </div>
                        </div>

                    </form>  

                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>   

        <div class="columns" v-show="this.showInfo">
            <div class="column"></div>
            <div class="column is-four-fifths">
                <div class="table-container">                
                    <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th class="has-text-centered"><small>Modelo</small></th>
                                <th class="has-text-centered"><small>Período</small></th>
                                <th class="has-text-centered"><small>Aporte</small></th>
                                <th class="has-text-centered"><small>Renda Ativa</small></th>
                                <th class="has-text-centered"><small>Renda Passiva</small></th>
                                <th class="has-text-centered"><small>Renda Ações</small></th>
                                <th class="has-text-centered"><small>Renda FIIs</small></th>
                                <th class="has-text-centered"><small>Renda Cupons RF</small></th>
                                <th class="has-text-centered"><small>Reposição Inflação</small></th>
                                <th colspan="4" class="has-text-centered"><small>Ações</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(modeloCarteiraAporte, index) in modeloCarteiraAportes" :key="modeloCarteiraAporte.id">
                                <td class="has-text-centered"><small>{{modeloCarteiraAporte.modeloCarteira.descricao}}</small></td>
                                <td class="has-text-centered"><small>{{modeloCarteiraAporte.periodo.mesAno}}</small></td>
                                <td class="has-text-centered"><small>{{modeloCarteiraAporte.valorAporte|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{modeloCarteiraAporte.valorRendaAtiva|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{modeloCarteiraAporte.valorRendaPassiva|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{modeloCarteiraAporte.valorRendaPassivaAcoes|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{modeloCarteiraAporte.valorRendaPassivaFIIs|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{modeloCarteiraAporte.valorRendaPassivaRendaFixa|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{modeloCarteiraAporte.valorReposicaoInflacao|numberFormat}}</small></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)" :disabled="mostraClassesDeAtivos"><span class="fas fa-edit"></span></button></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" :disabled="mostraClassesDeAtivos"><span class="fas fa-trash-alt"></span></button></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="processarPeriodo(index)" :disabled="mostraClassesDeAtivos"><span class="fas fa-play-circle"></span></button></td>
                                <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="callClassesDeAtivos(index)" :disabled="mostraClassesDeAtivos"><span class="fas fa-th-list"></span></button></td>
                            </tr>
                        </tbody>    
                    </table>
                </div>    

                <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                    <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.page == 1 || this.page == null">Anterior</a>
                    <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.page == this.numpages">Próxima</a>                
                </nav>
                <br>
            </div>
            <div class="column"></div>
        </div>   

        <div class="columns" v-show="this.mostraClassesDeAtivos">
            <div class="column">
            </div>
            <div class="column is-four-fifths">
                <article  class="panel is-primary" >

                    <header class="panel-heading">
                        <p>
                            Classes de Ativos
                        </p>
                    </header>

                    <content>
                        <div class="panel-block">
                            <div class="table-container">                
                                <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                                    <thead>
                                        <tr>
                                            <th><small>Classe de Ativo</small></th>
                                            <!-- <th><small>% Desejado</small></th> -->
                                            <th><small>% Realizado</small></th>
                                            <th><small>% Part. Aporte</small></th>
                                            <th><small>Aporte</small></th>
                                            <th><small>Realizado</small></th>
                                            <th><small>Pendente</small></th>
                                            <th><small>Comentário</small></th>
                                            <th class="has-text-centered"><small>Ação</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(modCarClasseAtivoAporte, index) in modCarClasseAtivoAportes" :key="modCarClasseAtivoAporte.id">
                                            <td><small>{{modCarClasseAtivoAporte.modeloCarteiraClasseAtivo.classeAtivo.descricao}}</small></td>
                                            <!-- <td class="has-text-centered"><small>{{modCarClasseAtivoAporte.percentualDesejado|numberFormat}}</small></td> -->
                                            <td class="has-text-centered"><small>{{modCarClasseAtivoAporte.percentualReal|numberFormat}}</small></td>
                                            <td class="has-text-centered"><small>{{modCarClasseAtivoAporte.percentualParticipacaoAporte|numberFormat}}</small></td>
                                            <td class="has-text-centered has-text-weight-bold"><small>{{modCarClasseAtivoAporte.valorAporte|numberFormat}}</small></td>
                                            <td class="has-text-centered"><small>{{modCarClasseAtivoAporte.valorAporteRealizado|numberFormat}}</small></td>

                                            <td class="has-text-centered"><small>{{modCarClasseAtivoAporte.valorAporte-modCarClasseAtivoAporte.valorAporteRealizado|numberFormat}}</small></td>

                                            <td><small>{{modCarClasseAtivoAporte.comentario}}</small></td>
                                            <td class="has-text-centered"><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></td>
                                        </tr>
                                    </tbody>    
                                </table>
                            </div>    
                        </div>
                    </content>

                    <footer class="card-footer">
                        <a v-on:click="mostraClassesDeAtivos = false" class="card-footer-item">Fechar</a>
                    </footer>            
                </article > 
            </div>
            <div class="column">
            </div>
        </div>
    </div>        
</template>

<script>
    import {http} from '../../services/config'

    export default {
        name: "ModeloCarteiraAporte",

        data(){
            return{
                modeloCarteiraAporte: {
                    id: null,
                    modeloCarteira: null,
                    periodo: null,
                    valorRendaAtiva: null,
                    valorRendaPassiva: null,
                    valorRendaPassivaFIIs: null,
                    valorRendaPassivaAcoes: null,
                    valorRendaPassivaExterior: null,
                    valorRendaPassivaRendaFixa: null,
                    valorReposicaoInflacao: null,
                    valorAporte: null,
                    index: null
                },
                modCarClasseAtivoAporte: {
                    id: null,
                    modeloCarteiraAporte: null,
                    modeloCarteiraClasseAtivo: null,
                    percentualDesejado: null,
                    percentualReal: null,
                    percentualParticipacaoAporte: null,
                    valorAporte: null,
                    valorAporteRealizado: null,
                    comentario: null,
                    index: null
                },
                modeloCarteiraAportes: [],
                modCarClasseAtivoAportes: [],
                periodos: [],
                modelosCarteiras: [],
                cmbPeriodo: "0",
                cmbModelo: "0",
                mostraClassesDeAtivos: false,
                titleModal: null,
                isShowModal: false,
                showInfo: false,
                previous: null,
                next: null,
                page: null,
                numpages: null,
            }
        },

        methods: {
            clearFields: function() {
                this.modeloCarteiraAporte = { id: null,
                    modeloCarteira: null,
                    periodo: null,
                    valorRendaAtiva: 0.00,
                    valorRendaPassiva: 0.00,
                    valorRendaPassivaFIIs: 0.00,
                    valorRendaPassivaAcoes: 0.00,
                    valorRendaPassivaExterior: 0.00,
                    valorRendaPassivaRendaFixa: 0.00,
                    valorReposicaoInflacao: 0.00,
                    valorAporte: 0.00,
                    index: null }
            },

            callListAPI: function(nome) {
                http.get(nome, {params: {
                    cmbModelo: this.cmbModelo,
                    cmbPeriodo: this.cmbPeriodo
                }}).then(response => {
                    this.modeloCarteiraAportes = response.data.results
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages

                    if (this.modeloCarteiraAportes.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }

                })
            },

            nextPage: function(){
                if (this.page != this.numpages) {
                    http.get(this.next).then(response =>{
                        this.modeloCarteiraAportes = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },

            previousPage: function(){
                if (this.page != 1 ) {
                    http.get(this.previous).then(response =>{
                        this.modeloCarteiraAportes = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },

            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                if (!this.mostraClassesDeAtivos) {
                    this.modeloCarteiraAporte.id = this.modeloCarteiraAportes[index].id
                    this.modeloCarteiraAporte.modeloCarteira = this.modeloCarteiraAportes[index].modeloCarteira
                    this.modeloCarteiraAporte.periodo = this.modeloCarteiraAportes[index].periodo
                    this.modeloCarteiraAporte.valorRendaAtiva = this.modeloCarteiraAportes[index].valorRendaAtiva
                    this.modeloCarteiraAporte.valorRendaPassiva = this.modeloCarteiraAportes[index].valorRendaPassiva
                    this.modeloCarteiraAporte.valorRendaPassivaFIIs = this.modeloCarteiraAportes[index].valorRendaPassivaFIIs
                    this.modeloCarteiraAporte.valorRendaPassivaAcoes = this.modeloCarteiraAportes[index].valorRendaPassivaAcoes
                    this.modeloCarteiraAporte.valorRendaPassivaExterior = this.modeloCarteiraAportes[index].valorRendaPassivaExterior
                    this.modeloCarteiraAporte.valorRendaPassivaRendaFixa = this.modeloCarteiraAportes[index].valorRendaPassivaRendaFixa
                    this.modeloCarteiraAporte.valorReposicaoInflacao = this.modeloCarteiraAportes[index].valorReposicaoInflacao
                    this.modeloCarteiraAporte.valorAporte = this.modeloCarteiraAportes[index].valorAporte
                    this.modeloCarteiraAporte.index = index

                } else {
                    this.titleModal = "Aporte em " + this.modCarClasseAtivoAportes[index].modeloCarteiraClasseAtivo.classeAtivo.descricao
                    this.modCarClasseAtivoAporte.id = this.modCarClasseAtivoAportes[index].id
                    this.modCarClasseAtivoAporte.modeloCarteiraAporte = this.modCarClasseAtivoAportes[index].modeloCarteiraAporte
                    this.modCarClasseAtivoAporte.modeloCarteiraClasseAtivo = this.modCarClasseAtivoAportes[index].modeloCarteiraClasseAtivo
                    this.modCarClasseAtivoAporte.percentualDesejado = this.modCarClasseAtivoAportes[index].percentualDesejado
                    this.modCarClasseAtivoAporte.percentualReal = this.modCarClasseAtivoAportes[index].percentualReal
                    this.modCarClasseAtivoAporte.percentualParticipacaoAporte = this.modCarClasseAtivoAportes[index].percentualParticipacaoAporte
                    this.modCarClasseAtivoAporte.valorAporte = this.modCarClasseAtivoAportes[index].valorAporte
                    this.modCarClasseAtivoAporte.valorAporteRealizado = this.modCarClasseAtivoAportes[index].valorAporteRealizado
                    this.modCarClasseAtivoAporte.comentario = this.modCarClasseAtivoAportes[index].comentario
                    this.modCarClasseAtivoAporte.index = index

                }
                this.isShowModal = true    
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    http.delete('/aportes/modeloCarteiraAporteExcluir/'+this.modeloCarteiraAportes[index].id).then(response =>{
                        this.modeloCarteiraAportes.splice(index, 1)
                        if (response.status == 204) {
                            alert('Registro excluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            save: function(){
                var data

                if (!this.mostraClassesDeAtivos) {

                    if (this.modeloCarteiraAporte.modeloCarteira == null) {
                        alert('Modelo não informado!')
                        return
                    }

                    if (this.modeloCarteiraAporte.periodo == null) {
                        alert('Período não informada!')
                        return
                    }

                    if (this.modeloCarteiraAporte.valorRendaAtiva == null) {
                        alert('Valor nulo ou zero não são permitidos!')
                        return
                    }

                    if (this.modeloCarteiraAporte.valorRendaPassiva == null) {
                        alert('Valor nulo ou zero não são permitidos!')
                        return
                    }

                    this.modeloCarteiraAporte.valorAporte = (parseFloat(this.modeloCarteiraAporte.valorRendaAtiva)+parseFloat(this.modeloCarteiraAporte.valorRendaPassivaAcoes) + parseFloat(this.modeloCarteiraAporte.valorRendaPassivaFIIs) + parseFloat(this.modeloCarteiraAporte.valorRendaPassivaRendaFixa)).toFixed(2)
                    this.modeloCarteiraAporte.valorRendaPassiva = (parseFloat(this.modeloCarteiraAporte.valorRendaPassivaAcoes) + parseFloat(this.modeloCarteiraAporte.valorRendaPassivaFIIs) + parseFloat(this.modeloCarteiraAporte.valorRendaPassivaRendaFixa)).toFixed(2)

                    data = {id: this.modeloCarteiraAporte.id,
                        modeloCarteira: this.modeloCarteiraAporte.modeloCarteira.id,
                        periodo: this.modeloCarteiraAporte.periodo.id,
                        valorRendaAtiva: this.modeloCarteiraAporte.valorRendaAtiva,
                        valorRendaPassiva: this.modeloCarteiraAporte.valorRendaPassiva,
                        valorRendaPassivaAcoes: this.modeloCarteiraAporte.valorRendaPassivaAcoes,
                        valorRendaPassivaFIIs: this.modeloCarteiraAporte.valorRendaPassivaFIIs,
                        valorRendaPassivaRendaFixa: this.modeloCarteiraAporte.valorRendaPassivaRendaFixa,
                        valorRendaPassivaExterior: this.modeloCarteiraAporte.valorRendaPassivaExterior,
                        valorReposicaoInflacao: this.modeloCarteiraAporte.valorReposicaoInflacao,
                        valorAporte: this.modeloCarteiraAporte.valorAporte
                    }

                    if (this.modeloCarteiraAporte.id == null) {
                        http.post('/aportes/modeloCarteiraAporteAdicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.modeloCarteiraAporte.id = response.data.id
                                this.modeloCarteiraAportes.push(this.modeloCarteiraAporte)
                                this.showInfo = true
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else {
                        http.put('/aportes/modeloCarteiraAporteEditar/'+this.modeloCarteiraAporte.id, data).then(response =>{
                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].modeloCarteira = this.modeloCarteiraAporte.modeloCarteira
                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].periodo = this.modeloCarteiraAporte.periodo
                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].valorRendaAtiva = response.data.valorRendaAtiva
                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].valorRendaPassiva = response.data.valorRendaPassiva

                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].valorRendaPassivaAcoes = response.data.valorRendaPassivaAcoes
                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].valorRendaPassivaFIIs = response.data.valorRendaPassivaFIIs
                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].valorRendaPassivaExterior = response.data.valorRendaPassivaExterior
                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].valorRendaPassivaRendaFixa = response.data.valorRendaPassivaRendaFixa
                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].valorReposicaoInflacao = response.data.valorReposicaoInflacao


                            this.modeloCarteiraAportes[this.modeloCarteiraAporte.index].valorAporte = response.data.valorAporte
                        }).catch(e => {
                            console.log(e);
                        })
                    }

                } else {
                    data = {id: this.modCarClasseAtivoAporte.id,
                        modeloCarteiraAporte: this.modCarClasseAtivoAporte.modeloCarteiraAporte.id,
                        modeloCarteiraClasseAtivo: this.modCarClasseAtivoAporte.modeloCarteiraClasseAtivo.id,
                        percentualDesejado: this.modCarClasseAtivoAporte.percentualDesejado,
                        percentualReal: this.modCarClasseAtivoAporte.percentualReal,
                        percentualParticipacaoAporte: this.modCarClasseAtivoAporte.percentualParticipacaoAporte,
                        valorAporte: this.modCarClasseAtivoAporte.valorAporte,
                        valorAporteRealizado: this.modCarClasseAtivoAporte.valorAporteRealizado,
                        comentario: this.modCarClasseAtivoAporte.comentario
                    }

                    http.put('/aportes/modCarClasseAtivoAporteEditar/'+this.modCarClasseAtivoAporte.id, data).then(response =>{
                        this.modCarClasseAtivoAportes[this.modCarClasseAtivoAporte.index].valorAporteRealizado = response.data.valorAporteRealizado
                        this.modCarClasseAtivoAportes[this.modCarClasseAtivoAporte.index].comentario = response.data.comentario
                    }).catch(e => {
                        console.log(e);
                    })


                }

            },

            filterRecords: function(){
                this.callListAPI('aportes/modeloCarteiraAporteListar')
            },

            processarPeriodo: function(index) {
                if (window.confirm('Confirma o processamento deste aporte?')) {
                    http.put('/aportes/modeloCarteiraAporte/processar/'+this.modeloCarteiraAportes[index].id).then(response =>{
                        if (response.status == 204) {
                            alert('Processamento concluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            callClassesDeAtivos: function(index) {
                this.mostraClassesDeAtivos = true
                http.get('aportes/modCarClasseAtivoAporte/listar', {params: {
                    idModeloCarteiraAporte: this.modeloCarteiraAportes[index].id,
                }}).then(response => {
                    this.modCarClasseAtivoAportes = response.data
                })
            }
        },

        mounted: function() {
            http.get('periodos/listar').then(response =>{
                this.periodos = response.data
            })
            http.get('modelagemCarteira/modeloCarteiraListar').then(response =>{
                this.modelosCarteiras = response.data
            })
        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>