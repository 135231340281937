<template>
    <div>
        <br>
        <h5 class="title is-5">Mapa da Renda
        </h5>
        <form>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbModelo">
                                    <option value="0" disabled>Selecione um modelo</option>
                                    <option v-for="modeloCarteita in modelosCarteiras" :key="modeloCarteita.id" :value="modeloCarteita">
                                        {{modeloCarteita.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPeriodo">
                                    <option value="0" disabled>Selecione um período</option>
                                    <option v-for="periodo in periodos" :key="periodo.id" :value="periodo">
                                        {{periodo.mesAno}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select v-model="cmbAssetClass">
                                        <option value="0">TODAS AS CLASSES DE ATIVOS</option>
                                        <option v-for="assetclass in assetsclasses" :key="assetclass.id" :value="assetclass.id">
                                            {{assetclass.descricao}}
                                        </option>
                                    </select>
                                </div>    
                            </div>
                        </div>  
                    </div>
    
                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>

            </div>
        </form>  

        <hr>

        <div class="columns" v-show="this.showInfo">
            <div class="column">
                <div class="table-container">                
                    <h2><strong>RESUMO GERAL</strong></h2>
                    <br>
                    <table class="table is-hoverable is-fullwidth is-striped">
                        <thead class="has-background-grey">
                            <tr>
                                <th class="has-text-centered has-text-white-bis"><small>Ano</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>JAN</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>FEV</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>MAR</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>ABR</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>MAI</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>JUN</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>JUL</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>AGO</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>SET</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>OUT</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>NOV</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>DEZ</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>TOTAL</small></th>
                                <th class="has-text-centered has-text-white-bis"><small>MÉDIA</small></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(mm, index) in matrizMensal" :key="index">
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small><strong>{{mm[index, 0]}}</strong></small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 1]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 2]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 3]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 4]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 5]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 6]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 7]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 8]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 9]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 10]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 11]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small>{{mm[index, 12]|numberFormat}}</small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small><strong>{{mm[index, 13]|numberFormat}}</strong></small></td>
                                <td class="has-text-centered" v-bind:class="{'is-selected':mm[index,15], 'has-text-weight-bold':mm[index,15]}"><small><strong>{{mm[index, 14]|numberFormat}}</strong></small></td>
                            </tr>
                        </tbody>

                    </table>

                </div>        
            </div>
        </div>

        <div class="columns" v-show="this.showInfo">
        
            <div class="column">
                <div class="card">
                    <header class="card-header has-background-grey">
                        <p class="card-header-title has-text-white-bis">
                            RECEBIMENTO DE PROVENTOS NO MÊS
                        </p>
                    </header>
                    <div class="table-container">                
                        <table class="table is-hoverable is-narrow is-fullwidth is-striped">
                            <thead class="has-background-link-light">
                                <tr>
                                    <th class="has-text-black-bis"><small>Ativo</small></th>
                                    <th class="has-text-black-bis has-text-right"><small>Valor</small></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(provRec, index) in proventosRecebidosNoMes" :key="index">
                                    <td><small>{{provRec.ticker}}</small></td>
                                    <td class="has-text-right"><small>{{provRec.totalProventos|numberFormat}}</small></td>
                                </tr>
                            </tbody>    

                            <tfoot class="has-background-grey">
                                <tr class="is-selected">
                                    <td class="has-text-weight-bold"><small>TOTAL</small></td>
                                    <td class="has-text-weight-bold has-text-right"><small>{{totalProventosRecebidos|numberFormat}}</small></td>
                                </tr>
                            </tfoot>                        
                        </table>
                    </div>
                </div>    
            </div>

            <div class="column">
                <div class="card">
                    <header class="card-header has-background-grey">
                        <p class="card-header-title has-text-white-bis">
                            TOP 15 ATIVOS
                        </p>
                    </header>
                    <div class="table-container">                
                        <table class="table is-hoverable is-narrow is-fullwidth is-striped">
                            <thead class="has-background-link-light">
                                <tr>
                                    <th class="has-text-black-bis"><small>Posição</small></th>
                                    <th class="has-text-black-bis"><small>Ativo</small></th>
                                    <th class="has-text-black-bis has-text-right"><small>Valor</small></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(rank, index) in rankingProventosPorAtivo" :key="index">
                                    <td><small>{{index+1}}</small></td>
                                    <td><small>{{rank.ticker}}</small></td>
                                    <td class="has-text-right"><small>{{rank.totalProventos|numberFormat}}</small></td>
                                </tr>
                            </tbody>    

                        </table>
                    </div>    
                </div>    
            </div>

            <div class="column">
                <div class="card">
                    <header class="card-header has-background-grey">
                        <p class="card-header-title has-text-white-bis">
                            RENDA POR CLASSE DE ATIVO
                        </p>
                    </header>
                    <div class="card-content">
                        <div class="content">
                            <apexchart ref="chartProventosClasseAtivo" type="donut" width="400" :options="chartProventosClasseAtivoOptions" :series="seriesProventosClasseAtivo"></apexchart>
                        </div>
                    </div>
                    <footer class="card-footer">
                    </footer>
                </div>

                <div class="card">
                    <header class="card-header has-background-grey">
                        <p class="card-header-title has-text-white-bis">
                            RENDA POR ANO
                        </p>
                    </header>
                    <div class="card-content is-center">
                        <div class="content">
                            <apexchart ref="chartProventosPorAno" height="300" width="400" type="bar" :options="options" :series="seriesProventosPorAno"></apexchart>
                        </div>
                    </div>
                    <footer class="card-footer">
                    </footer>
                </div>

            </div>

        </div>

        <div class="columns" v-show="this.showInfo">
            <div class="column">
                <div class="table-container">                
                    <h2><strong>RESUMO ANUAL POR ATIVO</strong></h2>
                    <br>
                    <table class="table is-hoverable is-fullwidth is-striped">
                        <thead class="has-background-grey">
                            <tr>
                                <th class="has-text-white-bis"><small>Ativo</small></th>
                                <th class="has-text-centered has-text-white-bis" v-for="(itemHeader, index) in headerTable.slice().reverse()" :key="index"><small>{{itemHeader}}</small></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(d, index) in detail" :key="index">
                                <td><small>{{d[index, 0]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 12][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 11][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 10][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 9][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 8][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 7][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 6][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 5][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 4][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 3][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 2][1]}}</small></td>
                                <td class="has-text-centered"><small>{{d[index, 1][1]}}</small></td>
                            </tr>
                        </tbody>    

                        <tfoot class="has-background-grey">
                            <tr class="is-selected">
                                <td class="has-text-weight-bold"><small>TOTAL</small></td>
                                <td class="has-text-weight-bold has-text-centered" v-for="(mp, index) in mapaProventosPorPeriodo" :key="index"><small>{{mp.totalProventos|numberFormat}}</small></td>
                            </tr>
                        </tfoot>                        
                    </table>
                </div>
            </div>
        </div>   

    </div>        

</template>

<script>
    import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "MapaProventos",

        data(){
            return{
                periodos: [],
                modelosCarteiras: [],
                assetsclasses: [],
                mapaProventos: [],
                mapaProventosPorPeriodo: [],
                mapaProventosAgrupado: [],
                mapaProventosPorAno: [],
                mapaProventosGeral: [],
                proventosRecebidosNoMes: [],
                rankingProventosPorAtivo: [],
                proventosClasseAtivo: [],
                headerTable: [],

                cmbModelo: "0",
                cmbPeriodo: "0",
                cmbAssetClass: null,
                detail: null,
                matrizMensal: [],
                totalProventosRecebidos: 0,
                showInfo: false,

                seriesProventosPorAno: [{
                    name: 'Proventos',
                    data: [] 
                }],
                seriesProventosClasseAtivo: [],
                chartProventosClasseAtivoOptions: {
                    chart: {
                        width: "100%",
                        type: 'donut',
                    },

                    labels: [], 

                    legend: {
                        show: false
                    },

                },  

                options: {
                    chart: {
                        id: 'investmethod-evolucao'
                    },
                    xaxis: {
                        categories: [],
                        
                        labels: {
                            style: {
                                fontSize: '10px',
                            },
                            show: true,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },    

                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },

                    markers: {
                        size: 4,
                    } ,

                },

            }
        },

        methods: {
            callListAPI: function() {
                var mesAno
                var i
                var j
                var arrayLabels
                var arraySeries
                var arrayMeses = []
                var dataInicial
                var mapDetail

                if (this.cmbPeriodo.mes == "12") {
                    dataInicial = this.cmbPeriodo.ano+"-01-01"
                } else {
                    dataInicial = (parseInt(this.cmbPeriodo.ano)-1).toString()+"-"+(parseInt(this.cmbPeriodo.mes)+1).toString().padStart(2,"0")+"-01"
                }

                // Definindo o cabeçalho da table
                this.headerTable = []
                mesAno = parseInt(this.cmbPeriodo.mesAno.replace('/', ''))
                for (i = 0; i < 12; i++) {
                    if (i != 11) {
                        if (i>0) {
                            mesAno = parseInt(mesAno)-1
                        }
                        if (mesAno.toString().substr(4,2) == "00") {
                            mesAno = parseInt((parseInt(mesAno.toString().substr(0,4))-1).toString()+"12")
                        }
                    } else {
                        mesAno = parseInt(dataInicial.substr(0,7).replace("-", ""))
                    }
                    this.headerTable.push(mesAno.toString().substr(0,4)+"/"+mesAno.toString().substr(4,2))
                    arrayMeses.push([mesAno.toString(), 0])
                }

                http.get('proventos/mapaProventos/', {params: {
                    idPeriodo: this.cmbPeriodo.id,
                    periodoInicial: dataInicial,
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    this.mapaProventos = response.data.results

                    if (this.mapaProventos.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }

                    this.mapaProventosAgrupado =_.chain(this.mapaProventos).groupBy("ticker").map(function(v, i) {
                    return {
                        ticker: i,
                        proventos: v,
                    }
                    }).value();  

                    this.detail = null
                    this.detail = new Array()
                    
                    for (i = 0; i < this.mapaProventosAgrupado.length; i++) {
                        this.detail[i] = new Array()
                        this.detail[i][0] = this.mapaProventosAgrupado[i].ticker
                        for (j = 1; j <= arrayMeses.length; j++) {
                            this.detail[i][j] = arrayMeses[j-1]
                        }
                    }

                    for (i=0; i < this.mapaProventosAgrupado.length; i++) {
                        for (j=0; j < this.mapaProventosAgrupado[i].proventos.length; j++) {
                            mapDetail = this.detail[i].map(function(element) {
                                if (Array.isArray(element)) {
                                    if (element[0] == this.mapaProventosAgrupado[i].proventos[j].competencia.replace('/', '')) {
                                        return [element[0], parseFloat(this.mapaProventosAgrupado[i].proventos[j].totalProventos).toFixed(2)]
                                    }
                                }
                                return element
                            }, this)
                            this.detail[i] = null
                            this.detail[i] = mapDetail
                        }
                    }

                })

                http.get('proventos/resumoPorPeriodo/', {params: {
                    idPeriodo: this.cmbPeriodo.id,
                    periodoInicial: dataInicial,
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    this.mapaProventosPorPeriodo = response.data.results
                })

                http.get('proventos/mapaProventosMes/', {params: {
                    idPeriodo: this.cmbPeriodo.id,
                    periodoInicial: dataInicial,
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    this.proventosRecebidosNoMes = response.data.results
                    this.totalProventosRecebidos = 0
                    for (i = 0; i < this.proventosRecebidosNoMes.length; i++) {
                        this.totalProventosRecebidos += parseFloat(this.proventosRecebidosNoMes[i].totalProventos)
                    }
                })

                http.get('proventos/resumoGeralPorCompetencia', {params: {
                        idModeloCarteira: this.cmbModelo.id
                    }}).then(response =>{
                    var hoje = new Date()
                    var i
                    var competenciaInicial
                    var numAnos

                    if (response.data.results === undefined || response.data.results.length == 0) {
                        return
                    }          

                    this.mapaProventosPorAno = response.data.results

                    competenciaInicial = this.mapaProventosPorAno[0].competencia
                    numAnos = parseInt(hoje.getFullYear())-parseInt(competenciaInicial.substr(0,4))+1

                    this.matrizMensal = []

                    for (i = 0; i < numAnos; i++) {
                        this.matrizMensal.push([parseInt(this.mapaProventosPorAno[0].competencia.substr(0,4))+i, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0, false])
                    }

                    this.mapaProventosGeral.push(["TOTAL", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, true])

                    for (i = 0; i < this.mapaProventosPorAno.length; i++) {
                        this.matrizMensal[parseInt(this.mapaProventosPorAno[i].competencia.substr(0,4)-competenciaInicial.substr(0,4))][parseInt(this.mapaProventosPorAno[i].competencia.substr(5,2))] = parseFloat(this.mapaProventosPorAno[i].totalProventos)

                        this.matrizMensal[parseInt(this.mapaProventosPorAno[i].competencia.substr(0,4)-competenciaInicial.substr(0,4))][13] += parseFloat(this.mapaProventosPorAno[i].totalProventos)
                        this.mapaProventosGeral[0][13] += parseFloat(this.mapaProventosPorAno[i].totalProventos)

                        this.matrizMensal[parseInt(this.mapaProventosPorAno[i].competencia.substr(0,4)-competenciaInicial.substr(0,4))][14] = this.matrizMensal[parseInt(this.mapaProventosPorAno[i].competencia.substr(0,4)-competenciaInicial.substr(0,4))][13]/12
                        this.mapaProventosGeral[0][14] = (this.mapaProventosGeral[0][13]/numAnos).toFixed(2)

                    }

                    this.matrizMensal.push(this.mapaProventosGeral[0])

                })    
                
                http.get('proventos/mapaProventosRankingPorAtivo/', {params: {
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    this.rankingProventosPorAtivo = response.data.results
                })

                http.get('proventos/mapaProventosClasseAtivo/', {params: {
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    this.proventosClasseAtivo = response.data.results
                    arrayLabels = []
                    arraySeries = []
                    for (i = 0; i < this.proventosClasseAtivo.length; i++) {
                        arraySeries.push(Math.round(this.proventosClasseAtivo[i].totalProventos, 2 ))
                        arrayLabels.push(this.proventosClasseAtivo[i].classeAtivo)
                    }
                    this.chartProventosClasseAtivoOptions.labels = arrayLabels
                    this.seriesProventosClasseAtivo = arraySeries
                    this.$refs.chartProventosClasseAtivo.refresh()
                })

                http.get('proventos/mapaProventosPorAno/', {params: {
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    this.mapaProventosPorAno = response.data.results

                    arrayLabels = []
                    arraySeries = []

                    this.evolucaoPatrimonial = response.data.results

                    for (i = 0; i < this.mapaProventosPorAno.length; i++) {
                        arraySeries.push( parseFloat(this.mapaProventosPorAno[i].totalProventos).toFixed(2) )
                        arrayLabels.push(this.mapaProventosPorAno[i].ano)
                    }

                    this.options.xaxis.categories = arrayLabels
                    this.seriesProventosPorAno[0].data = arraySeries
                    this.$refs.chartProventosPorAno.refresh()

                })                
            },

            clearFields: function() {
            },

            add: function() {
            },

            save: function(){
            },

            nextPage: function(){
            },
            previousPage: function(){
            },
            filterRecords: function(){
                if (this.cmbModelo == null || this.cmbModelo == "0") {
                    alert("Modelo não selecionado!")
                    return
                }
                if (this.cmbPeriodo == null || this.cmbPeriodo == "0") {
                    alert("Período final não selecionado!")
                    return
                }

                this.callListAPI()
            }
        },

        mounted: function() {
            http.get('modelagemCarteira/modeloCarteiraListar').then(response =>{
                this.modelosCarteiras = response.data
            })
            http.get('periodos/listar').then(response =>{
                this.periodos = response.data
            })

            http.get('classesativos/').then(response =>{
                this.assetsclasses = _.orderBy(response.data.results, ['descricao'], ['asc'])
                this.cmbAssetClass = "0"
            })

        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>