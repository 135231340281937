<template>
    <div>
        <br>
        <h5 class="title is-5">Notas de Negociação
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
                Criar
            </button>  
        </h5>

        <hr>
        
        <form>
            <div class="field is-horizontal">

                <div class="field-body">

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbModelo">
                                    <option value="0">TODOS OS MODELOS</option>
                                    <option v-for="modeloCarteita in modelosCarteiras" :key="modeloCarteita.id" :value="modeloCarteita.id">
                                        {{modeloCarteita.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbCorretora" :disabled="this.mostraNotaNegociacaoDetalhe">
                                    <option value="0">TODAS AS CORRETORAS</option>
                                    <option v-for="corretoraUsuario in corretorasUsuarios" :key="corretoraUsuario.id" :value="corretoraUsuario.id">
                                        {{corretoraUsuario.corretora.nomeCurto}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">
                        <input class="input" type="date" placeholder="Informe a data inicial" v-model="dataInicial" :disabled="this.mostraNotaNegociacaoDetalhe">
                    </div>

                    <div class="field">
                        <input class="input" type="date" placeholder="Informe a data final" v-model="dataFinal" :disabled="this.mostraNotaNegociacaoDetalhe">
                    </div>

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords" :disabled="this.mostraNotaNegociacaoDetalhe">Filtrar</button>
                    </div>    
                </div>
            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">

            <div class="modal-background" v-on:click="isShowModal = false"></div>

            <div class="modal-card">

                <header class="modal-card-head">
                    <p class="modal-card-title" v-if="!this.mostraNotaNegociacaoDetalhe">Nota de Negociação</p>
                    <p class="modal-card-title" v-if="this.mostraNotaNegociacaoDetalhe">Ativo da Nota de Negociação</p>
                    <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>

                <section class="modal-card-body">

                    <!-- Criar nota de negociação -->
                    <form v-if="!this.mostraNotaNegociacaoDetalhe">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Modelo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="notaNegociacao.modeloCarteira">
                                            <option v-for="modeloCarteira in modelosCarteiras" :key="modeloCarteira.id" :value="modeloCarteira">
                                                {{modeloCarteira.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Corretora</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="notaNegociacao.corretoraUsuario">
                                            <option v-for="corretoraUsuario in corretorasUsuarios" :key="corretoraUsuario.id" :value="corretoraUsuario">
                                                {{corretoraUsuario.corretora.nomeCurto}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Movimentação</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="date" placeholder="Informe a data inicial" v-model="notaNegociacao.dataNegociacao">
                                </div>
                            </div>

                            <div class="field-label is-normal">
                                <label class="label"><small>Liquidação</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="date" placeholder="Informe a data de término" v-model="notaNegociacao.dataLiquidacao">
                                </div>
                            </div>

                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Corretagem</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="notaNegociacao.valorCorretagem">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Emolumentos</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="notaNegociacao.valorEmolumentos">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Bruto</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="notaNegociacao.valorBruto">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Absoluto</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="notaNegociacao.valorBrutoAbsoluto">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Imposto</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="notaNegociacao.valorImposto">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Líquido</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="notaNegociacao.valorLiquido">
                                </div>
                            </div>
                        </div>

                    </form>  

                    <!-- Lançar ativos na nota de negociação -->
                    <form v-if="this.mostraNotaNegociacaoDetalhe">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Modelo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="notaNegociacaoDetalhe.modeloCarteira">
                                            <option v-for="modeloCarteira in modelosCarteiras" :key="modeloCarteira.id" :value="modeloCarteira">
                                                {{modeloCarteira.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label"><small>Tipo de Negociação</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select v-model="notaNegociacaoDetalhe.tipoNegociacao">
                                                <option value="C">Compra</option>
                                                <option value="V">Venda</option>
                                                <option value="S">Emissão/Subscrição</option>
                                                <option value="B">Bonificação</option>
                                            </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>    

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Ativo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="notaNegociacaoDetalhe.ativo">
                                            <option v-for="ativo in ativos" :key="ativo.id" :value="ativo">
                                                {{ativo.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Quantidade</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="notaNegociacaoDetalhe.quantidade">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Preço</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="notaNegociacaoDetalhe.preco">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Valor Total</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="notaNegociacaoDetalhe.valorTotal" disabled>
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Impostos</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o imposto" v-model="notaNegociacaoDetalhe.imposto">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Conversão R$</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe a conversão R$" v-model="notaNegociacaoDetalhe.conversaoReal">
                                </div>
                            </div>
                        </div>

                    </form> 

                </section>

                <footer class="modal-card-foot">
                    <button class="button is-success" @click="save()">Salvar</button>
                    <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>

        </div>        

        <div class="columns" v-if="!this.mostraNotaNegociacaoDetalhe" v-show="this.showInfo">

            <div class="column">
                <div class="table-container">                
                    <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th><small>Modelo</small></th>
                                <th><small>Corretora</small></th>
                                <th class="has-text-center"><small>Negociação</small></th>
                                <th class="has-text-center"><small>Liquidação</small></th>
                                <th class="has-text-right"><small>Valor Líquido</small></th>
                                <th colspan="3" class="has-text-center"><small>Ações</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(notaNegociacao, index) in notasNegociacao" :key="notaNegociacao.id">
                                <td><small>{{notaNegociacao.modeloCarteira.descricao}}</small></td>
                                <td><small>{{notaNegociacao.corretoraUsuario.corretora.nomeCurto}}</small></td>
                                <td class="has-text-center"><small>{{notaNegociacao.dataNegociacao|dateDDMMYYYY}}</small></td>
                                <td class="has-text-center"><small>{{notaNegociacao.dataLiquidacao|dateDDMMYYYY}}</small></td>
                                <td class="has-text-right"><small>{{notaNegociacao.valorLiquido|numberFormat}}</small></td>
                                <td class="has-text-center"><small><a @click="edit(index)"><span class="fas fa-edit"></span></a></small></td>
                                <td class="has-text-center"><small><a @click="erase(index)"><span class="fas fa-trash-alt"></span></a></small></td>
                                <td class="has-text-center"><small><a @click="callDetailListAPI(notaNegociacao)"><span class="fas fa-sitemap"></span></a></small></td>
                            </tr>
                        </tbody>    

                    </table>
                </div>    

                <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                    <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.page == 1 || this.page == null">Anterior</a>
                    <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.page == this.numpages">Próxima</a>                
                </nav>
                <br>
            </div>

        </div>   

        <div class="columns" v-show="this.mostraNotaNegociacaoDetalhe">
            <div class="column">
            </div>
            <div class="column is-two-thirds">
                <article  class="panel is-primary" >

                    <header class="panel-heading">
                        <p>
                            Ativos
                        </p>
                    </header>

                    <content>
                        <div class="panel-block">
                            <div class="table-container">                
                                <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                                    <thead>
                                        <tr>
                                            <th class="has-text-center"><small>Modelo</small></th>
                                            <th class="has-text-center"><small>Tipo de Negociação</small></th>
                                            <th class="has-text-center"><small>Ativo</small></th>
                                            <th class="has-text-right"><small>Quantidade</small></th>
                                            <th class="has-text-right"><small>Preço</small></th>
                                            <th class="has-text-right"><small>Valor Total</small></th>
                                            <th class="has-text-right"><small>Custo</small></th>
                                            <th class="has-text-right"><small>Imposto</small></th>
                                            <th class="has-text-right"><small>Valor Real</small></th>
                                            <th colspan="2" class="has-text-center"><small>Ações</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(notaNegociacaoDetalhe, index) in notaNegociacaoDetalhes" :key="notaNegociacaoDetalhe.id">
                                            <td><small>{{notaNegociacaoDetalhe.modeloCarteira.descricao}}</small></td>
                                            <td v-if="notaNegociacaoDetalhe.tipoNegociacao=='C'"><small>Compra</small></td>
                                            <td v-if="notaNegociacaoDetalhe.tipoNegociacao=='V'"><small>Venda</small></td>
                                            <td v-if="notaNegociacaoDetalhe.tipoNegociacao=='B'"><small>Bonificação</small></td>
                                            <td v-if="notaNegociacaoDetalhe.tipoNegociacao=='S'"><small>Emissão/Subscrição</small></td>
                                            <td><small>{{notaNegociacaoDetalhe.ativo.descricao}}</small></td>
                                            <td class="has-text-right"><small>{{notaNegociacaoDetalhe.quantidade|numberFormat}}</small></td>
                                            <td class="has-text-right"><small>{{notaNegociacaoDetalhe.preco|numberFormat}}</small></td>
                                            <td class="has-text-right"><small>{{notaNegociacaoDetalhe.valorTotal|numberFormat}}</small></td>
                                            <td class="has-text-right"><small>{{notaNegociacaoDetalhe.custo|numberFormat}}</small></td>
                                            <td class="has-text-right"><small>{{notaNegociacaoDetalhe.imposto|numberFormat}}</small></td>
                                            <td class="has-text-right"><small>{{parseFloat(notaNegociacaoDetalhe.imposto)+parseFloat(notaNegociacaoDetalhe.custo)+parseFloat(notaNegociacaoDetalhe.valorTotal)|numberFormat}}</small></td>
                                            <td class="has-text-center"><small><a @click="edit(index)"><span class="fas fa-edit"></span></a></small></td>
                                            <td class="has-text-center"><small><a @click="erase(index)"><span class="fas fa-trash-alt"></span></a></small></td>
                                        </tr>
                                    </tbody>    
                                </table>
                            </div>    
                        </div>
                    </content>

                    <footer class="card-footer">
                        <a v-on:click="mostraNotaNegociacaoDetalhe = false" class="card-footer-item">Fechar</a>
                    </footer>            
                </article > 
            </div>
            <div class="column">
            </div>
        </div>

    </div>        

</template>

<script>
    import {http} from '../../services/config'
    import moment from 'moment'

    export default {
        name: "NotaNegociacao",

        data(){
            return{
                corretorasUsuarios: [],
                notaNegociacao: {
                    id: null,
                    corretoraUsuario: null,
                    dataNegociacao: null,
                    dataLiquidacao: null,
                    valorCorretagem: 0,
                    valorEmolumentos: 0,
                    valorBruto: 0,
                    valorBrutoAbsoluto: 0,    
                    valorLiquido: 0,    
                    relacaoAtivos: null,  
                    valorImposto: 0,  
                    index: null,
                    usuario: this.$store.state.idUser,
                    modeloCarteira: null
                },
                notasNegociacao: [],
                notaNegociacaoDetalhe: {
                    id: null,
                    notaNegociacao: null,
                    tipoNegociacao: null,
                    ativo: null,
                    quantidade: null,
                    preco: null,
                    valorTotal: null,
                    custo: null,
                    fator: null,
                    imposto: null,
                    modeloCarteira: null,
                    index: null,
                    conversaoReal: 1
                },
                notaNegociacaoDetalhes: [],
                ativos: [],
                modelosCarteiras: [],
                cmbCorretora: "0",
                cmbModelo: "0",
                dataInicial: null,
                dataFinal: null,
                mostraNotaNegociacaoDetalhe: false,
                idNotaNegociacaoSelecionada: null,
                previous: null,
                next: null,
                page: null,
                numpages: null,
                isShowModal: false,
                showInfo: false
            }
        },

        methods: {
            clearFields: function() {
                if (!this.mostraNotaNegociacaoDetalhe) {
                    this.notaNegociacao = { id: null,
                        corretoraUsuario: null,
                        dataNegociacao: null,
                        dataLiquidacao: null,
                        valorCorretagem: null,
                        valorEmolumentos: null,
                        valorBruto: null,
                        valorBrutoAbsoluto: null,    
                        valorLiquido: null,    
                        relacaoAtivos: "Não informado",
                        valorImposto: null,
                        index: null,
                        usuario: this.$store.state.idUser,
                        modeloCarteira: null,
                        conversaoReal: 1 }
                } else {
                    console.log(this.notaNegociacaoSelecionada)
                    this.notaNegociacaoDetalhe = { id: null,
                        notaNegociacao: null,
                        tipoNegociacao: "C",
                        ativo: null,
                        quantidade: 0,
                        valorTotal: 0,
                        custo: 0,
                        fator: 1,
                        imposto: 0,
                        modeloCarteira: this.notaNegociacaoSelecionada.modeloCarteira,
                        index: null }
                }
            },
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                if (!this.mostraNotaNegociacaoDetalhe) {
                    this.notaNegociacao.id = this.notasNegociacao[index].id
                    this.notaNegociacao.corretoraUsuario = this.notasNegociacao[index].corretoraUsuario
                    this.notaNegociacao.dataNegociacao = this.notasNegociacao[index].dataNegociacao
                    this.notaNegociacao.dataLiquidacao = this.notasNegociacao[index].dataLiquidacao
                    this.notaNegociacao.valorCorretagem = this.notasNegociacao[index].valorCorretagem
                    this.notaNegociacao.valorEmolumentos = this.notasNegociacao[index].valorEmolumentos
                    this.notaNegociacao.valorBruto = this.notasNegociacao[index].valorBruto
                    this.notaNegociacao.valorBrutoAbsoluto = this.notasNegociacao[index].valorBrutoAbsoluto
                    this.notaNegociacao.valorLiquido = this.notasNegociacao[index].valorLiquido
                    this.notaNegociacao.relacaoAtivos = this.notasNegociacao[index].relacaoAtivos
                    this.notaNegociacao.valorImposto = this.notasNegociacao[index].valorImposto
                    this.notaNegociacao.usuario = this.notasNegociacao[index].usuario
                    this.notaNegociacao.modeloCarteira = this.notasNegociacao[index].modeloCarteira
                    this.notaNegociacao.index = index
                    this.isShowModal = true
                } else {
                    this.notaNegociacaoDetalhe.id = this.notaNegociacaoDetalhes[index].id
                    this.notaNegociacaoDetalhe.notaNegociacao = this.notaNegociacaoDetalhes[index].notaNegociacao
                    this.notaNegociacaoDetalhe.tipoNegociacao = this.notaNegociacaoDetalhes[index].tipoNegociacao
                    this.notaNegociacaoDetalhe.ativo = this.notaNegociacaoDetalhes[index].ativo
                    this.notaNegociacaoDetalhe.quantidade = this.notaNegociacaoDetalhes[index].quantidade
                    this.notaNegociacaoDetalhe.preco = this.notaNegociacaoDetalhes[index].preco
                    this.notaNegociacaoDetalhe.valorTotal = this.notaNegociacaoDetalhes[index].valorTotal
                    this.notaNegociacaoDetalhe.fator = this.notaNegociacaoDetalhes[index].fator
                    this.notaNegociacaoDetalhe.imposto = this.notaNegociacaoDetalhes[index].imposto
                    this.notaNegociacaoDetalhe.modeloCarteira = this.notaNegociacaoDetalhes[index].modeloCarteira
                    this.notaNegociacaoDetalhe.index = index
                    this.notaNegociacaoDetalhe.conversaoReal = this.notaNegociacaoDetalhes[index].conversaoReal
                    this.isShowModal = true              
                }

            },

            erase: function(index){
                if (!this.mostraNotaNegociacaoDetalhe) {
                    if (window.confirm('Confirma exclusão?')) {
                        http.delete('/notasNegociacao/excluir/'+this.notasNegociacao[index].id).then(response =>{
                            this.notasNegociacao.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                } else {
                    if (window.confirm('Confirma exclusão?')) {
                        http.delete('/notaNegociacaoDetalhes/excluir/'+this.notaNegociacaoDetalhes[index].id).then(response =>{
                            this.notaNegociacaoDetalhes.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }

                }    
            },

            save: function(){
                var data

                if (!this.mostraNotaNegociacaoDetalhe) {
                    if (this.notaNegociacao.modeloCarteira == null) {
                        alert('Modelo de carteira não informado!')
                        return
                    }

                    if (this.notaNegociacao.corretoraUsuario == null) {
                        alert('Corretora não informada!')
                        return
                    }

                    if (this.notaNegociacao.dataNegociacao == null || this.notaNegociacao.dataNegociacao == '') {
                        alert('Data de negociação não informada!')
                        return
                    }

                    if (this.notaNegociacao.dataLiquidacao == null || this.notaNegociacao.dataLiquidacao == '') {
                        alert('Data de liquidação não informada!')
                        return
                    }

                    if (this.notaNegociacao.valorCorretagem == null || this.notaNegociacao.valorCorretagem == '') {
                        alert('Valor da corretagem  não informado!')
                        return
                    }

                    if (this.notaNegociacao.valorEmolumentos == null || this.notaNegociacao.valorEmolumentos == '') {
                        alert('Valor dos emolumentos não informado!')
                        return
                    }

                    if (this.notaNegociacao.valorBruto == null || this.notaNegociacao.valorBruto == '') {
                        alert('Valor bruto não informado!')
                        return
                    }

                    if (this.notaNegociacao.valorBrutoAbsoluto == null || this.notaNegociacao.valorBrutoAbsoluto == '') {
                        alert('Valor bruto não informado!')
                        return
                    }

                    if (this.notaNegociacao.valorLiquido == null || this.notaNegociacao.valorLiquido == '') {
                        alert('Valor líquido não informado!')
                        return
                    }

                    if (this.notaNegociacao.valorImposto == null || this.notaNegociacao.valorImposto == '') {
                        alert('Valor do imposto não preenchido!')
                        return
                    }

                    data = {id: this.notaNegociacao.id,
                        corretoraUsuario: this.notaNegociacao.corretoraUsuario.id,
                        dataNegociacao: moment(this.notaNegociacao.dataNegociacao).format('DD/MM/YYYY'),
                        dataLiquidacao: moment(this.notaNegociacao.dataLiquidacao).format('DD/MM/YYYY'),
                        valorCorretagem: this.notaNegociacao.valorCorretagem,
                        valorEmolumentos: this.notaNegociacao.valorEmolumentos,
                        valorBruto: this.notaNegociacao.valorBruto,
                        valorBrutoAbsoluto: this.notaNegociacao.valorBrutoAbsoluto,
                        valorLiquido: this.notaNegociacao.valorLiquido,
                        valorImposto: this.notaNegociacao.valorImposto,
                        relacaoAtivos: this.notaNegociacao.relacaoAtivos,
                        usuario: this.notaNegociacao.usuario,
                        modeloCarteira: this.notaNegociacao.modeloCarteira.id
                    }

                    if (this.notaNegociacao.id == null) {
                        http.post('/notasNegociacao/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.notaNegociacao.id = response.data.id
                                this.notasNegociacao.push(this.notaNegociacao)
                                this.showInfo = true
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })

                    } else {
                        http.put('/notasNegociacao/editar/'+this.notaNegociacao.id, data).then(response =>{
                            this.notasNegociacao[this.notaNegociacao.index].corretoraUsuario = this.notaNegociacao.corretoraUsuario
                            this.notasNegociacao[this.notaNegociacao.index].dataNegociacao = response.data.dataNegociacao
                            this.notasNegociacao[this.notaNegociacao.index].dataLiquidacao = response.data.dataLiquidacao
                            this.notasNegociacao[this.notaNegociacao.index].valorCorretagem = response.data.valorCorretagem
                            this.notasNegociacao[this.notaNegociacao.index].valorEmolumentos = response.data.valorEmolumentos
                            this.notasNegociacao[this.notaNegociacao.index].valorBruto = response.data.valorBruto
                            this.notasNegociacao[this.notaNegociacao.index].valorBrutoAbsoluto = response.data.valorBrutoAbsoluto
                            this.notasNegociacao[this.notaNegociacao.index].valorLiquido = response.data.valorLiquido
                            this.notasNegociacao[this.notaNegociacao.index].relacaoAtivos = response.data.relacaoAtivos
                            this.notasNegociacao[this.notaNegociacao.index].valorImposto = response.data.valorImposto
                            this.notasNegociacao[this.notaNegociacao.index].usuario = response.data.usuario
                            this.notasNegociacao[this.notaNegociacao.index].modeloCarteira = this.notaNegociacao.modeloCarteira
                        }).catch(e => {
                            console.log(e);
                        })
                    }

                } else {
                    if (this.notaNegociacaoDetalhe.modeloCarteira == null) {
                        alert('Modelo de carteira não informado!')
                        return
                    }
                    
                    if (this.notaNegociacaoDetalhe.tipoNegociacao == null) {
                        alert('Tipo de negociação não informado!')
                        return
                    }

                    if (this.notaNegociacaoDetalhe.ativo == null) {
                        alert('Ativo não informado!')
                        return
                    }
                    
                    if (this.notaNegociacaoDetalhe.quantidade == null || this.notaNegociacaoDetalhe.quantidade == '' || this.notaNegociacaoDetalhe.quantidade == 0) {
                        alert('Quantidade não informada!')
                        return
                    }

                    if (this.notaNegociacaoDetalhe.preco == null || this.notaNegociacaoDetalhe.preco == '') {
                        alert('Preço não informado!')
                        return
                    }

                    if (this.notaNegociacaoDetalhe.imposto == null || this.notaNegociacaoDetalhe.imposto == '') {
                        alert('Imposto não informado!')
                        return
                    }

                    if (this.notaNegociacaoDetalhe.tipoNegociacao == "V" && this.notaNegociacaoDetalhe.quantidade >= 0) {
                        this.notaNegociacaoDetalhe.quantidade = (parseFloat(this.notaNegociacaoDetalhe.quantidade) * -1)
                    }

                    // if (this.notaNegociacaoDetalhe.tipoNegociacao == "B") {
                    //     this.notaNegociacaoDetalhe.custo = 0
                    //     this.notaNegociacaoDetalhe.imposto = 0
                    // } else {
                    //     this.notaNegociacaoDetalhe.valorTotal = parseFloat((parseFloat(this.notaNegociacaoDetalhe.quantidade).toFixed(8) * parseFloat(this.notaNegociacaoDetalhe.preco).toFixed(8)).toFixed(2))
                    //     this.notaNegociacaoDetalhe.custo = (((Math.abs(this.notaNegociacaoDetalhe.quantidade) * this.notaNegociacaoDetalhe.preco)/this.notaNegociacaoSelecionada.valorBrutoAbsoluto)*(parseFloat(this.notaNegociacaoSelecionada.valorCorretagem)+parseFloat(this.notaNegociacaoSelecionada.valorEmolumentos))).toFixed(8)
                    // }

                    this.notaNegociacaoDetalhe.valorTotal = parseFloat((parseFloat(this.notaNegociacaoDetalhe.quantidade).toFixed(8) * parseFloat(this.notaNegociacaoDetalhe.preco).toFixed(8)).toFixed(2))
                    if (this.notaNegociacaoSelecionada.valorBrutoAbsoluto != 0) {
                        this.notaNegociacaoDetalhe.custo = (((Math.abs(this.notaNegociacaoDetalhe.quantidade) * this.notaNegociacaoDetalhe.preco)/this.notaNegociacaoSelecionada.valorBrutoAbsoluto)*(parseFloat(this.notaNegociacaoSelecionada.valorCorretagem)+parseFloat(this.notaNegociacaoSelecionada.valorEmolumentos))).toFixed(8)
                    } else {
                        this.notaNegociacaoDetalhe.custo = 0
                    }

                    data = {id: this.notaNegociacaoDetalhe.id,
                        notaNegociacao: this.notaNegociacaoSelecionada.id,
                        ativo: this.notaNegociacaoDetalhe.ativo.id,
                        tipoNegociacao: this.notaNegociacaoDetalhe.tipoNegociacao,
                        quantidade: this.notaNegociacaoDetalhe.quantidade,
                        preco: this.notaNegociacaoDetalhe.preco,
                        valorTotal: this.notaNegociacaoDetalhe.valorTotal,
                        fator: this.notaNegociacaoDetalhe.fator,
                        imposto: this.notaNegociacaoDetalhe.imposto,
                        custo: this.notaNegociacaoDetalhe.custo,
                        conversaoReal: this.notaNegociacaoDetalhe.conversaoReal,
                        modeloCarteira: this.notaNegociacaoDetalhe.modeloCarteira.id
                    }

                    if (this.notaNegociacaoDetalhe.id == null) {
                        http.post('/notaNegociacaoDetalhes/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.notaNegociacaoDetalhe.id = response.data.id
                                this.notaNegociacaoDetalhes.push(this.notaNegociacaoDetalhe)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })

                    } else {
                        http.put('/notaNegociacaoDetalhes/editar/'+this.notaNegociacaoDetalhe.id, data).then(response =>{
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].ativo = this.notaNegociacaoDetalhe.ativo
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].tipoNegociacao = response.data.tipoNegociacao
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].tipoNegociacao = response.data.tipoNegociacao
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].quantidade = response.data.quantidade
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].preco = response.data.preco
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].valorTotal = response.data.valorTotal
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].custo = response.data.custo
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].fator = response.data.fator
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].imposto = response.data.imposto
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].conversaoReal = response.data.conversaoReal
                            this.notaNegociacaoDetalhes[this.notaNegociacaoDetalhe.index].modeloCarteira = this.notaNegociacaoDetalhe.modeloCarteira
                        }).catch(e => {
                            console.log(e);
                        })
                    }

                }    
            },

            callListAPI: function(nome) {
                http.get(nome, {params: {
                        cmbCorretora: this.cmbCorretora,
                        dataInicial: this.dataInicial,
                        dataFinal: this.dataFinal,
                        cmbModelo: this.cmbModelo
                }}).then(response => {
                    this.notasNegociacao = response.data.results
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages
                    if (this.notasNegociacao.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })
            },

            nextPage: function(){
                if (this.page != this.numpages) {
                    this.callListAPI(this.next)
                }
            },
            previousPage: function(){
                if (this.page != 1 ) {
                    this.callListAPI(this.previous)
                }
            },
            filterRecords: function(){
                if (this.dataInicial != null && this.dataFinal == null) {
                    alert('Data final não preenchida!')
                    return;
                } else if (this.dataInicial == null && this.dataFinal != null) {
                    alert('Data inicial não preenchida!')
                    return;
                }
                this.callListAPI('notasNegociacao/listar/')
            },

            callDetailListAPI: function(notaNegociacao) {
                this.mostraNotaNegociacaoDetalhe = true
                this.notaNegociacaoSelecionada = notaNegociacao
                http.get('notaNegociacaoDetalhes/listar', {params: {
                    idNotaNegociacao: notaNegociacao.id
                }}).then(response => {
                    this.notaNegociacaoDetalhes = response.data.results
                })
            }
        },

        mounted: function() {
            http.get('corretorasUsuarios/listar').then(response =>{
                this.corretorasUsuarios = response.data
            })

            http.get('ativos/listar', {
                params: {
                    paginacao: 'N'
                }
            }).then(response =>{
                this.ativos = response.data.results
            })

            http.get('modelagemCarteira/modeloCarteiraListar').then(response =>{
                this.modelosCarteiras = response.data
            })

        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>