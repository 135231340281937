<template>
    <div>
        <br>
        <h5 class="title is-5">Balancete
        </h5>

        <form>
            <div class="field is-horizontal">
                <div class="field-body">

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbTipoPeriodo" @change="selectTipoPeriodo()">
                                    <option value="0" disabled>Selecione um tipo de período</option>
                                    <option v-for="td in tiposPeriodo" :key="td.id" :value="td.id">
                                        {{td.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPerfil" @change="selectPerfil()">
                                    <option value="0" disabled>Selecione um perfil</option>
                                    <option v-for="perfilUsuario in perfisUsuario" :key="perfilUsuario.id" :value="perfilUsuario.perfil.id">
                                        {{perfilUsuario.perfil.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbPeriodoPerfil">
                                    <option value="0" disabled>Selecione um período</option>
                                    <option v-for="periodoPerfil in periodosPerfis" :key="periodoPerfil.id" :value="periodoPerfil.id">
                                        {{periodoPerfil.periodo.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select">
                                <select v-model="cmbCampoPesquisa">
                                    <option value="0">TODAS AS CONTAS</option>
                                    <option value="1">Conta Contábil</option>
                                    <option value="2">Descrição</option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-fullwidth">
                        <input class="input" type="text" placeholder="Informe o argumento da pesquisa" v-model="txtArgument" :disabled="cmbCampoPesquisa == '0'">
                    </div>

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>

            </div>
        </form>  

        <hr>

        <div class="columns" v-show="this.showInfo">
            <div class="column">
                <div class="table-container">                
                    <table class="table is-striped is-narrow is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                                <th><small>Conta</small></th>
                                <th><small>Descrição da Conta</small></th>
                                <th class="has-text-centered"><small>Saldo Anterior</small></th>
                                <th class="has-text-centered"><small>Débitos</small></th>
                                <th class="has-text-centered"><small>Créditos</small></th>
                                <th class="has-text-centered"><small>Movimento</small></th>
                                <th class="has-text-centered"><small>Saldo Final</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="balancete in balancetes" :key="balancete.id">
                                <td><small>{{balancete.plano_contas.conta_contabil}}</small></td>
                                <td><small>{{balancete.plano_contas.descricao}}</small></td>
                                <td class="has-text-centered"><small>{{balancete.saldo_anterior|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{balancete.valor_debito|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{balancete.valor_credito|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{balancete.valor_movimento|numberFormat}}</small></td>
                                <td class="has-text-centered"><small>{{balancete.saldo_final|numberFormat}}</small></td>
                            </tr>
                        </tbody>    
                    </table>
                </div>    
                <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                    <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.page == 1 || this.page == null">Anterior</a>
                    <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.page == this.numpages">Próxima</a>                
                </nav>
                <br>
            </div>
        </div>   
       

    </div>        

</template>

<script>
    // import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "Balancete",

        data(){
            return{
                periodosPerfis: [],
                perfisUsuario: [],
                balancetes: [],
                tiposPeriodo: [],
                previous: null,
                next: null,
                page: null,
                numpages: null,
                cmbPerfil: 0,
                cmbPeriodoPerfil: 0,
                cmbCampoPesquisa: 0,
                cmbTipoPeriodo: 0,
                txtArgument: null,
                showInfo: false
            }
        },

        methods: {
            nextPage: function(){
                if (this.page != this.numpages) {
                    http.get(this.next, {params: {
                        idPeriodoPerfil: this.cmbPeriodoPerfil,
                        cmbCampoPesquisa: this.cmbCampoPesquisa,
                        txtArgument: this.txtArgument
                    }}).then(response =>{
                        this.balancetes = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },
            previousPage: function(){
                if (this.page != 1 ) {
                    http.get(this.previous, {params: {
                        idPeriodoPerfil: this.cmbPeriodoPerfil,
                        cmbCampoPesquisa: this.cmbCampoPesquisa,
                        txtArgument: this.txtArgument
                    }}).then(response =>{
                        this.balancetes = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },
            filterRecords: function(){
                if (this.cmbPerfil == 0) {
                    alert('Selecione um perfil')
                    return
                }
                if (this.cmbPeriodoPerfil == 0) {
                    alert('Selecione um período')
                    return
                }
                http.get('balancete/listar/', {params: {
                    idPeriodoPerfil: this.cmbPeriodoPerfil,
                    cmbCampoPesquisa: this.cmbCampoPesquisa,
                    txtArgument: this.txtArgument
                }}).then(response => {
                    this.balancetes = response.data.results
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages

                    if (this.balancetes.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })

            },

            selectTipoPeriodo: function() {
                this.cmbPerfil = 0
                this.cmbPeriodoPerfil = 0
                this.cmbCampoPesquisa = 0
            },

            selectPerfil: function() {

                if (this.cmbTipoPeriodo == 0) {
                    alert('Selecione um tipo de período!')
                    return
                }

                http.get('periodoPerfil/listar/', {params: {
                    idPerfil: this.cmbPerfil,
                    idTipoPeriodo: this.cmbTipoPeriodo,
                    semPaginacao: 'S'
                }}).then(response => {
                    this.periodosPerfis = response.data.results
                    if (this.periodosPerfis.length == 0) {
                        alert('Não há períodos associados ao perfil selecionado!')
                    }
                })
            }

        },

        mounted: function() {
        },
        
        created: function() {
            http.get('compartilharPerfis/listarPerfis').then(response => {
                    this.perfisUsuario = response.data.results
            })

            http.get('tiposPeriodo/listar').then(response => {
                this.tiposPeriodo = response.data.results
            })

        }

    }
</script>

<style scoped>
</style>