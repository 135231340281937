<template>
    <div>
        <br>
        <h5 class="title is-5">Modelagem de Carteiras de Investimentos
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()" :disabled="mostraEstruturaModeloCarteira">
                Criar
            </button>  
        </h5>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Criar {{complementoTituloModal}}</p>
                    <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <!-- Formulário para criar/editar modelos de carteira -->
                    <form v-if="!this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo && !this.mostraEstruturaModeloCarteira">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <input class="input" type="text" placeholder="Informe um nome para o modelo" v-model="modeloCarteira.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentário</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <textarea class="textarea" placeholder="Descreva os objetivos desse modelo de carteira" v-model="modeloCarteira.comentario"></textarea>
                                </div>
                            </div>
                        </div>
                    </form>  

                    <!-- Formulário para criar/editar classes de ativos do modelos de carteira -->
                    <form v-if="this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo && !this.mostraEstruturaModeloCarteira">
                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label"><small>Classe de Ativo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="modeloCarteiraClasseAtivo.classeAtivo">
                                            <option v-for="classeAtivo in classesAtivos" :key="classeAtivo.id" :value="classeAtivo">
                                                {{classeAtivo.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>    

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Percentual</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <input class="input" type="number" placeholder="Informe o percentual da classe do ativo para o modelo" v-model="modeloCarteiraClasseAtivo.percentual">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentário</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <textarea class="textarea" placeholder="Descreva os objetivos da classe de ativo no modelo de carteira" v-model="modeloCarteiraClasseAtivo.comentario"></textarea>
                                </div>
                            </div>
                        </div>
                    </form>  

                    <!-- Formulário para criar/editar ativos da classe de ativo do modelos de carteira -->
                    <form v-if="this.mostraModeloCarteiraClasseAtivo && this.mostraModeloCarteiraAtivo && !this.mostraEstruturaModeloCarteira">
                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label"><small>Ativo</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="modeloCarteiraAtivo.ativo">
                                            <option v-for="ativo in ativos" :key="ativo.id" :value="ativo">
                                                {{ativo.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>    

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Percentual</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <input class="input" type="number" placeholder="Informe o percentual  do ativo para a classe de ativo do modelo" v-model="modeloCarteiraAtivo.percentual">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Comentário</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <textarea class="textarea" placeholder="Descreva os objetivos do ativo da classe de ativo do modelo de carteira" v-model="modeloCarteiraAtivo.comentario"></textarea>
                                </div>
                            </div>
                        </div>
                    </form>  
                </section>    

                <footer class="modal-card-foot">
                    <button class="button is-success" @click="save()">Salvar</button>
                    <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>

            </div>
        </div>        

        <div class="columns" v-show="this.showInfo">
            <div class="column"></div>
            <div class="column is-two-thirds">
                <div class="table-container">                
                    <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th><small>Modelo</small></th>
                                <th><small>Comentário</small></th>
                                <th colspan="5" class="has-text-center"><small>Ações</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(modeloCarteira, index) in modelosCarteiras" :key="modeloCarteira.id">
                                <td class="has-text-weight-bold"><small>{{modeloCarteira.descricao}}</small></td>
                                <td class="is-italic"><small>{{modeloCarteira.comentario}}</small></td>
                                <td class="has-text-center"><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)" title="Editar registro" :disabled="mostraModeloCarteiraClasseAtivo || mostraEstruturaModeloCarteira"><span class="fas fa-edit"></span></button></td>
                                <td class="has-text-center"><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" title="Excluir registro" :disabled="mostraModeloCarteiraClasseAtivo || mostraEstruturaModeloCarteira"><span class="fas fa-trash-alt"></span></button></td>
                                <td class="has-text-center"><button class="button is-ghost is-small is-inverted is-link" @click="mostrarClassesDeAtivos(modeloCarteira)" title="Classes de ativos do modelo" :disabled="mostraModeloCarteiraClasseAtivo || mostraEstruturaModeloCarteira"><span class="fas fa-th-list"></span></button></td>
                                <td class="has-text-center"><button class="button is-ghost is-small is-inverted is-link" @click="mostrarEstrutura(modeloCarteira)" title="Estrutura completa do modelo da carteira" :disabled="mostraModeloCarteiraClasseAtivo || mostraEstruturaModeloCarteira"><span class="fas fa-stream"></span></button></td>
                                <td class="has-text-center"><button class="button is-ghost is-small is-inverted is-link" @click="compartilharModeloCarteira(modeloCarteira)" title="Estrutura completa do modelo da carteira" :disabled="mostraModeloCarteiraClasseAtivo || mostraEstruturaModeloCarteira"><span class="fas fa-share-alt"></span></button></td>
                            </tr>
                        </tbody>    
                    </table>
                </div>    

                <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                    <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.page == 1 || this.page == null">Anterior</a>
                    <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.page == this.numpages">Próxima</a>                
                </nav>
                <br>
            </div>
            <div class="column"></div>
        </div>    

        <div class="columns" v-show="this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo && !this.mostraEstruturaModeloCarteira">
            <div class="column">
            </div>
            <div class="column is-three-fifths">
                <article  class="panel is-primary" >

                    <header class="panel-heading">
                        <p>
                            Classes de Ativos do Modelo
                        </p>
                    </header>

                    <content class="content">
                        <div class="panel-block">
                            <div class="table-container">                
                                <table class="table is-narrow is-hoverable is-fullwidth is-striped is-centered">
                                    <thead>
                                        <tr>
                                            <th class="has-text-centered"><small>Classe de Ativo</small></th>
                                            <th class="has-text-centered"><small>Percentual</small></th>
                                            <th><small>Comentário</small></th>
                                            <th colspan="3" class="has-text-center"><small>Ações</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(modeloCarteiraClasseAtivo, index) in modeloCarteiraClassesAtivos" :key="modeloCarteiraClasseAtivo.id">
                                            <td><small>{{modeloCarteiraClasseAtivo.classeAtivo.descricao}}</small></td>
                                            <td><small>{{modeloCarteiraClasseAtivo.percentual|numberFormat}}</small></td>
                                            <td><small>{{modeloCarteiraClasseAtivo.comentario}}</small></td>
                                            <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)" :disabled="mostraModeloCarteiraAtivo" title="Editar registro"><span class="fas fa-edit"></span></button></small></td>
                                            <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" :disabled="mostraModeloCarteiraAtivo" title="Excluir registro"><span class="fas fa-trash-alt"></span></button></small></td>
                                            <td><small><button class="button is-ghost is-small is-inverted is-link" @click="mostrarAtivos(modeloCarteiraClasseAtivo)" :disabled="mostraModeloCarteiraAtivo" title="Ativos da classe de ativo do modelo"><span class="fas fa-th-list"></span></button></small></td>
                                        </tr>
                                    </tbody>    
                                </table>
                            </div>    
                        </div>
                    </content>

                    <footer class="card-footer">
                        <a v-on:click="mostraModeloCarteiraClasseAtivo = false" class="card-footer-item">Fechar</a>
                    </footer>            
                </article > 
            </div>
            <div class="column">
            </div>
        </div>

        <div class="columns" v-show="this.mostraModeloCarteiraClasseAtivo && this.mostraModeloCarteiraAtivo && !this.mostraEstruturaModeloCarteira">
            <div class="column">
            </div>
            <div class="column is-three-fifths">
                <article  class="panel is-primary" >

                    <header class="panel-heading">
                        <p>
                            Ativos do Modelo
                        </p>
                    </header>

                    <content class="content">
                        <div class="panel-block">
                            <div class="table-container">                
                                <table class="table is-narrow is-hoverable is-fullwidth is-striped is-centered">
                                    <thead>
                                        <tr>
                                            <th class="has-text-centered"><small>Ativo</small></th>
                                            <th class="has-text-centered"><small>Percentual</small></th>
                                            <th><small>Comentário</small></th>
                                            <th colspan="2" class="has-text-center"><small>Ações</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(modeloCarteiraAtivo, index) in modeloCarteiraAtivos" :key="modeloCarteiraAtivo.id">
                                            <td><small>{{modeloCarteiraAtivo.ativo.descricao}}</small></td>
                                            <td><small>{{modeloCarteiraAtivo.percentual|numberFormat}}</small></td>
                                            <td><small>{{modeloCarteiraAtivo.comentario}}</small></td>
                                            <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)" title="Editar registro"><span class="fas fa-edit"></span></button></small></td>
                                            <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)" title="Excluir registro"><span class="fas fa-trash-alt"></span></button></small></td>
                                        </tr>
                                    </tbody>    
                                </table>
                            </div>    
                        </div>
                    </content>

                    <footer class="card-footer">
                        <a v-on:click="mostraModeloCarteiraAtivo = false" class="card-footer-item">Fechar</a>
                    </footer>            
                </article > 
            </div>

            <div class="column">
            </div>
        </div>

        <div class="columns" v-show="this.mostraEstruturaModeloCarteira">

            <div class="column">
            </div>
            <div class="column is-three-fifths">
                <article  class="panel is-primary" >

                    <header class="panel-heading">
                        <p>
                            Estrutura
                        </p>
                    </header>

                    <content class="content">
                        <div class="panel-block">

                            <ul id="example-2">
                                <li v-for="modeloCarteiraClasseAtivo in modeloCarteiraClassesAtivos" :key="modeloCarteiraClasseAtivo.id">
                                    <strong>{{modeloCarteiraClasseAtivo.classeAtivo.descricao}} ({{modeloCarteiraClasseAtivo.percentual|numberFormat}} %)</strong>

                                    <div class="table-container">     
                                        <table class="table is-narrow is-hoverable is-fullwidth is-striped is-centered is-bordered">
                                                <thead>
                                                    <tr>
                                                        <th><small>Empresa</small></th>
                                                        <th><small>Setor</small></th>
                                                        <th><small>Percentual</small></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(modeloCarteiraAtivo, index) in modeloCarteiraClasseAtivo.modeloCarteiraClasseAtivoModeloCarteiraAtivos" :key="index">
                                                        <td v-if="modeloCarteiraAtivo.ativo.segmento == null"><small>{{modeloCarteiraAtivo.ativo.empresa.descricao}}</small></td>
                                                        <td v-if="modeloCarteiraAtivo.ativo.segmento != null"><small>{{modeloCarteiraAtivo.ativo.descricao}}</small></td>
                                                        <td v-if="modeloCarteiraAtivo.ativo.segmento == null"><small>{{modeloCarteiraAtivo.ativo.empresa.segmento.descricao}}</small></td>
                                                        <td v-if="modeloCarteiraAtivo.ativo.segmento != null"><small>{{modeloCarteiraAtivo.ativo.segmento.descricao}}</small></td>
                                                        <td><small>{{modeloCarteiraAtivo.percentual|numberFormat}}</small></td>
                                                    </tr>
                                                </tbody>    
                                        </table>
                                    </div>    
                                    <br>
    
                                </li>
                            </ul>                            

                        </div>
                    </content>

                    <footer class="card-footer">
                        <a v-on:click="mostraEstruturaModeloCarteira = false" class="card-footer-item">Fechar</a>
                    </footer>            
                </article > 
                <br>
                <br>

            </div>

            <div class="column">
            </div>


        </div>
    </div>        

</template>

<script>
    import {http} from '../../services/config'

    export default {
        name: "ModeloCarteira",

        data(){
            return{
                modeloCarteira: {
                    id: null,
                    descricao: null,
                    comentario: null,
                    usuario: null,
                    index: null
                },
                modeloCarteiraClasseAtivo: {
                    id: null,
                    modeloCarteira: null,
                    classeAtivo: null,
                    percentual: null,
                    comentario: null,
                    index: null
                },
                modeloCarteiraAtivo: {
                    id: null,
                    modeloCarteiraClasseAtivo: null,
                    ativo: null,
                    percentual: null,
                    comentario: null,
                    index: null
                },
                modelosCarteiras: [],
                modeloCarteiraClassesAtivos: [],
                modeloCarteiraAtivos: [],
                classesAtivos: [],
                ativos: [],
                isShowModal: false,
                showInfo: false,
                mostraModeloCarteiraClasseAtivo: false,
                mostraModeloCarteiraAtivo: false,
                mostraEstruturaModeloCarteira: false,
                previous: null,
                next: null,
                page: null,
                numpages: null,
                modeloCarteiraSelecionado: null,
                modeloCarteiraClasseAtivoSelecionado: null,
                complementoTituloModal: null,
            }
        },

        methods: {
            clearFields: function() {
                if (!this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo) {
                    this.complementoTituloModal = "Modelo de Carteira"
                    this.modeloCarteira = { id: null,
                        descricao: null,
                        comentario: null,
                        usuario: this.$store.state.idUser }
                }  else if (this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo) {
                    this.complementoTituloModal = "Classe de Ativo do Modelo"
                    this.modeloCarteiraClasseAtivo = { id: null,
                        modeloCarteira: null,
                        classeAtivo: null,
                        percentual: 0,
                        comentario: null
                    }
                }  else if (this.mostraModeloCarteiraAtivo && this.mostraModeloCarteiraAtivo) {
                    this.complementoTituloModal = "Ativo do Modelo"
                    this.modeloCarteiraAtivo = { id: null,
                        modeloCarteiraClasseAtivo: null,
                        ativo: null,
                        percentual: 0,
                        comentario: null
                    }
                }
            },
            nextPage: function(){
                if (this.page != this.numpages) {
                    http.get(this.next).then(response =>{
                        this.modelosCarteiras = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },
            previousPage: function(){
                if (this.page != 1 ) {
                    http.get(this.previous).then(response =>{
                        this.modelosCarteiras = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },

            mostrarClassesDeAtivos: function(modeloCarteira) {
                this.mostraModeloCarteiraClasseAtivo = true
                this.modeloCarteiraSelecionado = modeloCarteira
                http.get('modelagemCarteira/modeloCarteiraClasseAtivoListar', {params: {
                    idModeloCarteira: modeloCarteira.id
                }}).then(response => {
                    this.modeloCarteiraClassesAtivos = response.data
                })
            },

            mostrarAtivos: function(modeloCarteiraClasseAtivo) {
                this.mostraModeloCarteiraAtivo = true
                this.modeloCarteiraClasseAtivoSelecionado = modeloCarteiraClasseAtivo

                http.get('ativos/listar/', {params: {
                    cmbClasseAtivo: modeloCarteiraClasseAtivo.classeAtivo.id,
                    paginacao: 'N'
                }}).then(response =>{
                    this.ativos = response.data.results
                }).catch(e => {
                    console.log(e);
                })

                http.get('modelagemCarteira/modeloCarteiraAtivoListar', {params: {
                    idModeloCarteiraClasseAtivo: modeloCarteiraClasseAtivo.id,
                    idModeloCarteira: 0
                }}).then(response => {
                    this.modeloCarteiraAtivos = response.data
                })
            },

            compartilharModeloCarteira: function(modeloCarteira) {
                http.get('aportesmodeloCarteiraCompartilhar/listar', {params: {
                    idModeloCarteira: modeloCarteira.id
                }}).then(response => {
                    console.log( response.data )
                })

            },

            mostrarEstrutura: function(modeloCarteira)             {
                this.mostraEstruturaModeloCarteira = true

                http.get('modelagemCarteira/modeloCarteiraAtivoListar', {params: {
                    idModeloCarteiraClasseAtivo: null,
                    idModeloCarteira: modeloCarteira.id
                }}).then(response => {
                    this.modeloCarteiraAtivos = response.data
                })

                http.get('modelagemCarteira/modeloCarteiraClasseAtivoListar', {params: {
                    idModeloCarteira: modeloCarteira.id
                }}).then(response => {
                    this.modeloCarteiraClassesAtivos = response.data
                })


            },

            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                if (!this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo) {
                    this.modeloCarteira.id = this.modelosCarteiras[index].id
                    this.modeloCarteira.descricao = this.modelosCarteiras[index].descricao
                    this.modeloCarteira.comentario = this.modelosCarteiras[index].comentario
                    this.modeloCarteira.usuario = this.modelosCarteiras[index].usuario
                    this.modeloCarteira.index = index
                    this.isShowModal = true
                } else if (this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo) {
                    this.modeloCarteiraClasseAtivo.id = this.modeloCarteiraClassesAtivos[index].id
                    this.modeloCarteiraClasseAtivo.modeloCarteira = this.modeloCarteiraClassesAtivos[index].modeloCarteira
                    this.modeloCarteiraClasseAtivo.classeAtivo = this.modeloCarteiraClassesAtivos[index].classeAtivo
                    this.modeloCarteiraClasseAtivo.percentual = this.modeloCarteiraClassesAtivos[index].percentual
                    this.modeloCarteiraClasseAtivo.comentario = this.modeloCarteiraClassesAtivos[index].comentario
                    this.modeloCarteiraClasseAtivo.index = index
                    this.isShowModal = true              
                } else if (this.mostraModeloCarteiraAtivo) {
                    this.modeloCarteiraAtivo.id = this.modeloCarteiraAtivos[index].id
                    this.modeloCarteiraAtivo.modeloCarteiraClasseAtivo = this.modeloCarteiraAtivos[index].modeloCarteiraClasseAtivo
                    this.modeloCarteiraAtivo.ativo = this.modeloCarteiraAtivos[index].ativo
                    this.modeloCarteiraAtivo.percentual = this.modeloCarteiraAtivos[index].percentual
                    this.modeloCarteiraAtivo.comentario = this.modeloCarteiraAtivos[index].comentario
                    this.modeloCarteiraAtivo.index = index
                    this.isShowModal = true    
                }

            },

            erase: function(index){
                if (!this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo) {
                    if (window.confirm('Confirma exclusão?')) {
                        http.delete('/modelagemCarteira/modeloCarteiraExcluir/'+this.modelosCarteiras[index].id).then(response =>{
                            this.modelosCarteiras.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                } else if (this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo) {
                    if (window.confirm('Confirma exclusão?')) {
                        http.delete('/modelagemCarteira/modeloCarteiraClasseAtivoExcluir/'+this.modeloCarteiraClassesAtivos[index].id).then(response =>{
                            this.modeloCarteiraClassesAtivos.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                } else if (this.mostraModeloCarteiraAtivo && this.mostraModeloCarteiraAtivo) {
                    if (window.confirm('Confirma exclusão?')) {
                        http.delete('/modelagemCarteira/modeloCarteiraAtivoExcluir/'+this.modeloCarteiraAtivos[index].id).then(response =>{
                            this.modeloCarteiraAtivos.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    }

                }    
            },

            save: function(){
                var data

                if (!this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo) {
                    if (this.modeloCarteira.descricao == null || this.modeloCarteira.descricao == "") {
                        alert('Descrição não informada!')
                        return
                    }
                    data = {id: this.modeloCarteira.id,
                        descricao: this.modeloCarteira.descricao,
                        comentario: this.modeloCarteira.comentario,
                        usuario: this.modeloCarteira.usuario
                    }
                    if (this.modeloCarteira.id == null) {
                        http.post('/modelagemCarteira/modeloCarteiraAdicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.modeloCarteira.id = response.data.id
                                this.modelosCarteiras.push(this.modeloCarteira)
                                this.showInfo = true
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else {
                        http.put('modelagemCarteira/modeloCarteiraEditar/'+this.modeloCarteira.id, data).then(response =>{
                            this.modelosCarteiras[this.modeloCarteira.index].descricao = response.data.descricao
                            this.modelosCarteiras[this.modeloCarteira.index].comentario = response.data.comentario
                        }).catch(e => {
                            console.log(e);
                        })
                    }

                } else if ( this.mostraModeloCarteiraClasseAtivo && !this.mostraModeloCarteiraAtivo ) {
                    if (this.modeloCarteiraClasseAtivo.classeAtivo == null) {
                        alert('Classe de ativo não informada')
                        return
                    }
                    if (this.modeloCarteiraClasseAtivo.percentual == null) {
                        alert('Percentual não informado ou igual a zero!')
                        return
                    }
                    data = {id: this.modeloCarteiraClasseAtivo.id,
                        modeloCarteira: this.modeloCarteiraSelecionado.id,
                        classeAtivo: this.modeloCarteiraClasseAtivo.classeAtivo.id,
                        percentual: this.modeloCarteiraClasseAtivo.percentual,
                        comentario: this.modeloCarteiraClasseAtivo.comentario
                    }

                    if (this.modeloCarteiraClasseAtivo.id == null) {
                        http.post('/modelagemCarteira/modeloCarteiraClasseAtivoAdicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.modeloCarteiraClasseAtivo.id = response.data.id
                                this.modeloCarteiraClassesAtivos.push(this.modeloCarteiraClasseAtivo)
                                this.clearFields()
                            } else if ( response.data.error != undefined){
                                alert("Erro ao adicionar uma classe de ativo no modelo: "+response.data.error)
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else {
                        http.put('modelagemCarteira/modeloCarteiraClasseAtivoEditar/'+this.modeloCarteiraClasseAtivo.id, data).then(response =>{
                            this.modeloCarteiraClassesAtivos[this.modeloCarteiraClasseAtivo.index].classeAtivo = this.modeloCarteiraClasseAtivo.classeAtivo
                            this.modeloCarteiraClassesAtivos[this.modeloCarteiraClasseAtivo.index].percentual = response.data.percentual
                            this.modeloCarteiraClassesAtivos[this.modeloCarteiraClasseAtivo.index].comentario = response.data.comentario
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                } else if ( this.mostraModeloCarteiraClasseAtivo && this.mostraModeloCarteiraAtivo ) {
                    if (this.modeloCarteiraAtivo.ativo == null) {
                        alert('Ativo não informada')
                        return
                    }
                    if (this.modeloCarteiraAtivo.percentual == null) {
                        alert('Percentual não informado ou igual a zero!')
                        return
                    }
                    data = {id: this.modeloCarteiraAtivo.id,
                        modeloCarteiraClasseAtivo: this.modeloCarteiraClasseAtivoSelecionado.id,
                        ativo: this.modeloCarteiraAtivo.ativo.id,
                        percentual: this.modeloCarteiraAtivo.percentual,
                        comentario: this.modeloCarteiraAtivo.comentario
                    }
                    if (this.modeloCarteiraAtivo.id == null) {
                        http.post('/modelagemCarteira/modeloCarteiraAtivoAdicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.modeloCarteiraAtivo.id = response.data.id
                                this.modeloCarteiraAtivos.push(this.modeloCarteiraAtivo)
                                this.clearFields()
                            } else if ( response.data.error != undefined){
                                alert("Erro ao adicionar um ativo na classe de ativo do modelo: "+response.data.error)
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                    } else {
                        http.put('modelagemCarteira/modeloCarteiraAtivoEditar/'+this.modeloCarteiraAtivo.id, data).then(response =>{
                            this.modeloCarteiraAtivos[this.modeloCarteiraAtivo.index].ativo = this.modeloCarteiraAtivo.ativo
                            this.modeloCarteiraAtivos[this.modeloCarteiraAtivo.index].percentual = response.data.percentual
                            this.modeloCarteiraAtivos[this.modeloCarteiraAtivo.index].comentario = response.data.comentario
                        }).catch(e => {
                            console.log(e);
                        })
                    }
                }    
            },

            filterRecords: function(){
            }
        },

        mounted: function() {
            http.get('modelagemCarteira/modeloCarteiraListar').then(response =>{
                this.modelosCarteiras = response.data
                if (this.modelosCarteiras.length != 0) {
                    this.showInfo = true
                } else {
                    this.showInfo = false
                }
            }).catch(function(){
                alert("Você não tem permissão para acessar essas informações")
            })

            http.get('classesativos/').then(response =>{
                this.classesAtivos = response.data.results
            })

        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>