import { render, staticRenderFns } from "./DemonstracaoResultadoVersao.vue?vue&type=template&id=1fbeaa96&scoped=true"
import script from "./DemonstracaoResultadoVersao.vue?vue&type=script&lang=js"
export * from "./DemonstracaoResultadoVersao.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fbeaa96",
  null
  
)

export default component.exports