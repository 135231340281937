<template>
    <div>
        <br>
        <h5 class="title is-5">Balanco Patrimonial
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="processar()" :disabled="this.cmbPeriodoPerfil.estaFechado">
                Processar
            </button>  
        </h5>

        <form>
            <div class="field is-horizontal">
                <div class="field-body">

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbTipoPeriodo" @change="selectTipoPeriodo()">
                                    <option value="0" disabled>Selecione um tipo de período</option>
                                    <option v-for="td in tiposPeriodo" :key="td.id" :value="td.id">
                                        {{td.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPerfil" @change="selectPerfil()">
                                    <option value="0" disabled>Selecione um perfil</option>
                                    <option v-for="perfilUsuario in perfisUsuario" :key="perfilUsuario.id" :value="perfilUsuario.perfil.id">
                                        {{perfilUsuario.perfil.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbPeriodoPerfil" @change="selectPeriodoPerfil()">
                                    <option value="0" disabled>Selecione um período</option>
                                    <option v-for="periodoPerfil in periodosPerfis" :key="periodoPerfil.id" :value="periodoPerfil">
                                        {{periodoPerfil.periodo.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbBalancoPatrimonialVersao">
                                    <option value="0" disabled>Selecione um balanço patrimonial</option>
                                    <option v-for="bp in balancosPatrimoniais" :key="bp.id" :value="bp.balanco_patrimonial_versao">
                                        {{bp.balanco_patrimonial_versao.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>

            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isProcessing }">
            <div class="modal-background"></div>
            <div class="modal-content">
                <progress class="progress is-large is-primary" max="100">50%</progress>
            </div>
        </div>        

        <div class="columns" v-show="this.showInfo">
            <div class="column is-half">
                <nav class="panel is-success" v-if="cmbBalancoPatrimonialVersao != 0">
                    <p class="panel-heading">
                        ATIVO
                    </p>
                    <div class="panel-block">
                        <div class="table-container">                
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left is-size-7"><small></small></th>
                                        <th class="has-text-centered is-size-7"><small>{{anoMesReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light">AV%</th>
                                        <th class="has-text-centered is-size-7"><small>{{anoMesMesAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light">AH%</th>
                                        <th class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != 6"><small>{{anoMesAnoAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light" v-if="cmbTipoPeriodo != 6">AH%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="bp in balancosPatrimoniaisPeriodo" :key="bp.id">
                                        <td class="is-size-7" v-if="bp.balanco_patrimonial_versao_config.e_negrito && bp.balanco_patrimonial_versao_config.destino=='A'"><small><strong>{{bp.balanco_patrimonial_versao_config.descricao}}</strong></small></td>
                                        <td class="is-size-7" v-if="!bp.balanco_patrimonial_versao_config.e_negrito && bp.balanco_patrimonial_versao_config.destino=='A'"><small>{{bp.balanco_patrimonial_versao_config.descricao}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='A'"><small>{{bp.valor|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-link has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A'"><small>{{bp.perc_av|numberFormat}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='A'"><small>{{bp.valorMesAnterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_mes_anterior=='1'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_mes_anterior=='2'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_mes_anterior=='3'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && cmbTipoPeriodo != '6'"><small>{{bp.valorAnoAnterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_ano_anterior=='1' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_ano_anterior=='2' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='A' && bp.cor_ah_ano_anterior=='3' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>

                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </nav>
            </div>

            <div class="column is-half">
                <nav class="panel is-link" v-if="cmbBalancoPatrimonialVersao != 0">
                    <p class="panel-heading">
                        PASSIVO
                    </p>
                    <div class="panel-block">
                        <div class="table-container">                
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th class="has-text-left is-size-7"><small></small></th>
                                        <th class="has-text-centered is-size-7"><small>{{anoMesReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light">AV%</th>
                                        <th class="has-text-centered is-size-7"><small>{{anoMesMesAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light">AH%</th>
                                        <th class="has-text-centered is-size-7" v-if="cmbTipoPeriodo != '6'"><small>{{anoMesAnoAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                        <th class="has-text-centered is-size-7 has-text-grey-light" v-if="cmbTipoPeriodo != '6'">AH%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="bp in balancosPatrimoniaisPeriodo" :key="bp.id">
                                        <td class="is-size-7" v-if="bp.balanco_patrimonial_versao_config.e_negrito && bp.balanco_patrimonial_versao_config.destino=='P'"><small><strong>{{bp.balanco_patrimonial_versao_config.descricao}}</strong></small></td>
                                        <td class="is-size-7" v-if="!bp.balanco_patrimonial_versao_config.e_negrito && bp.balanco_patrimonial_versao_config.destino=='P'"><small>{{bp.balanco_patrimonial_versao_config.descricao}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='P'"><small>{{bp.valor|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-link has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P'"><small>{{bp.perc_av|numberFormat}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='P'"><small>{{bp.valorMesAnterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_mes_anterior=='1'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_mes_anterior=='2'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_mes_anterior=='3'"><small>{{bp.perc_ah_mes_anterior|numberFormat}}</small></td>

                                        <td class="has-text-centered is-size-7" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && cmbTipoPeriodo != '6'"><small>{{bp.valorAnoAnterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-success has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_ano_anterior=='1' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-danger has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_ano_anterior=='2' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                        <td class="has-text-centered is-size-7 has-text-info has-text-weight-bold" v-if="bp.balanco_patrimonial_versao_config.destino=='P' && bp.cor_ah_ano_anterior=='3' && cmbTipoPeriodo != '6'"><small>{{bp.perc_ah_ano_anterior|numberFormat}}</small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </nav> 
            </div>

        </div>        
    </div>     

</template>

<script>
    import {http} from '../../services/config'

    export default {
        name: "BalancoPatrimonialPeriodo",

        data(){
            return{
                periodosPerfis: [],
                perfisUsuario: [],
                balancosPatrimoniais: [],
                balancosPatrimoniaisPeriodo: [],                
                linhasBalancoPatrimonial: [],
                tiposPeriodo: [],
                cmbPerfil: 0,
                cmbPeriodoPerfil: 0,
                cmbBalancoPatrimonialVersao: 0,
                cmbTipoPeriodo: 0,
                anoMesReferencia: "",
                anoMesMesAnteriorReferencia: "",
                anoMesAnoAnteriorReferencia: "",
                anoMes3AnosAnteriorReferencia: "",
                anoMes5AnosAnteriorReferencia: "",
                anoMes10AnosAnteriorReferencia: "",
                showInfo: false,
                isProcessing: false
            }
        },

        methods: {
            filterRecords: function(){
                if (this.cmbPerfil == 0) {
                    alert('Selecione um perfil')
                    return
                }
                if (this.cmbPeriodoPerfil == 0) {
                    alert('Selecione um período')
                    return
                }
                if (this.cmbBalancoPatrimonialVersao == 0) {
                    alert('Selecione um balanço patrimonial')
                    return
                }
                http.get('balancoPatrimonialPeriodo/listar/', {params: {
                    idPeriodoPerfil: this.cmbPeriodoPerfil.id
                }}).then(response => {
                    this.balancosPatrimoniaisPeriodo = response.data.results

                    if (this.balancosPatrimoniaisPeriodo.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })

            },

            selectTipoPeriodo: function() {
                this.cmbPerfil = 0
                this.cmbPeriodoPerfil = 0
                this.cmbBalancoPatrimonialVersao = 0
                this.balancosPatrimoniaisPeriodo = []
                this.anoMesReferencia = ""
                this.anoMesMesAnteriorReferencia = ""
                this.anoMesAnoAnteriorReferencia = ""
                this.anoMes3AnosAnteriorReferencia = ""
                this.anoMes5AnosAnteriorReferencia = ""
                this.anoMes10AnosAnteriorReferencia = ""

            },


            selectPerfil: function() {
                http.get('periodoPerfil/listar/', {params: {
                    idPerfil: this.cmbPerfil,
                    idTipoPeriodo: this.cmbTipoPeriodo,
                    semPaginacao: 'S'
                }}).then(response => {
                    this.periodosPerfis = response.data.results
                    if (this.periodosPerfis.length == 0) {
                        alert('Não há períodos associados ao perfil selecionado!')
                    }
                })
            },

            selectPeriodoPerfil: function() {
                http.get('balancoPatrimonialVersao/listarBalancos/', {params: {
                    idVersaoPlanoContas: this.cmbPeriodoPerfil.versao_plano_contas.id,
                }}).then(response => {
                    this.balancosPatrimoniais = response.data.results
                    this.anoMesReferencia = this.cmbPeriodoPerfil.periodo.data
                    this.anoMesMesAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_mes_anterior
                    this.anoMesAnoAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_ano_anterior
                    this.anoMes3AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_3anos_anteriores
                    this.anoMes5AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_5anos_anteriores
                    this.anoMes10AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_10anos_anteriores

                    if (this.balancosPatrimoniais.length == 0) {
                        alert('Não há balanços patrimoniais associados a versão associada ao período selecionado!')
                    }
                })

                if (this.cmbBalancoPatrimonialVersao != 0) {
                    this.filterRecords()
                }

            },


            processar: function() {
                if (this.cmbPerfil == 0) {
                    alert('Perfil não selecionado!')
                    return
                }

                if (this.cmbPeriodoPerfil == 0) {
                    alert('Período não selecionado!')
                    return
                }

                if (this.cmbBalancoPatrimonialVersao == 0) {
                    alert('Balanço patrimonial não selecionado!')
                    return
                }

                if (window.confirm('Confirma o processamento do balanco patrimonial?')) {
                    this.isProcessing = true
                    http.put('/balancoPatrimonialPeriodo/processar/'+this.cmbPeriodoPerfil.id, this.cmbBalancoPatrimonialVersao).then(response =>{
                        if (response.status == 204) {
                            this.isProcessing = false
                            this.filterRecords()
                            alert('Processamento concluído!')
                        }
                    }).catch(e => {
                        this.isProcessing = false
                        console.log(e);
                    })
                }


            }

        },

        mounted: function() {
        },
        
        created: function() {
            http.get('compartilharPerfis/listarPerfis').then(response => {
                    this.perfisUsuario = response.data.results
            })

            http.get('tiposPeriodo/listar').then(response => {
                this.tiposPeriodo = response.data.results
            })

        }

    }
</script>

<style scoped>
</style>