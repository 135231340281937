<template>
    <div>
        <br>
        <h5 class="title is-5">Seleção de Corretoras
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
                Selecionar
            </button>  
        </h5>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Selecionar Corretora</p>
                    <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Corretora</small></label>
                            </div>  
                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select v-model="corretoraUsuario.corretora">
                                                <option v-for="corretora in corretoras" :key="corretora.id" :value="corretora">
                                                    {{corretora.nomeCurto}}
                                                </option>
                                            </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Início</small></label>
                            </div>  
                            <div class="field-body">
                                <div class="field">
                                    <input class="input" type="date" placeholder="Informe a data inicial" v-model="corretoraUsuario.dataInicio">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Encerramento</small></label>
                            </div>  
                            <div class="field-body">
                                <div class="field">
                                    <input class="input" type="date" placeholder="Informe a data de término" v-model="corretoraUsuario.dataTermino">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Dashboard?</small></label>
                            </div>  

                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select v-model="corretoraUsuario.exibeResumo">
                                                <option value="N">Não</option>
                                                <option value="S">Sim</option>
                                            </select>
                                        </div>    
                                    </div>
                                </div>    
                            </div>

                        </div>  


                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div v-show="this.showInfo">
            <div class="table-container">                
                <table class="table is-narrow is-hoverable is-fullwidth is-small is-striped">
                    <thead>
                        <tr>
                            <th><small>Corretora</small></th>
                            <th><small>País</small></th>
                            <th><small>Código</small></th>
                            <th><small>Início</small></th>
                            <th><small>Término</small></th>
                            <th colspan="3" class="has-text-center"><small>Ações</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(corretoraUsuario, index) in corretorasUsuarios" :key="corretoraUsuario.id">
                            <td><small>{{corretoraUsuario.corretora.nomeCurto}}</small></td>
                            <td><small>{{corretoraUsuario.corretora.pais.descricao}}</small></td>
                            <td><small>{{corretoraUsuario.corretora.codigoPais}}</small></td>
                            <td><small>{{corretoraUsuario.dataInicio|dateDDMMYYYY}}</small></td>
                            <td><small>{{corretoraUsuario.dataTermino|dateDDMMYYYY}}</small></td>
                            <td class="has-text-center"><a @click="edit(index)"><span class="fas fa-edit"></span></a></td>
                            <td class="has-text-center"><a @click="erase(index)"><span class="fas fa-trash-alt"></span></a></td>
                        </tr>
                    </tbody>    
                </table>
            </div>    
        </div>    

    </div>        

</template>

<script>
    import {http} from '../../services/config'
    import moment from 'moment'

    export default {
        name: "CorretoraUsuario",

        data(){
            return{
                corretoraUsuario: {
                    id: null,
                    corretora: null,
                    dataInicio: null,
                    dataTermino: null, 
                    usuario: this.$store.state.idUser,
                    exibeResumo: 'S',
                    index: null
                },
                corretorasUsuarios: [],
                corretoras: [],
                isShowModal: false,
                showInfo: false
            }
        },

        methods: {
            clearFields: function() {
                this.corretoraUsuario = { id: null,
                    corretora: null,
                    dataInicio: null,
                    dataTermino: null, 
                    usuario: this.$store.state.idUser,
                    exibeResumo: 'S',
                    index: null }
            },
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index) {
                this.corretoraUsuario.id = this.corretorasUsuarios[index].id
                this.corretoraUsuario.corretora = this.corretorasUsuarios[index].corretora                
                this.corretoraUsuario.dataInicio = this.corretorasUsuarios[index].dataInicio
                this.corretoraUsuario.dataTermino = this.corretorasUsuarios[index].dataTermino
                this.corretoraUsuario.exibeResumo = this.corretorasUsuarios[index].exibeResumo
                this.corretoraUsuario.index = index
                this.isShowModal = true              
            },

            erase: function(index) {
                if (window.confirm('Confirma exclusão?')) {
                    http.delete('/corretorasUsuarios/excluir/'+this.corretorasUsuarios[index].id).then(response =>{
                        this.corretorasUsuarios.splice(index, 1)
                        if (response.status == 204) {
                            alert('Registro excluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            save: function(){

                if (this.corretoraUsuario.corretora == null) {
                    alert('Corretora não selecionada!')
                    return
                }

                if (this.corretoraUsuario.dataInicio == null) {
                    alert('Data de início de operação não informada!')
                    return
                }

                if (this.corretoraUsuario.dataTermino != null && this.corretoraUsuario.dataTermino < this.corretoraUsuario.dataInicio) {
                    alert('Data de término não pode ser inferior a data de início de operação')
                    return
                }

                var dataTermino2

                if (this.corretoraUsuario.dataTermino == '' || this.corretoraUsuario.dataTermino == null) {
                    dataTermino2 = null 
                } else {
                    dataTermino2 = moment(this.corretoraUsuario.dataTermino).format('DD/MM/YYYY')
                }

                var data = {
                    id: this.corretoraUsuario.id,
                    corretora: this.corretoraUsuario.corretora.id,
                    dataInicio: moment(this.corretoraUsuario.dataInicio).format('DD/MM/YYYY'),
                    dataTermino: dataTermino2,
                    usuario: this.corretoraUsuario.usuario,
                    exibeResumo: this.corretoraUsuario.exibeResumo,
                }

                if (this.corretoraUsuario.id == null) {
                    http.post('/corretorasUsuarios/adicionar', data).then(response =>{
                        if (response.data.id != undefined && response.data.id != '') {
                            this.corretoraUsuario.id = response.data.id
                            this.corretorasUsuarios.push(this.corretoraUsuario)
                            this.showInfo = true
                            this.clearFields()
                        } else {
                            alert(response.request.responseText)
                        }
                    }).catch(e => {
                        console.log(e);
                    })

                } else {
                    http.put('/corretorasUsuarios/editar/'+this.corretoraUsuario.id, data).then(response =>{
                        this.corretorasUsuarios[this.corretoraUsuario.index].corretora = this.corretoraUsuario.corretora
                        this.corretorasUsuarios[this.corretoraUsuario.index].dataInicio = response.data.dataInicio
                        this.corretorasUsuarios[this.corretoraUsuario.index].dataTermino = response.data.dataTermino
                        this.corretorasUsuarios[this.corretoraUsuario.index].exibeResumo = response.data.exibeResumo
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },
            nextPage: function(){
            },
            previousPage: function(){
            }
        },

        mounted: function() {
            http.get('corretoras/').then(response =>{
                this.corretoras = response.data.results
            })

            http.get('corretorasUsuarios/listar').then(response =>{
                this.corretorasUsuarios = response.data

                if (this.corretorasUsuarios.length != 0) {
                    this.showInfo = true
                } else {
                    this.showInfo = false
                }

            })
        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>