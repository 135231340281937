import axios from 'axios'

export const http = axios.create({
    // baseURL: 'http://localhost:8000/api/',
    baseURL: 'https://pgpaf.com.br/api/',
    headers: {
        "Content-type": "application/json"
    }
})

