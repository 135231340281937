import { render, staticRenderFns } from "./OutrasInformacoesPeriodo.vue?vue&type=template&id=4cec7164&scoped=true"
import script from "./OutrasInformacoesPeriodo.vue?vue&type=script&lang=js"
export * from "./OutrasInformacoesPeriodo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cec7164",
  null
  
)

export default component.exports