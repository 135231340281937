<template>
    <div>
        <br>
        <h5 class="title is-5">Dashboard
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="processar()" :disabled="this.cmbPeriodoPerfil.estaFechado">
                Processar
            </button>  
        </h5>

        <form>
            <div class="field is-horizontal">
                <div class="field-body">

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbTipoPeriodo" @change="selectTipoPeriodo()">
                                    <option value="0" disabled>Selecione um tipo de período</option>
                                    <option v-for="td in tiposPeriodo" :key="td.id" :value="td.id">
                                        {{td.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPerfil" @change="selectPerfil()">
                                    <option value="0" disabled>Selecione um perfil</option>
                                    <option v-for="perfilUsuario in perfisUsuario" :key="perfilUsuario.id" :value="perfilUsuario.perfil.id">
                                        {{perfilUsuario.perfil.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbPeriodoPerfil" @change="selectPeriodoPerfil()">
                                    <option value="0" disabled>Selecione um período</option>
                                    <option v-for="periodoPerfil in periodosPerfis" :key="periodoPerfil.id" :value="periodoPerfil">
                                        {{periodoPerfil.periodo.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbGrupoIndicador">
                                    <option value="0">TODOS OS GRUPOS DE INDICADORES</option>
                                    <option v-for="gi in gruposIndicadores" :key="gi.id" :value="gi.grupo_indicador.id">
                                        {{gi.grupo_indicador.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>

            </div>
        </form>  

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card is-large">
                <header class="modal-card-head">
                <p class="modal-card-title">Evolução do {{indicadorSelecionado}}</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <ul id="example-2">
                        <li v-for="(gih, index) in gruposIndicadoresHistorico" :key="index">
                            <content class="panel is-link">
                                <div class="panel-block">
                                    <ul id="example-3">
                                        <li v-for="(gih2, index2) in gih[1]" :key="index2">

                                            <div class="columns">

                                                <div class="column">

                                                    <div class="card">
                                                        <div class="card-content is-center is-large">
                                                            <div class="content">
                                                                <apexchart ref="chartEvolucaoIndicador" height="300" width="540" type="line" :options="gih2[2]" :series="gih2[3]"></apexchart>
                                                            </div>
                                                        </div>
                                                        <footer class="card-footer">
                                                        </footer>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>    

                                </div>  
                                <br>
                            </content>
                        </li>
                    </ul>                            

                </section>
            </div>
        </div>        

        <hr>

        <div class="columns" v-if="showInfo">

            <div class="column">
            </div>
            <div class="column is-four-fifths">
                <ul id="example-2">
                    <li v-for="(gip, index) in gruposIndicadoresPeriodo" :key="index">
                        <content class="panel is-info">

                            <header class="panel-heading">
                                <strong class="has-text-white">{{index}}</strong>
                            </header>

                            <div class="panel-block">

                                <div class="table-container">     
                                    <br>
                                    <table class="table is-fullwidth is-striped is-centered">
                                        <thead>
                                            <tr>
                                                <th class="has-text-left is-size-6">Indicador</th>
                                                <th class="has-text-left is-size-6">Evolução</th>
                                                <th class="has-text-right is-size-6">{{anoMesReferencia|datePorTipo(cmbTipoPeriodo)}}</th>
                                                <th class="has-text-centered is-size-6">Status</th>
                                                <th class="has-text-right is-size-6">{{anoMesMesAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</th>
                                                <th class="has-text-right is-size-6" v-if="cmbTipoPeriodo != 6">{{anoMesAnoAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</th>
                                                <th class="has-text-right is-size-6">{{anoMes3AnosAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</th>
                                                <th class="has-text-right is-size-6">{{anoMes5AnosAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</th>
                                                <th class="has-text-right is-size-6">{{anoMes10AnosAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in gip" :key="item.id">
                                                <td class="is-size-6">{{item.indicador.descricao}}</td>
                                                <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" data-tooltip="Evolução" @click="callChart(item.indicador)"><span class="fas fa-chart-line"></span></button></small></td>
                                                <td class="has-text-right is-size-6">{{item.valor|numberFormat}}</td>

                                                <td class="has-text-centered is-size-6" v-if="item.valor_referencia_indicador == null"><small>Nenhum</small></td>
                                                <td class="has-text-centered is-size-6 has-background-danger-dark has-text-white-bis" v-if="item.valor_referencia_indicador != null && item.valor_referencia_indicador.status == '1'"><small>Péssimo</small></td>
                                                <td class="has-text-centered is-size-6 has-background-danger-light" v-if="item.valor_referencia_indicador != null && item.valor_referencia_indicador.status == '2'"><small>Ruim</small></td>
                                                <td class="has-text-centered is-size-6 has-background-warning-light" v-if="item.valor_referencia_indicador != null && item.valor_referencia_indicador.status == '3'"><small>Normal</small></td>
                                                <td class="has-text-centered is-size-6 has-background-success-light" v-if="item.valor_referencia_indicador != null && item.valor_referencia_indicador.status == '4'"><small>Bom</small></td>
                                                <td class="has-text-centered is-size-6 has-background-success-dark has-text-white-bis" v-if="item.valor_referencia_indicador != null && item.valor_referencia_indicador.status == '5'"><small>Excelente</small></td>


                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_mes_anterior=='1' && item.valor > item.valorMesAnterior"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_mes_anterior=='2' && item.valor < item.valorMesAnterior"><span class="fas fa-arrow-down"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-info has-text-weight-bold" v-if="item.cor_ah_mes_anterior=='3'"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_mes_anterior=='1' && item.valor == item.valorMesAnterior"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_mes_anterior=='2' && item.valor > item.valorMesAnterior"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_mes_anterior=='1' && item.valor < item.valorMesAnterior"><span class="fas fa-arrow-down"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_mes_anterior=='2' && item.valor == item.valorMesAnterior"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_ano_anterior=='1' && item.valor > item.valorAnoAnterior && cmbTipoPeriodo != 6"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_ano_anterior=='2' && item.valor < item.valorAnoAnterior && cmbTipoPeriodo != 6"><span class="fas fa-arrow-down"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-info has-text-weight-bold" v-if="item.cor_ah_ano_anterior=='3' && cmbTipoPeriodo != 6"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_ano_anterior=='1' && item.valor == item.valorAnoAnterior" && cmbTipoPeriodo != 6><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_ano_anterior=='2' && item.valor > item.valorAnoAnterior && cmbTipoPeriodo != 6"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_ano_anterior=='1' && item.valor < item.valorAnoAnterior && cmbTipoPeriodo != 6"><span class="fas fa-arrow-down"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_ano_anterior=='2' && item.valor == item.valorAnoAnterior && cmbTipoPeriodo != 6"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_3anos_anteriores=='1' && item.valor > item.valor3AnosAnteriores"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_3anos_anteriores=='2' && item.valor < item.valor3AnosAnteriores"><span class="fas fa-arrow-down"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-info has-text-weight-bold" v-if="item.cor_ah_3anos_anteriores=='3'"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_3anos_anteriores=='1' && item.valor == item.valor3AnosAnteriores"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_3anos_anteriores=='2' && item.valor > item.valor3AnosAnteriores"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_3anos_anteriores=='1' && item.valor < item.valor3AnosAnteriores"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_3anos_anteriores=='2' && item.valor == item.valor3AnosAnteriores"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_5anos_anteriores=='1' && item.valor > item.valor5AnosAnteriores"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_5anos_anteriores=='2' && item.valor < item.valor5AnosAnteriores"><span class="fas fa-arrow-down"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-info has-text-weight-bold" v-if="item.cor_ah_5anos_anteriores=='3'"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_5anos_anteriores=='1' && item.valor == item.valor5AnosAnteriores"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_5anos_anteriores=='2' && item.valor > item.valor5AnosAnteriores"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_5anos_anteriores=='1' && item.valor < item.valor5AnosAnteriores"><span class="fas fa-arrow-down"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_5anos_anteriores=='2' && item.valor == item.valor5AnosAnteriores"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_10anos_anteriores=='1' && item.valor > item.valor10AnosAnteriores"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_10anos_anteriores=='2' && item.valor < item.valor10AnosAnteriores"><span class="fas fa-arrow-down"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-info has-text-weight-bold" v-if="item.cor_ah_10anos_anteriores=='3'"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_10anos_anteriores=='1' && item.valor == item.valor10AnosAnteriores"><span class="fas fa-window-minimize"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_10anos_anteriores=='2' && item.valor > item.valor10AnosAnteriores"><span class="fas fa-arrow-up"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-success has-text-weight-bold" v-if="item.cor_ah_10anos_anteriores=='1' && item.valor < item.valor10AnosAnteriores"><span class="fas fa-arrow-down"></span></td>
                                                <td class="has-text-centered is-size-6 has-text-danger has-text-weight-bold" v-if="item.cor_ah_10anos_anteriores=='2' && item.valor == item.valor10AnosAnteriores"><span class="fas fa-window-minimize"></span></td>
                                            </tr>
                                        </tbody>    
                                    </table>
                                </div>
                            </div>  
                            <br>
                        </content>
                    </li>
                </ul>                         

                <br>
            </div>

            <div class="column">
            </div>
        </div>
    </div>        

</template>

<script>
    import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "IndicadorPeriodo",

        data(){
            return{
                periodosPerfis: [],
                perfisUsuario: [],
                gruposIndicadores: [],
                gruposIndicadoresPeriodo: [],
                gruposIndicadoresHistorico: [],
                indicadoresPeriodo: [],
                tiposPeriodo: [],
                cmbPerfil: 0,
                cmbPeriodoPerfil: 0,
                cmbGrupoIndicador: 0,
                cmbTipoPeriodo: 0,
                anoMesReferencia: "",
                anoMesMesAnteriorReferencia: "",
                anoMesAnoAnteriorReferencia: "",
                anoMes3AnosAnteriorReferencia: "",
                anoMes5AnosAnteriorReferencia: "",
                anoMes10AnosAnteriorReferencia: "",
                indicadorSelecionado: "",
                showInfo: false,
                isShowModal: false

            }
        },

        methods: {
            filterRecords: function(){
                if (this.cmbTipoPeriodo == 0) {
                    alert('Selecione um tipo de período')
                    return
                }
                if (this.cmbPerfil == 0) {
                    alert('Selecione um perfil')
                    return
                }
                if (this.cmbPeriodoPerfil == 0) {
                    alert('Selecione um período')
                    return
                }
                http.get('indicadorPeriodo/listar/', {params: {
                    idPeriodoPerfil: this.cmbPeriodoPerfil.id,
                    idGrupoIndicador: this.cmbGrupoIndicador
                }}).then(response => {
                    this.gruposIndicadoresPeriodo = _.groupBy(response.data.results, x => x.indicador.grupo_indicador.descricao)

                    if (this.gruposIndicadoresPeriodo.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })

            },

            selectTipoPeriodo: function() {
                this.cmbPerfil = 0
                this.cmbPeriodoPerfil = 0
                this.cmbGrupoIndicador = 0
                this.indicadoresPeriodo = []
                this.anoMesReferencia = ""
                this.anoMesMesAnteriorReferencia = ""
                this.anoMesAnoAnteriorReferencia = ""
                this.anoMes3AnosAnteriorReferencia = ""
                this.anoMes5AnosAnteriorReferencia = ""
                this.anoMes10AnosAnteriorReferencia = ""
            },

            selectPerfil: function() {
                http.get('periodoPerfil/listar/', {params: {
                    idPerfil: this.cmbPerfil,
                    idTipoPeriodo: this.cmbTipoPeriodo,
                    semPaginacao: 'S'
                }}).then(response => {
                    this.periodosPerfis = response.data.results
                    if (this.periodosPerfis.length == 0) {
                        alert('Não há períodos associados ao perfil selecionado!')
                    }
                })
            },

            selectPeriodoPerfil: function() {
                http.get('grupoIndicador/listarGruposIndicadores/', {params: {
                    idVersaoPlanoContas: this.cmbPeriodoPerfil.versao_plano_contas.id,
                }}).then(response => {
                    this.gruposIndicadores = response.data.results
                    this.anoMesReferencia = this.cmbPeriodoPerfil.periodo.data
                    this.anoMesMesAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_mes_anterior
                    this.anoMesAnoAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_ano_anterior
                    this.anoMes3AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_3anos_anteriores
                    this.anoMes5AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_5anos_anteriores
                    this.anoMes10AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_10anos_anteriores

                    if (this.gruposIndicadores.length == 0) {
                        alert('Não há grupos de indicadores associados a versão associada ao período selecionado!')
                    } else {
                        this.filterRecords()
                    }
                })

            },

            callChart: function(ind) {
                this.isShowModal = true    
                this.indicadorSelecionado = ind.descricao.substring(0, 40)

                http.get('indicadorPeriodo/historico/', {params: {
                    idPeriodoPerfil: this.cmbPeriodoPerfil.id,
                    idGrupoIndicador: this.cmbGrupoIndicador,
                    idIndicador: ind.id
                }}).then(response => {
                    var i
                    var j
                    var k
                    var auxGrupoIndicador
                    var auxIndicador
                    var auxPeriodoValor
                    var nIndGI
                    var nIndI
                    var seriesEvolucaoIndicadores
                    var options

                    auxGrupoIndicador = null
                    auxIndicador = null
                    auxPeriodoValor = []

                    this.gruposIndicadoresHistorico = []

                    for (i = 0; i < response.data.results.length; i++) {
                        if (auxGrupoIndicador == null || response.data.results[i].grupoIndicador != auxGrupoIndicador) {
                            auxGrupoIndicador = response.data.results[i].grupoIndicador
                            auxIndicador = null
                            auxPeriodoValor = []
                            this.gruposIndicadoresHistorico.push([auxGrupoIndicador, [] ])
                            nIndGI = (this.gruposIndicadoresHistorico.length-1)
                        } 

                        if (auxIndicador == null || response.data.results[i].kpi != auxIndicador ) {
                            auxIndicador = response.data.results[i].kpi
                            auxPeriodoValor = []

                            seriesEvolucaoIndicadores = [{
                                name: 'Indicador',
                                data: [] 
                            }]

                            options = {
                                chart: {
                                    id: 'accounting-evolucao'
                                },
                                xaxis: {
                                    categories: [],
                                    
                                    labels: {
                                        style: {
                                            fontSize: '10px',
                                        },
                                        show: true,
                                    },
                                },
                                dataLabels: {
                                    enabled: false,
                                },    

                                plotOptions: {
                                    bar: {
                                        horizontal: false,
                                        columnWidth: '55%',
                                        endingShape: 'rounded'
                                    },
                                },

                                markers: {
                                    size: 4,
                                } ,

                            }   

                            this.gruposIndicadoresHistorico[nIndGI][1].push([auxIndicador, [], options, seriesEvolucaoIndicadores, response.data.results[i].comentarioKPI])
                            nIndI = (this.gruposIndicadoresHistorico[nIndGI][1].length-1)
                        }

                        this.gruposIndicadoresHistorico[nIndGI][1][nIndI][1].push([response.data.results[i].periodo, parseFloat(response.data.results[i].valorKPI).toFixed(2)])
                        
                    }

                    if (auxPeriodoValor.length != 0) {
                        this.gruposIndicadoresHistorico.push([auxGrupoIndicador, [auxIndicador, auxPeriodoValor] ])
                    }

                    for (i = 0; i < this.gruposIndicadoresHistorico.length; i++) {

                        for (j = 0; j < this.gruposIndicadoresHistorico[i][1].length; j++) {

                            for (k = 0; k < this.gruposIndicadoresHistorico[i][1][j][1].length; k++) {
                                this.gruposIndicadoresHistorico[i][1][j][2].xaxis.categories.push( this.gruposIndicadoresHistorico[i][1][j][1][k][0] )
                                this.gruposIndicadoresHistorico[i][1][j][3][0].data.push( this.gruposIndicadoresHistorico[i][1][j][1][k][1])
                            }
                        }
                    }

                    for (i = 0; i < this.$refs.length; i++) {
                        this.$refs.chartEvolucaoIndicador[i].refresh()
                    }

                })
                

            },

            processar: function() {
                if (this.cmbPerfil == 0) {
                    alert('Perfil não selecionado!')
                    return
                }

                if (this.cmbPeriodoPerfil == 0) {
                    alert('Período não selecionado!')
                    return
                }

                if (window.confirm('Confirma o processamento dos indicadores no período informado?')) {
                    http.put('/indicadorPeriodo/processar/'+this.cmbPeriodoPerfil.id, this.cmbGrupoIndicador).then(response =>{
                        if (response.status == 204) {
                            this.filterRecords()
                            alert('Processamento concluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            }

        },

        mounted: function() {
        },
        
        created: function() {
            http.get('compartilharPerfis/listarPerfis').then(response => {
                    this.perfisUsuario = response.data.results
            })

            http.get('tiposPeriodo/listar').then(response => {
                this.tiposPeriodo = response.data.results
            })

        }

    }
</script>

<style scoped>
</style>