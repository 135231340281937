<template>
    <div>
        <br>
        <h5 class="title is-5">Empresas
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
                Cadastrar
            </button>  
        </h5>
        <form>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="criteria">
                                    <option value="0">TODAS</option>
                                    <option value="1">Empresa</option>
                                    <option value="2">Setor</option>
                                    <option value="3">Subsetor</option>
                                    <option value="4">Segmento do subsetor</option>
                                    <option value="5">Segmento de mercado</option>
                                </select>
                            </div>    
                        </div>
                        
                    </div>  
                    <div class="field">                    
                        <input type="text" class="input" placeholder="Informe o argumento de pesquisa" name="txtArgument" v-model="argument" :disabled="this.criteria == '0'">
                    </div>    
                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>
            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cadastrar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Código</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <input class="input" type="text" placeholder="Informe o código" v-model="empresa.codigo">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <input class="input" type="text" placeholder="Informe a descrição" v-model="empresa.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Segmento de Mercado</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <input class="input" type="text" placeholder="Informe o segmento de mercado" v-model="empresa.segmentoMercado">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Código no País</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <input class="input" type="text" placeholder="Informe o código da empresa no país" v-model="empresa.codigoNoPais">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Código na CVM</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <input class="input" type="text" placeholder="Informe o código na CVM" v-model="empresa.codigoCVM">
                                </div>
                            </div>
                        </div>


                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Segmento</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="empresa.segmento">
                                            <option v-for="segmento in segmentos" :key="segmento.id" :value="segmento">
                                                {{segmento.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div v-show="this.showInfo">
            <div class="table-container">                
                <table class="table is-narrow is-hoverable is-fullwidth is-small is-striped">
                    <thead>
                        <tr>
                            <th><small>Código</small></th>
                            <th><small>Descrição</small></th>
                            <th><small>Segmento de Mercado</small></th>
                            <th><small>Setor</small></th>
                            <th><small>Subsetor</small></th>
                            <th><small>Segmento</small></th>
                            <th colspan="2" class="has-text-center"><small>Ações</small></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(empresa, index) in empresas" :key="empresa.id">
                            <td><small>{{empresa.codigo}}</small></td>
                            <td><small>{{empresa.descricao}}</small></td>
                            <td><small>{{empresa.segmentoMercado}}</small></td>
                            <td><small>{{empresa.segmento.subsetor.setor.descricao}}</small></td>
                            <td><small>{{empresa.segmento.subsetor.descricao}}</small></td>
                            <td><small>{{empresa.segmento.descricao}}</small></td>
                            <td class="has-text-center"><small><a @click="edit(index)"><span class="fas fa-edit"></span></a></small></td>
                            <td class="has-text-center"><small><a @click="erase(index)"><span class="fas fa-trash-alt"></span></a></small></td>
                        </tr>
                    </tbody>    

                </table>
            </div>    

            <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.page == 1 || this.page == null">Anterior</a>
                <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.page == this.numpages">Próxima</a>                
            </nav>
            <br>

        </div>    

    </div>        

</template>

<script>
    import {http} from '../../services/config'

    export default {
        name: "Company",

        data(){
            return{ 
                empresa: {
                    id: null,
                    codigo: null,
                    descricao: null,
                    segmentoMercado: null,
                    codigoNoPais: null,
                    codigoCVM: null,
                    segmento: null,
                    index: null
                },
                empresas: [],
                previous: null,
                next: null,
                argument: null,
                page: null,
                numpages: null,
                criteria: "0",
                segmentos: [],
                isShowModal: false,
                showInfo: false
            }
        },

        methods: {
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                this.empresa.id = this.empresas[index].id
                this.empresa.codigo = this.empresas[index].codigo
                this.empresa.descricao = this.empresas[index].descricao
                this.empresa.segmentoMercado = this.empresas[index].segmentoMercado
                this.empresa.codigoNoPais = this.empresas[index].codigoNoPais
                this.empresa.codigoCVM = this.empresas[index].codigoCVM
                this.empresa.segmento = this.empresas[index].segmento
                this.empresa.index = index
                this.isShowModal = true              
            },

            clearFields: function() {
                this.empresa = { id: null,
                    descricao: null,
                    codigo: null,
                    segmentoMercado: null,
                    codigoNoPais: null,
                    codigoCVM: null,
                    segmento: null,
                    index: null }
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    http.delete('/empresas/excluir/'+this.empresas[index].id).then(response =>{
                        this.empresas.splice(index, 1)
                        if (response.status == 204) {
                            alert('Registro excluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            save: function(){
                if (this.empresa.segmento == null) {
                    alert('Segmento não informado!')
                    return
                }

                if (this.empresa.codigo == null) {
                    alert('Código não informado!')
                    return
                }

                if (this.empresa.descricao == null) {
                    alert('Descrição não informada!')
                    return
                }

                var data = {id: this.empresa.id,
                    codigo: this.empresa.codigo,
                    descricao: this.empresa.descricao,
                    segmentoMercado: this.empresa.segmentoMercado,
                    codigoNoPais: this.empresa.codigoNoPais,
                    codigoCVM: this.empresa.codigoCVM,
                    segmento: this.empresa.segmento.id
                }

                if (this.empresa.segmentoMercado == "") {
                    data.segmentoMercado = null
                }

                if (this.empresa.codigoNoPais == "") {
                    data.codigoNoPais = null
                }

                if (this.empresa.codigoCVM == "") {
                    data.codigoCVM = null
                }

                if (this.empresa.id == null) {
                    http.post('/empresas/adicionar', data).then(response =>{
                        if (response.data.id != undefined && response.data.id != '') {
                            this.empresa.id = response.data.id
                            this.empresas.push(this.empresa)
                            this.showInfo = true
                            this.clearFields()
                        } else {
                            alert(response.request.responseText)
                        }
                    }).catch(e => {
                        console.log(e);
                    })

                } else {
                    http.put('/empresas/editar/'+this.empresa.id, data).then(response =>{
                        this.empresas[this.empresa.index].codigo = response.data.codigo
                        this.empresas[this.empresa.index].descricao = response.data.descricao
                        this.empresas[this.empresa.index].segmentoMercado = response.data.segmentoMercado
                        this.empresas[this.empresa.index].codigoNoPais = response.data.codigoNoPais
                        this.empresas[this.empresa.index].codigoCVM = response.data.codigoCVM
                        this.empresas[this.empresa.index].segmento = this.empresa.segmento
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            nextPage: function(){
                if (this.page != this.numpages) {
                    http.get(this.next, {params: {
                        criteria: this.criteria,
                        argument: this.argument
                    }}).then(response =>{
                        this.empresas = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },
            previousPage: function(){
                if  (this.page != 1 ) {
                    http.get(this.previous, {params: {
                        criteria: this.criteria,
                        argument: this.argument
                    }}).then(response =>{
                        this.empresas = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },
            filterRecords: function(){
                if (this.criteria == "0") {
                    this.argument = null
                }

                http.get('empresas/listar', {params: {
                    criteria: this.criteria,
                    argument: this.argument
                }}).then(response =>{
                    this.empresas = response.data.results
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages
                    if (this.empresas.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })

            }
        },

        mounted: function() {
            http.get('segmentos/listar').then(response =>{
                this.segmentos = response.data.results
            })
        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>