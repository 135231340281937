<template>
    <div>
        <br>
        <h5 class="title is-5">Benchmarks
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
                Cadastrar
            </button>  
        </h5>
        <form>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbBenchmark">
                                    <option value="0">TODOS OS BENCHMARKS</option>
                                    <option v-for="benchmark in benchmarks" :key="benchmark.id" :value="benchmark.id">
                                        {{benchmark.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  
                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>
            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cadastrar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Benchmark</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="benchmarkValor.benchmark">
                                            <option v-for="benchmark in benchmarks" :key="benchmark.id" :value="benchmark">
                                                {{benchmark.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Competência</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="date" placeholder="Informe a data inicial" v-model="benchmarkValor.competencia">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Valor</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="number" placeholder="Informe o valor" v-model="benchmarkValor.valor">
                                </div>
                            </div>
                        </div>

                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        


        <div class="columns" v-show="this.showInfo">
            <div class="column"></div>
            <div class="column is-three-fifths">
                <div class="table-container">                
                    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                                <th><small>Benchmark</small></th>
                                <th><small>Período</small></th>
                                <th class="has-text-right"><small>Valor</small></th>
                                <th colspan="3" class="has-text-center"><small>Ações</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(benchmarkvalue, index) in benchMarksValues" :key="benchmarkvalue.id">
                                <td><small>{{benchmarkvalue.benchmark.descricao}}</small></td>
                                <td><small>{{benchmarkvalue.competencia|dateMMYYYY}}</small></td>
                                <td class="has-text-right"><small>{{benchmarkvalue.valor|numberFormat}}</small></td>
                                <td class="has-text-center"><small><a @click="edit(index)"><span class="fas fa-edit"></span></a></small></td>
                                <td class="has-text-center"><small><a @click="erase(index)"><span class="fas fa-trash-alt"></span></a></small></td>
                            </tr>
                        </tbody>    

                    </table>
                </div>    

                <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                    <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.page == 1 || this.page == null">Anterior</a>
                    <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.page == this.numpages">Próxima</a>                
                </nav>
                <br>
            </div>
            <div class="column"></div>

        </div>    

    </div>        

</template>

<script>
    import _ from 'lodash'
    import {http} from '../../services/config'
    import moment from 'moment'

    export default {
        name: "Benchmark",

        data(){
            return{
                benchmarkValor: {
                    id: null,
                    competencia: null,
                    benchmark: null,
                    valor: null,
                    index: null
                },
                benchmarks: [],
                benchMarksValues: [],
                cmbBenchmark: null,
                previous: null,
                next: null,
                page: null,
                numpages: null,
                isShowModal: false,
                showInfo: false
            }
        },

        methods: {
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                this.benchmarkValor.id = this.benchMarksValues[index].id
                this.benchmarkValor.competencia = this.benchMarksValues[index].competencia
                this.benchmarkValor.benchmark = this.benchMarksValues[index].benchmark
                this.benchmarkValor.valor = this.benchMarksValues[index].valor
                this.benchmarkValor.index = index
                this.isShowModal = true              
            },

            clearFields: function() {
                this.benchmarkValor = { id: null,
                    competencia: null,
                    benchmark: null,
                    valor: null,
                    index: null }
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    http.delete('/benchmarkValor/excluir/'+this.benchMarksValues[index].id).then(response =>{
                        this.benchMarksValues.splice(index, 1)
                        if (response.status == 204) {
                            alert('Registro excluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            save: function(){
                if (this.benchmarkValor.competencia == null) {
                    alert('Competência não informada!')
                    return
                }

                if (this.benchmarkValor.benchmark == null) {
                    alert('Benchmark não informado!')
                    return
                }

                if (this.benchmarkValor.valor == null) {
                    alert('Valor não informado!')
                    return
                }

                var data = {id: this.benchmarkValor.id,
                    competencia: moment(this.benchmarkValor.competencia).format('DD/MM/YYYY'),
                    benchmark: this.benchmarkValor.benchmark.id,
                    valor: this.benchmarkValor.valor
                }

                if (this.benchmarkValor.id == null) {
                    http.post('/benchmarkValor/adicionar', data).then(response =>{
                        if (response.data.id != undefined && response.data.id != '') {
                            this.benchmarkValor.id = response.data.id
                            this.benchMarksValues.push(this.benchmarkValor)
                            this.showInfo = true
                            this.clearFields()
                        } else {
                            alert(response.request.responseText)
                        }
                    }).catch(e => {
                        console.log(e);
                    })

                } else {
                    http.put('/benchmarkValor/editar/'+this.benchmarkValor.id, data).then(response =>{
                        this.benchMarksValues[this.benchmarkValor.index].competencia = response.data.competencia
                        this.benchMarksValues[this.benchmarkValor.index].benchmark = response.data.benchmark
                        this.benchMarksValues[this.benchmarkValor.index].valor = response.data.valor
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },
            nextPage: function(){
                if (this.page != this.numpages) {
                    http.get(this.next, {params: {
                        cmbBenchmark: this.cmbBenchmark
                    }}).then(response =>{
                        this.benchMarksValues = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },
            previousPage: function(){
                if (this.page != 1 ) {
                    http.get(this.previous, {params: {
                        cmbBenchmark: this.cmbBenchmark
                    }}).then(response =>{
                        this.benchMarksValues = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },
            filterRecords: function(){
                http.get('benchmarkValor/listar/', {params: {
                        cmbBenchmark: this.cmbBenchmark
                }}).then(response =>{
                    this.benchMarksValues = response.data.results
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages
                    if (this.benchMarksValues.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })
            }
        },

        mounted: function() {
                http.get('benchmarks/').then(response =>{
                    this.benchmarks = _.orderBy(response.data.results, ['descricao'], ['asc'])
                    this.cmbBenchmark = "0"
                }).catch(function(){
                    alert("Você não tem permissão para acessar essas informações")
                })
        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>