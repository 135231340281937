<template>
    <div>
        <br>
        <h5 class="title is-5">Dashboard
        </h5>
        <form>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbModelo">
                                    <option value="0" disabled>Selecione um modelo</option>
                                    <option v-for="modeloCarteita in modelosCarteiras" :key="modeloCarteita.id" :value="modeloCarteita">
                                        {{modeloCarteita.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPeriodo">
                                    <option value="0" disabled>Selecione um período</option>
                                    <option v-for="periodo in periodos" :key="periodo.id" :value="periodo">
                                        {{periodo.mesAno}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select v-model="cmbAssetClass">
                                        <option value="0">TODAS AS CLASSES DE ATIVOS</option>
                                        <option v-for="assetclass in assetsclasses" :key="assetclass.id" :value="assetclass.id">
                                            {{assetclass.descricao}}
                                        </option>
                                    </select>
                                </div>    
                            </div>
                        </div>  
                    </div>
    
                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>
            </div>
        </form>  

        <hr>

        <div class="columns" v-show="this.showInfo">

            <div class="column">
                <div class="card">
                    <header class="card-header has-background-grey">
                        <p class="card-header-title has-text-white-bis">
                            Evolução Patrimonial
                        </p>
                    </header>
                    <div class="card-content is-center">
                        <div class="content">
                            <apexchart ref="chartEvolucaoPatrimonial" height="400"  type="bar" :options="options" :series="seriesPatrimonio"></apexchart>
                        </div>
                    </div>
                    <footer class="card-footer">
                    </footer>
                </div>
            </div>

            <div class="column">
                <div class="card">
                    <header class="card-header has-background-grey">
                        <p class="card-header-title has-text-white-bis">
                            Evolução da Rentabilidade
                        </p>
                    </header>
                    <div class="card-content is-center">
                        <div class="content">
                            <apexchart ref="chartEvolucaoRentabilidade" height="400"  type="line" :options="options" :series="seriesRentabilidade"></apexchart>
                        </div>
                    </div>
                    <footer class="card-footer">
                    </footer>
                </div>
            </div>

        </div>

        <div class="columns" v-show="this.showInfo">

            <div class="column">

                <div class="card">
                    <header class="card-header has-background-grey">
                        <p class="card-header-title has-text-white-bis">
                            Evolução da Renda
                        </p>
                    </header>
                    <div class="card-content is-center">
                        <div class="content">
                            <apexchart ref="chartEvolucaoRenda" height="400"  type="bar" :options="options" :series="seriesRenda"></apexchart>
                        </div>
                    </div>
                    <footer class="card-footer">
                    </footer>
                </div>

            </div>

            <!-- <div class="column">

                <div class="card">
                    <header class="card-header has-background-grey">
                        <p class="card-header-title has-text-white-bis">
                            Quantidade de Ativos
                        </p>
                    </header>
                    <div class="card-content is-center">
                        <div class="content">
                            <apexchart ref="chartEvolucaoQuantidade" height="400" width="600" type="bar" :options="options" :series="seriesQuantidade"></apexchart>
                        </div>
                    </div>
                    <footer class="card-footer">
                    </footer>
                </div>

            </div> -->

        </div>

        <div class="field is-horizontal" v-show="this.showInfo">
            <div class="card is-one-third">
                <header class="card-header has-background-grey">
                    <p class="card-header-title has-text-white-bis">
                        Patrimônio por Empresa
                    </p>
                </header>
                <div class="card-content">
                    <div class="content">
                        <apexchart ref="chartPatrimonioPorEmpresa" type="donut" width="450" :options="chartAlocacaoPorEmpresaOptions" :series="seriesPatrimonioPorEmpresa"></apexchart>
                    </div>
                </div>
                <footer class="card-footer">
                </footer>
            </div>

            <div class="card is-one-third">
                <header class="card-header has-background-grey">
                    <p class="card-header-title has-text-white-bis">
                        Patrimônio por Setor
                    </p>
                </header>
                <div class="card-content">
                    <div class="content">
                        <apexchart ref="chartPatrimonioPorSetor" type="donut" width="450" :options="chartAlocacaoPorSetorOptions" :series="seriesPatrimonioPorSetor"></apexchart>
                    </div>
                </div>
                <footer class="card-footer">
                </footer>
            </div>

            <div class="card is-one-third">
                <header class="card-header has-background-grey">
                    <p class="card-header-title has-text-white-bis">
                        Patrimônio por Classe de Ativo
                    </p>
                </header>
                <div class="card-content">
                    <div class="content">
                        <apexchart ref="chartPatrimonioPorClasseAtivo" type="donut" width="450" :options="chartAlocacaoPorClasseAtivoOptions" :series="seriesPatrimonioPorClasseAtivo"></apexchart>
                    </div>
                </div>
                <footer class="card-footer">
                </footer>
            </div>
        </div>    

        <div class="field is-horizontal" v-show="false">
            <div class="card is-one-third">
                <header class="card-header has-background-grey">
                    <p class="card-header-title has-text-white-bis">
                        Aplicação por Empresa
                    </p>
                </header>
                <div class="card-content">
                    <div class="content">
                        <apexchart ref="chartAplicacaoPorEmpresa" type="donut" width="400" :options="chartAlocacaoPorEmpresaOptions" :series="seriesAplicacaoPorEmpresa"></apexchart>
                    </div>
                </div>
                <footer class="card-footer">
                </footer>
            </div>

            <div class="card is-one-third">
                <header class="card-header has-background-grey">
                    <p class="card-header-title has-text-white-bis has-text-white-bis">
                        Aplicação por Setor
                    </p>
                </header>
                <div class="card-content">
                    <div class="content">
                        <apexchart ref="chartAplicacaoPorSetor" type="donut" width="400" :options="chartAlocacaoPorSetorOptions" :series="seriesAplicacaoPorSetor"></apexchart>
                    </div>
                </div>
                <footer class="card-footer">
                </footer>
            </div>

            <div class="card is-one-third">
                <header class="card-header has-background-grey">
                    <p class="card-header-title has-text-white-bis">
                        Aplicação por Classe de Ativo
                    </p>
                </header>
                <div class="card-content">
                    <div class="content">
                        <apexchart ref="chartAplicacaoPorClasseAtivo" type="donut" width="400" :options="chartAlocacaoPorClasseAtivoOptions" :series="seriesAplicacaoPorClasseAtivo"></apexchart>
                    </div>
                </div>
                <footer class="card-footer">
                </footer>
            </div>
        </div>    

    </div>        

</template>

<script>
    import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "Dashboard",

        data(){
            return{
                periodos: [],
                modelosCarteiras: [],

                assetsclasses: [],
                cmbAssetClass: null,

                alocacoesPorEmpresa: [],
                alocacoesPorSetor: [],
                alocacoesPorClasseAtivo: [],
                evolucaoPatrimonial: [],

                cmbPeriodo: "0",
                cmbModelo: "0",

                showInfo: false,

                options: {
                    chart: {
                        id: 'investmethod-evolucaoPatrimonial'
                    },
                    xaxis: {
                        categories: [],
                        
                        labels: {
                            style: {
                                fontSize: '10px',
                            },
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },    

                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },

                    markers: {
                        size: 4,
                    } ,

                },
                seriesPatrimonio: [{
                    name: 'Patrimônio',
                    data: [] 
                }],

                seriesRenda: [{
                    name: 'Renda',
                    data: [] 
                }],

                seriesQuantidade: [{
                    name: 'Quantidade',
                    data: [] 
                }],

                seriesRentabilidade: [{
                    name: 'Rentabilidade',
                    data: []
                }],

                seriesPatrimonioPorEmpresa: [], 
                seriesAplicacaoPorEmpresa: [], 
                chartAlocacaoPorEmpresaOptions: {
                    chart: {
                        width: "100%",
                        type: 'donut',
                    },

                    labels: [], 

                    legend: {
                        show: false
                    },

                },   

                seriesPatrimonioPorSetor: [], 
                seriesAplicacaoPorSetor: [], 
                chartAlocacaoPorSetorOptions: {
                    chart: {
                        width: "100%",
                        type: 'donut',
                    },

                    labels: [], 

                    legend: {
                        show: false
                    },

                },

                seriesPatrimonioPorClasseAtivo: [], 
                seriesAplicacaoPorClasseAtivo: [], 
                chartAlocacaoPorClasseAtivoOptions: {
                    chart: {
                        width: "100%",
                        type: 'donut',
                    },

                    labels: [], 

                    legend: {
                        show: false
                    },

                }   

            }
        },

        methods: {
            callListAPI: function() {
                var i
                var arrayLabels
                var arraySeries
                var arraySeriesAuxiliar
                var arraySeriesRentabilidade
                var arraySeriesQuantidade

                http.get('dashboards/evolucaoPatrimonio/', {params: {
                    idPeriodo: this.cmbPeriodo.id,
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    arrayLabels = []
                    arraySeries = []
                    arraySeriesAuxiliar = []
                    arraySeriesQuantidade = []
                    arraySeriesRentabilidade = []
                    this.evolucaoPatrimonial = response.data.results

                    if (this.evolucaoPatrimonial.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }

                    for (i = 0; i < this.evolucaoPatrimonial.length; i++) {
                        arraySeries.push( parseFloat(this.evolucaoPatrimonial[i].patrimonio).toFixed(2) )
                        arraySeriesAuxiliar.push( parseFloat(this.evolucaoPatrimonial[i].renda).toFixed(2) )
                        arraySeriesQuantidade.push( parseFloat(this.evolucaoPatrimonial[i].quantidade).toFixed(2) )
                        arraySeriesRentabilidade.push( parseFloat(this.evolucaoPatrimonial[i].rentabilidade).toFixed(2) )
                        arrayLabels.push(this.evolucaoPatrimonial[i].periodo_mesAno)
                    }

                    this.options.xaxis.categories = arrayLabels

                    this.seriesPatrimonio[0].data = arraySeries
                    this.seriesRenda[0].data = arraySeriesAuxiliar
                    this.seriesQuantidade[0].data = arraySeriesQuantidade
                    this.seriesRentabilidade[0].data = arraySeriesRentabilidade

                    this.$refs.chartEvolucaoPatrimonial.refresh()
                    this.$refs.chartEvolucaoRenda.refresh()
                    this.$refs.chartEvolucaoRentabilidade.refresh()
                    this.$refs.chartEvolucaoQuantidade.refresh()

                })
                
                http.get('dashboards/alocacaoPorEmpresa/', {params: {
                    idPeriodo: this.cmbPeriodo.id,
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    arrayLabels = []
                    arraySeries = []
                    arraySeriesAuxiliar = []
                    this.alocacoesPorEmpresa = response.data.results
                    for (i = 0; i < this.alocacoesPorEmpresa.length; i++) {
                        arraySeries.push(Math.round(this.alocacoesPorEmpresa[i].aplicacao, 2 ))
                        arraySeriesAuxiliar.push(Math.round(this.alocacoesPorEmpresa[i].patrimonio, 2 ))
                        arrayLabels.push(this.alocacoesPorEmpresa[i].empresa_descricao)
                    }
                    this.chartAlocacaoPorEmpresaOptions.labels = arrayLabels
                    this.seriesAplicacaoPorEmpresa = arraySeries
                    this.seriesPatrimonioPorEmpresa = arraySeriesAuxiliar
                    this.$refs.chartAplicacaoPorEmpresa.refresh()
                    this.$refs.chartPatrimonioPorEmpresa.refresh()
                })

                http.get('dashboards/alocacaoPorSetor/', {params: {
                    idPeriodo: this.cmbPeriodo.id,
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    this.alocacoesPorSetor = response.data.results
                    arrayLabels = []
                    arraySeries = []
                    arraySeriesAuxiliar = []
                    for (i = 0; i < this.alocacoesPorSetor.length; i++) {
                        arraySeries.push(Math.round(this.alocacoesPorSetor[i].aplicacao, 2 ))
                        arraySeriesAuxiliar.push(Math.round(this.alocacoesPorSetor[i].patrimonio, 2 ))
                        arrayLabels.push(this.alocacoesPorSetor[i].setor_descricao)
                    }
                    this.chartAlocacaoPorSetorOptions.labels = arrayLabels
                    this.seriesAplicacaoPorSetor = arraySeries
                    this.seriesPatrimonioPorSetor = arraySeriesAuxiliar
                    this.$refs.chartAplicacaoPorSetor.refresh()
                    this.$refs.chartPatrimonioPorSetor.refresh()

                })

                http.get('dashboards/alocacaoPorClasseAtivo/', {params: {
                    idPeriodo: this.cmbPeriodo.id,
                    idClasseAtivo: this.cmbAssetClass,
                    idModeloCarteira: this.cmbModelo.id
                }}).then(response =>{
                    this.alocacoesPorClasseAtivo = response.data.results
                    arrayLabels = []
                    arraySeries = []
                    arraySeriesAuxiliar = []
                    for (i = 0; i < this.alocacoesPorClasseAtivo.length; i++) {
                        arraySeries.push(Math.round(this.alocacoesPorClasseAtivo[i].aplicacao, 2 ))
                        arraySeriesAuxiliar.push(Math.round(this.alocacoesPorClasseAtivo[i].patrimonio, 2 ))
                        arrayLabels.push(this.alocacoesPorClasseAtivo[i].classeAtivo_descricao)
                    }
                    this.chartAlocacaoPorClasseAtivoOptions.labels = arrayLabels
                    this.seriesAplicacaoPorClasseAtivo = arraySeries
                    this.seriesPatrimonioPorClasseAtivo = arraySeriesAuxiliar
                    this.$refs.chartAplicacaoPorClasseAtivo.refresh()
                    this.$refs.chartPatrimonioPorClasseAtivo.refresh()
                })

            },

            clearFields: function() {
            },

            add: function() {
            },

            save: function(){
            },

            nextPage: function(){
            },
            previousPage: function(){
            },
            filterRecords: function(){
                if (this.cmbModelo == null || this.cmbModelo == "0" ) {
                    alert("Modelo não selecionado!")
                    return
                }
                if (this.cmbPeriodo == null || this.cmbPeriodo == "0") {
                    alert("Período não selecionado!")
                    return
                }

                this.callListAPI()
            }
        },

        mounted: function() {
            http.get('periodos/listar').then(response =>{
                this.periodos = response.data
            })

            http.get('classesativos/').then(response =>{
                this.assetsclasses = _.orderBy(response.data.results, ['descricao'], ['asc'])
                this.cmbAssetClass = "0"
            })

            http.get('modelagemCarteira/modeloCarteiraListar').then(response =>{
                this.modelosCarteiras = response.data
            })

        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>