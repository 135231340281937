<template>
    <div>
        <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
            <strong>Cadastrar</strong>
        </button>  
        <br>
        <br>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cadastrar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form  v-if="!this.mostraCompartilhamento && !this.mostraEmails && !this.mostraImages">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <input class="input" type="text" placeholder="Informe a descrição do perfil" v-model="perfil.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                               <label class="label"><small>Estado</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="select is-fullwidth">
                                    <select v-model="perfil.estado" @change="selectEstado()">
                                        <option value="0" disabled>Selecione um estado</option>
                                        <option v-for="uf in estados" :key="uf.sigla" :value="uf.sigla">
                                            {{uf.sigla}}
                                        </option>
                                    </select>
                                </div>
                            </div>    
                        </div>  

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                               <label class="label"><small>Cidade</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="select is-fullwidth">
                                    <select v-model="perfil.cidade">
                                        <option value="0" disabled>Selecione uma cidade</option>
                                        <option v-for="cidade in cidades" :key="cidade.id" :value="cidade">
                                            {{cidade.nome}}
                                        </option>
                                    </select>
                                </div>
                            </div>    
                        </div> 
                        
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Versão</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="perfil.versao_plano_contas">
                                            <option v-for="versao_plano_contas in versoesPlanosContas" :key="versao_plano_contas.id" :value="versao_plano_contas">
                                                {{versao_plano_contas.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </form>  

                    <form  v-if="this.mostraCompartilhamento">
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Usuário</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="usuarioPerfil.usuario">
                                            <option v-for="usuario in usuarios" :key="usuario.id" :value="usuario">
                                                {{usuario.username}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </form>  

                    <form  v-if="this.mostraEmails">
                        <div class="field is-horizontal">
                            <div class="field-label is-fullwidth">
                                <label class="label"><small>Nome</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <input class="input" type="text" placeholder="Informe o nome" v-model="perfilEmail.nome">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-fullwidth">
                                <label class="label"><small>Email</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-fullwidth">
                                    <input class="input" type="email" placeholder="Informe o email" v-model="perfilEmail.email">
                                </div>
                            </div>
                        </div>
                    </form>  

                    <form  v-if="this.mostraImages">
                        <div class="field is-horizontal">
                            <div class="field-label">
                                <label class="label"><small>Destino</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-normal">
                                    <select class="select is-fullwidth" v-model="perfilImagem.tipo_destino_imagem">
                                        <option value="1">Cabeçalho</option>
                                        <option value="2">Rodapé</option>
                                        <option value="3">Logomarca</option>
                                        <option value="4">Capa do Relatório</option>
                                    </select>
                                </div>
                            </div>    
                        </div> 
                        
                        <div class="file has-name is-fullwidth">
                            <label class="file-label">
                                <input class="file-input" type="file" ref="file" @change="uploadImageFile">
                                <span class="file-cta">
                                    <span class="file-icon">
                                        <i class="fas fa-upload"></i>
                                    </span>
                                    <span class="file-label">
                                        Selecione a imagem 
                                    </span>
                                </span>
                                <span class="file-name">
                                    {{ arquivoImagem }}
                                </span>
                            </label>
                        </div>
                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns" v-show="this.showInfo">
            <div class="column is-half">
                <div class="card">
                    <header class="panel-heading has-background-link-dark has-text-light">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Perfis</small>
                                </p>
                            </div>    
                        </nav>    
                    </header>
                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th><small>Descrição</small></th>
                                        <th colspan="5" class="has-text-centered"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(perfil, index) in perfis" :key="perfil.id">
                                        <td><small>{{perfil.descricao}}</small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Editar perfil" @click="edit(index)" :disabled="mostraCompartilhamento || mostraEmails || mostraImages"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Excluir perfil" @click="erase(index)" :disabled="mostraCompartilhamento || mostraEmails || mostraImages"><span class="fas fa-trash-alt"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Compartilhar perfil" @click="callCompartilhamento(perfil)" :disabled="mostraCompartilhamento || mostraEmails || mostraImages"><span class="fas fa-share-alt"></span></button></td>
                                        <td class="has-text-centered" style="width:15px"><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Imagens do perfil" @click="callImages(perfil)" :disabled="mostraCompartilhamento || mostraEmails || mostraImages"><span class="fas fa-image"></span></button></td>
                                        <td class="has-text-centered" style="width:15px"><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="E-mails do perfil" @click="callEmails(perfil)" :disabled="mostraCompartilhamento || mostraEmails || mostraImages"><span class="fas fa-at"></span></button></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>   
                    </div>
                </div>    
            </div>

            <div class="column" v-show="this.mostraCompartilhamento">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Compartilhamento do Perfil {{ this.perfilSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraCompartilhamento = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidthtable is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Usuários</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(usuarioPerfil, index) in usuariosPerfil" :key="usuarioPerfil.id">
                                        <td class="has-text-left"><small>{{usuarioPerfil.usuario.username}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>
            </div>

            <div class="column" v-show="this.mostraEmails">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Emails do Perfil {{ this.perfilSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraEmails = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidthtable is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Nome</small></th>
                                        <th class="has-text-left"><small>Email</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(pe, index) in perfilEmails" :key="pe.id">
                                        <td class="has-text-left"><small>{{pe.nome}}</small></td>
                                        <td class="has-text-left"><small>{{pe.email}}</small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>
            </div>

            <div class="column" v-show="this.mostraImages">
                <div class="card">
                    <header class="panel-heading has-background-grey">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Imagens do Perfil {{ this.perfilSelecionado.descricao }}</small>
                                </p>
                            </div>    
                            <div class="level-right">
                                <small><a v-on:click="mostraImages = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                            </div>
                        </nav>    
                    </header>

                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-narrow is-fullwidthtable is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th class="has-text-left"><small>Destino</small></th>
                                        <th class="has-text-left"><small>Imagem</small></th>
                                        <th colspan="2" class="has-text-centered"><small>Ação</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(pi, index) in perfilImagens" :key="pi.id">
                                        <td class="has-text-left" v-if="pi.tipo_destino_imagem=='1'"><small>Cabeçalho</small></td>
                                        <td class="has-text-left" v-if="pi.tipo_destino_imagem=='2'"><small>Rodapé</small></td>
                                        <td class="has-text-left" v-if="pi.tipo_destino_imagem=='3'"><small>Logomarca</small></td>
                                        <td class="has-text-left" v-if="pi.tipo_destino_imagem=='4'"><small>Capa do Relatório</small></td>
                                        <td class="has-text-left"><figure class="image is-64x64"><img :src="pi.imagem" /></figure></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="erase(index)"><span class="fas fa-trash-alt"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div>    
                    </div>
                </div>
            </div>

        </div>    
    </div>        

</template>

<script>
    import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "Perfil",

        data(){
            return{
                perfil: {
                    id: null,
                    descricao: null,
                    pais: 'BRASIL',
                    estado: '0',
                    cidade: null,
                    codigo_cidade: '0',
                    versao_plano_contas: null,
                    index: null
                },
                usuarioPerfil: {
                    id: null,
                    perfil: null,
                    usuario: null,
                    index: null
                },
                perfilEmail: {
                    id: null,
                    perfil: null,
                    nome: null,
                    email: null,
                    index: null
                },
                perfilImagem: {
                    id: null,
                    perfil: null,
                    tipo_destino_imagem: '1',
                    imagem: null
                },
                perfis: [],
                usuarios: [],
                usuariosPerfil: [],
                perfilEmails: [],
                perfilImagens: [],
                versoesPlanosContas: [],
                estados: [],
                cidades: [],
                previous: null,
                next: null,
                page: null,
                numpages: null,
                isShowModal: false,
                mostraCompartilhamento: false,
                mostraEmails: false,
                mostraImages: false,
                perfilSelecionado: {
                    id: null,
                    descricao: null
                },
                arquivoImagem: null,
                showInfo: false
            }
        },

        methods: {
            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                if (!this.mostraCompartilhamento && !this.mostraEmails && !this.mostraImages) {
                    this.perfil.id = this.perfis[index].id
                    this.perfil.descricao = this.perfis[index].descricao
                    this.perfil.pais = this.perfis[index].pais
                    this.perfil.estado = this.perfis[index].estado
                    this.perfil.cidade = this.perfis[index].cidade
                    this.perfil.codigo_cidade = this.perfis[index].codigo_cidade
                    this.perfil.versao_plano_contas = this.perfis[index].versao_plano_contas

                    http.get('https://servicodados.ibge.gov.br/api/v1/localidades/distritos/'+this.perfis[index].codigo_cidade).then(response => {
                        this.perfil.cidade = response.data[0]
                    })

                    this.perfil.index = index
                    this.isShowModal = true
                    this.selectEstado()

                } else if (this.mostraCompartilhamento) {
                    this.usuarioPerfil.id = this.usuariosPerfil[index].id
                    this.usuarioPerfil.usuario = this.usuariosPerfil[index].usuario
                    this.usuarioPerfil.perfil = this.usuariosPerfil[index].perfil
                    this.usuarioPerfil.index = index
                    this.isShowModal = true            
                } else if (this.mostraEmails) {
                    this.perfilEmail.id = this.perfilEmails[index].id
                    this.perfilEmail.nome = this.perfilEmails[index].nome
                    this.perfilEmail.email = this.perfilEmails[index].email
                    this.perfilEmail.perfil = this.perfilSelecionado
                    this.perfilEmail.index = index
                    this.isShowModal = true            
                } else {
                    this.perfilImagem.id = this.perfilImagens[index].id
                    this.perfilImagem.tipo_destino_imagem = this.perfilImagens[index].tipo_destino_imagem
                    this.perfilImagem.imagem = http.defaults.baseURL.replace("/api/", "")+this.perfilImagens[index].imagem
                    this.perfilImagem.perfil = this.perfilSelecionado
                    this.perfilImagem.index = index
                    this.isShowModal = true      
                }
            },

            clearFields: function() {
                if (!this.mostraCompartilhamento && !this.mostraEmails && !this.mostraImages) {
                    this.perfil = { id: null,
                    descricao: null,
                    pais: 'BRASIL',
                    cidade: null,
                    codigo_cidade: '0',
                    versao_plano_contas: null,
                    estado: '0'}
                } else if (this.mostraCompartilhamento) {
                    this.usuarioPerfil = { id: null,
                    perfil: this.perfilSelecionado,
                    usuario: null }
                } else if (this.mostraImages) {
                    this.perfilImagem = { id: null,
                        tipo_destino_imagem: '1',
                        imagem: null,
                        perfil: this.perfilSelecionado }
                } else {
                    this.perfilEmail = { id: null,
                        nome: null,
                        email: null,
                        perfil: this.perfilSelecionado
                    }
                    this.arquivoImagem = null
                }
            },

            callCompartilhamento: function(perfil) {
                this.mostraCompartilhamento = true
                this.perfilSelecionado = perfil
                http.get('compartilharPerfis/listar', {params: {
                    idPerfil: this.perfilSelecionado.id
                }}).then(response => {
                    this.usuariosPerfil = response.data.results
                })

            },

            callEmails: function(perfil) {
                this.mostraEmails = true
                this.perfilSelecionado = perfil
                http.get('perfilEmails/listar', {params: {
                    idPerfil: this.perfilSelecionado.id
                }}).then(response => {
                    this.perfilEmails = response.data.results
                })
            },

            callImages: function(perfil) {
                this.mostraImages = true
                this.perfilSelecionado = perfil
                http.get('perfilImagens/listar', {params: {
                    idPerfil: this.perfilSelecionado.id
                }}).then(response => {
                    this.perfilImagens = response.data.results
                    for (let index = 0; index < this.perfilImagens.length; index++) {
                        this.perfilImagens[index].imagem = http.defaults.baseURL.replace("/api/", "")+this.perfilImagens[index].imagem;
                    }
                })
            },

            uploadImageFile: function(evt) {
                this.perfilImagem.imagem = evt.target.files[0]
                this.arquivoImagem = evt.target.files[0].name
            },   

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {

                    if (!this.mostraCompartilhamento && !this.mostraEmails && !this.mostraImages) {
                        http.delete('/perfis/excluir/'+this.perfis[index].id).then(response =>{
                                this.perfis.splice(index, 1)
                                if (response.status == 204) {
                                    alert('Registro excluído!')
                                }
                            }).catch(e => {
                                console.log(e);
                            })
                    } else if (this.mostraCompartilhamento) {
                        http.delete('/compartilharPerfis/excluir/'+this.usuariosPerfil[index].id).then(response =>{
                                this.usuariosPerfil.splice(index, 1)
                                if (response.status == 204) {
                                    alert('Registro excluído!')
                                }
                            }).catch(e => {
                                console.log(e);
                            })
                    } else if (this.mostraImages) {
                        http.delete('/perfilImagens/excluir/'+this.perfilImagens[index].id).then(response =>{
                                this.perfilImagens.splice(index, 1)
                                if (response.status == 204) {
                                    alert('Registro excluído!')
                                }
                            }).catch(e => {
                                console.log(e);
                            })
                    } else {
                        http.delete('/perfilEmails/excluir/'+this.perfilEmails[index].id).then(response =>{
                                this.perfilEmails.splice(index, 1)
                                if (response.status == 204) {
                                    alert('Registro excluído!')
                                }
                            }).catch(e => {
                                console.log(e);
                            })
                    }
                }
            },

            save: function(){
                var data

                if (!this.mostraCompartilhamento && !this.mostraEmails && !this.mostraImages) {
                    if (this.perfil.descricao == null) {
                        alert('Descrição do perfil não informada!')
                        return
                    }

                    data = {id: this.perfil.id,
                        descricao: this.perfil.descricao,
                        pais: this.perfil.pais,
                        estado: this.perfil.estado,
                        cidade: this.perfil.cidade.nome,
                        codigo_cidade: this.perfil.cidade.id,
                        versao_plano_contas: null,
                        usuario: this.$store.state.idUser
                    }

                    if (this.perfil.versao_plano_contas != null) {
                        data.versao_plano_contas = this.perfil.versao_plano_contas.id
                    }    

                    if (this.perfil.id == null) {
                        http.post('/perfis/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.perfil.id = response.data.id
                                this.perfil.codigo_cidade = this.perfil.cidade.id
                                this.perfis.push(this.perfil)
                                this.showInfo = true
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })

                    } else {
                        http.put('/perfis/editar/'+this.perfil.id, data).then(response =>{
                            this.perfis[this.perfil.index].descricao = response.data.descricao
                            this.perfis[this.perfil.index].pais = response.data.pais
                            this.perfis[this.perfil.index].estado = response.data.estado
                            this.perfis[this.perfil.index].cidade = response.data.cidade
                            this.perfis[this.perfil.index].codigo_cidade = response.data.codigo_cidade
                            this.perfis[this.perfil.index].versao_plano_contas = this.perfil.versao_plano_contas
                        }).catch(e => {
                            console.log(e);
                        })
                    }

                } else if (this.mostraCompartilhamento) {
                    if (this.usuarioPerfil.usuario == null) {
                        alert('Usuário não informado!')
                        return
                    }

                    data = {id: this.usuarioPerfil.id,
                        perfil: this.usuarioPerfil.perfil.id,
                        usuario: this.usuarioPerfil.usuario.id
                    }

                    if (this.usuarioPerfil.id == null) {
                        http.post('/compartilharPerfis/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.usuarioPerfil.id = response.data.id
                                this.usuariosPerfil.push(this.usuarioPerfil)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })

                    } else {
                        http.put('/compartilharPerfis/editar/'+this.usuarioPerfil.id, data).then(
                            this.usuariosPerfil[this.usuarioPerfil.index].usuario = this.usuarioPerfil.usuario
                        ).catch(e => {
                            console.log(e);
                        })
                    }

                    
                } else if (this.mostraImages) {
                    if (this.perfilImagem.tipo_destino_imagem == null) {
                        alert('Destino da imagem não selecionado!')
                        return
                    }

                    if (this.perfilImagem.imagem == null) {
                        alert('Imagem não selecionada!')
                        return
                    }

                    data = {id: this.perfilImagem.id,
                        perfil: this.perfilImagem.perfil.id,
                        tipo_destino_imagem: this.perfilImagem.tipo_destino_imagem,
                        imagem: this.perfilImagem.imagem}

                    let formData = new FormData()

                    formData.append('id', data.id)
                    formData.append('perfil', data.perfil)
                    formData.append('tipo_destino_imagem', data.tipo_destino_imagem)
                    formData.append('imagem', data.imagem)

                    if (this.perfilImagem.id == null) {
                        http.post('/perfilImagens/adicionar', formData, {
                            headers: {
                                    "Content-Type": "multipart/form-data",
                            }}).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.perfilImagem.id = response.data.id
                                this.perfilImagem.imagem = http.defaults.baseURL.replace("/api/", "")+response.data.imagem
                                this.perfilImagens.push(this.perfilImagem)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })

                    } else {
                        http.put('/perfilImagens/editar/'+this.perfilImagem.id, formData, {
                            headers: {
                                    "Content-Type": "multipart/form-data",
                            }}).then(response =>{
                            this.perfilImagens[this.perfilImagem.index].tipo_destino_imagem = response.data.tipo_destino_imagem
                            this.perfilImagens[this.perfilImagem.index].imagem = http.defaults.baseURL.replace("/api/", "")+response.data.imagem
                        }).catch(e => {
                            alert(e.response.data);
                            return
                        })
                    }

                } else {
                    if (this.perfilEmail.nome == null) {
                        alert('Nome não informado!')
                        return
                    }

                    if (this.perfilEmail.email == null) {
                        alert('Email não informado!')
                        return
                    }

                    data = {id: this.perfilEmail.id,
                        perfil: this.perfilEmail.perfil.id,
                        nome: this.perfilEmail.nome,
                        email: this.perfilEmail.email                    }

                    if (this.perfilEmail.id == null) {
                        http.post('/perfilEmails/adicionar', data).then(response =>{
                            if (response.data.id != undefined && response.data.id != '') {
                                this.perfilEmail.id = response.data.id
                                this.perfilEmails.push(this.perfilEmail)
                                this.clearFields()
                            } else {
                                alert(response.request.responseText)
                            }
                        }).catch(e => {
                            console.log(e);
                        })

                    } else {
                        http.put('/perfilEmails/editar/'+this.perfilEmail.id, data).then(
                            this.perfilEmails[this.perfilEmail.index].nome = this.perfilEmail.nome
                        ).catch(e => {
                            console.log(e);
                        })
                    }
                }
            },

            selectEstado: function() {
                http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+this.perfil.estado+'/distritos').then(response => {
                    this.cidades = _.orderBy(response.data, ['nome'])
                    
                })
            },

            nextPage: function(){
                if (this.page != this.numpages) {
                    http.get(this.next).then(response =>{
                        this.perfis = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },
            previousPage: function(){
                if (this.page != 1 ) {
                    http.get(this.previous).then(response =>{
                        this.perfis = response.data.results
                        this.previous = response.data.previous
                        this.next = response.data.next
                        this.page = response.data.page
                        this.numpages = response.data.numpages
                    })
                }
            },
            filterRecords: function(){
            }
        },

        mounted: function() {
        },
        
        created: function() {
            http.get('perfis/listar').then(response =>{
                this.perfis = response.data.results
                if (this.perfis.length > 0) {
                    this.showInfo = true
                }
            })

            http.get('usuarios/listar').then(response =>{
                this.usuarios = response.data.results
            })

            http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').then(response =>{
                this.estados = _.orderBy(response.data, ['sigla'])
                
            })

            http.get('versoesPlanoContas/listar').then(response => {
                this.versoesPlanosContas = response.data.results
            })



        }

    }
</script>

<style scoped>
</style>