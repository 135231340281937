<template>
    <div>
        <br>
        <h5 class="title is-5">Outras Informações
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="processar()" :disabled="this.cmbPeriodoPerfil.estaFechado">
                Processar
            </button>  
        </h5>

        <form>
            <div class="field is-horizontal">
                <div class="field-body">

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbTipoPeriodo" @change="selectTipoPeriodo()">
                                    <option value="0" disabled>Selecione um tipo de período</option>
                                    <option v-for="td in tiposPeriodo" :key="td.id" :value="td.id">
                                        {{td.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPerfil" @change="selectPerfil()">
                                    <option value="0" disabled>Selecione um perfil</option>
                                    <option v-for="perfilUsuario in perfisUsuario" :key="perfilUsuario.id" :value="perfilUsuario.perfil.id">
                                        {{perfilUsuario.perfil.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbPeriodoPerfil" @change="selectPeriodoPerfil()">
                                    <option value="0" disabled>Selecione um período</option>
                                    <option v-for="periodoPerfil in periodosPerfis" :key="periodoPerfil.id" :value="periodoPerfil">
                                        {{periodoPerfil.periodo.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-narrow">
                                <select v-model="cmbOutrasInformacoesVersao">
                                    <option value="0" disabled>Selecione um quadro de outras informações</option>
                                    <option v-for="oiv in outrasInformacoesVersao" :key="oiv.id" :value="oiv.outras_informacoes_versao">
                                        {{oiv.outras_informacoes_versao.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>

            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Editar</p>
                    <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form>
                        <div class="field">
                            <label class="label">{{labelLinhaEdicao}}</label>
                            <div class="control">
                                <input class="input" type="text" placeholder="Informe o valor para essa linha" v-model="outrasInformacoesPeriodo.valor">
                            </div>
                        </div>                        
                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns" v-show="this.showInfo">
            <div class="column">
                <div class="table-container">                
                    <table class="table is-fullwidth is-hoverable">
                        <thead>
                            <tr>
                                <th class="has-text-left is-size-7"><small>Informações</small></th>
                                <th class="has-text-centered is-size-7"><small>Editar</small></th>
                                <th class="has-text-right is-size-7"><small>{{anoMesReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                <th class="has-text-right is-size-7"><small>{{anoMesMesAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                <th class="has-text-right is-size-7 has-text-grey-light">AH%</th>
                                <th class="has-text-right is-size-7" v-if="cmbTipoPeriodo != 6"><small>{{anoMesAnoAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                <th class="has-text-right is-size-7 has-text-grey-light" v-if="cmbTipoPeriodo != 6">AH%</th>
                                <th class="has-text-right is-size-7"><small>{{anoMes3AnosAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                <th class="has-text-right is-size-7 has-text-grey-light">AH%</th>
                                <th class="has-text-right is-size-7"><small>{{anoMes5AnosAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                <th class="has-text-right is-size-7 has-text-grey-light">AH%</th>
                                <th class="has-text-right is-size-7"><small>{{anoMes10AnosAnteriorReferencia|datePorTipo(cmbTipoPeriodo)}}</small></th>
                                <th class="has-text-right is-size-7 has-text-grey-light">AH%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(oip, index) in linhasOutrasInformacoes" :key="oip.id">
                                <td class="is-size-7"><small><strong>{{oip.outras_informacoes_versao_config.descricao}}</strong></small></td>
                                <td class="has-text-centered"><small><button class="button is-ghost is-small is-inverted is-link" @click="edit(index)" :disabled="oip.outras_informacoes_versao_config.e_calculado"><span class="fas fa-edit"></span></button></small></td>
                                <td class="has-text-right is-size-7"><small>{{oip.valor|numberFormat}}</small></td>
                                
                                <td class="has-text-right is-size-7"><small>{{oip.valorMesAnterior|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-success has-text-weight-bold" v-if="oip.cor_ah_mes_anterior=='1'"><small>{{oip.perc_ah_mes_anterior|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-danger has-text-weight-bold" v-if="oip.cor_ah_mes_anterior=='2'"><small>{{oip.perc_ah_mes_anterior|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-info has-text-weight-bold" v-if="oip.cor_ah_mes_anterior=='3'"><small>{{oip.perc_ah_mes_anterior|numberFormat}}</small></td>

                                <td class="has-text-right is-size-7" v-if="cmbTipoPeriodo != 6"><small>{{oip.valorAnoAnterior|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-success has-text-weight-bold" v-if="oip.cor_ah_ano_anterior=='1' && cmbTipoPeriodo != 6"><small>{{oip.perc_ah_ano_anterior|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-danger has-text-weight-bold" v-if="oip.cor_ah_ano_anterior=='2' && cmbTipoPeriodo != 6"><small>{{oip.perc_ah_ano_anterior|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-info has-text-weight-bold" v-if="oip.cor_ah_ano_anterior=='3' && cmbTipoPeriodo != 6"><small>{{oip.perc_ah_ano_anterior|numberFormat}}</small></td>

                                <td class="has-text-right is-size-7"><small>{{oip.valor3AnosAnteriores|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-success has-text-weight-bold" v-if="oip.cor_ah_3anos_anteriores=='1'"><small>{{oip.perc_ah_3anos_anteriores|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-danger has-text-weight-bold" v-if="oip.cor_ah_3anos_anteriores=='2'"><small>{{oip.perc_ah_3anos_anteriores|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-info has-text-weight-bold" v-if="oip.cor_ah_3anos_anteriores=='3'"><small>{{oip.perc_ah_3anos_anteriores|numberFormat}}</small></td>

                                <td class="has-text-right is-size-7"><small>{{oip.valor5AnosAnteriores|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-success has-text-weight-bold" v-if="oip.cor_ah_5anos_anteriores=='1'"><small>{{oip.perc_ah_5anos_anteriores|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-danger has-text-weight-bold" v-if="oip.cor_ah_5anos_anteriores=='2'"><small>{{oip.perc_ah_5anos_anteriores|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-info has-text-weight-bold" v-if="oip.cor_ah_5anos_anteriores=='3'"><small>{{oip.perc_ah_5anos_anteriores|numberFormat}}</small></td>

                                <td class="has-text-right is-size-7"><small>{{oip.valor10AnosAnteriores|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-success has-text-weight-bold" v-if="oip.cor_ah_10anos_anteriores=='1'"><small>{{oip.perc_ah_10anos_anteriores|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-danger has-text-weight-bold" v-if="oip.cor_ah_10anos_anteriores=='2'"><small>{{oip.perc_ah_10anos_anteriores|numberFormat}}</small></td>
                                <td class="has-text-right is-size-7 has-text-info has-text-weight-bold" v-if="oip.cor_ah_10anos_anteriores=='3'"><small>{{oip.perc_ah_10anos_anteriores|numberFormat}}</small></td>
                            </tr>
                        </tbody>    
                    </table>
                </div>    
            </div>

            <br>

        </div>   

    </div>        

</template>

<script>
    // import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "OutrasInformacoesPeriodo",

        data(){
            return{
                periodosPerfis: [],
                perfisUsuario: [],
                outrasInformacoesVersao: [],
                outrasInformacoesPeriodo: {id: null,
                    periodo_perfil: null,
                    outras_informacoes_versao_config: null,
                    valor: null,
                    valorMesAnterior: null,
                    valorAnoAnterior: null,
                    valor3AnosAnteriores: null,
                    valor5AnosAnteriores: null,
                    valor10AnosAnteriores: null,
                    perc_ah_mes_anterior: null,
                    perc_ah_ano_anterior: null,
                    perc_ah_3anos_anteriores: null,
                    perc_ah_5anos_anteriores: null,
                    perc_ah_10anos_anteriores: null,
                    status_ah_mes_anterior: null,
                    status_ah_ano_anterior: null,
                    status_ah_3anos_anteriores: null,
                    status_ah_5anos_anteriores: null,
                    status_ah_10anos_anteriores: null,
                    cor_ah_mes_anterior: null,
                    cor_ah_ano_anterior: null,
                    cor_ah_3anos_anteriores: null,
                    cor_ah_5anos_anteriores: null,
                    cor_ah_10anos_anteriores: null
                },                
                linhasOutrasInformacoes: [],
                tiposPeriodo: [],
                cmbPerfil: 0,
                cmbPeriodoPerfil: 0,
                cmbOutrasInformacoesVersao: 0,
                cmbTipoPeriodo: 0,
                anoMesReferencia: "",
                anoMesMesAnteriorReferencia: "",
                anoMesAnoAnteriorReferencia: "",
                anoMes3AnosAnteriorReferencia: "",
                anoMes5AnosAnteriorReferencia: "",
                anoMes10AnosAnteriorReferencia: "",
                showInfo: false,
                isShowModal: false,
                labelLinhaEdicao: null
            }
        },

        methods: {
            filterRecords: function(){
                if (this.cmbPerfil == 0) {
                    alert('Selecione um perfil')
                    return
                }
                if (this.cmbPeriodoPerfil == 0) {
                    alert('Selecione um período')
                    return
                }
                if (this.cmbOutrasInformacoesVersao == 0) {
                    alert('Selecione um o que seja visualizar')
                    return
                }
                http.get('outrasInformacoesPeriodo/listar/', {params: {
                    idPeriodoPerfil: this.cmbPeriodoPerfil.id
                }}).then(response => {
                    this.linhasOutrasInformacoes = response.data.results

                    if (this.linhasOutrasInformacoes.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })

            },

            selectTipoPeriodo: function() {
                this.cmbPerfil = 0
                this.cmbPeriodoPerfil = 0
                this.cmbOutrasInformacoesVersao = 0
                this.linhasOutrasInformacoes = []
                this.anoMesReferencia = ""
                this.anoMesMesAnteriorReferencia = ""
                this.anoMesAnoAnteriorReferencia = ""
                this.anoMes3AnosAnteriorReferencia = ""
                this.anoMes5AnosAnteriorReferencia = ""
                this.anoMes10AnosAnteriorReferencia = ""
            },

            selectPerfil: function() {
                http.get('periodoPerfil/listar/', {params: {
                    idPerfil: this.cmbPerfil,
                    idTipoPeriodo: this.cmbTipoPeriodo,
                    semPaginacao: 'S'
                }}).then(response => {
                    this.periodosPerfis = response.data.results
                    if (this.periodosPerfis.length == 0) {
                        alert('Não há períodos associados ao perfil selecionado!')
                    }
                })
            },

            selectPeriodoPerfil: function() {
                http.get('outrasInformacoesVersao/listarOutrasInformacoes/', {params: {
                    idVersaoPlanoContas: this.cmbPeriodoPerfil.versao_plano_contas.id,
                }}).then(response => {
                    this.outrasInformacoesVersao = response.data.results
                    this.anoMesReferencia = this.cmbPeriodoPerfil.periodo.data
                    this.anoMesMesAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_mes_anterior
                    this.anoMesAnoAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_ano_anterior
                    this.anoMes3AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_3anos_anteriores
                    this.anoMes5AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_5anos_anteriores
                    this.anoMes10AnosAnteriorReferencia = this.cmbPeriodoPerfil.periodo.data_10anos_anteriores

                    if (this.outrasInformacoesVersao.length == 0) {
                        alert('Não há outras informações associadas a versão associada ao período selecionado!')
                    }
                })

                if (this.cmbOutrasInformacoesVersao != 0) {
                    this.filterRecords()
                }
            },

            edit: function(index){
                this.outrasInformacoesPeriodo.id = this.linhasOutrasInformacoes[index].id
                this.outrasInformacoesPeriodo.periodo_perfil = this.linhasOutrasInformacoes[index].periodo_perfil
                this.outrasInformacoesPeriodo.outras_informacoes_versao_config = this.linhasOutrasInformacoes[index].outras_informacoes_versao_config
                this.outrasInformacoesPeriodo.valor = this.linhasOutrasInformacoes[index].valor
                this.outrasInformacoesPeriodo.valorMesAnterior = this.linhasOutrasInformacoes[index].valorMesAnterior
                this.outrasInformacoesPeriodo.valorAnoAnterior = this.linhasOutrasInformacoes[index].valorAnoAnterior
                this.outrasInformacoesPeriodo.valor3AnosAnteriores = this.linhasOutrasInformacoes[index].valor3AnosAnteriores
                this.outrasInformacoesPeriodo.valor5AnosAnteriores = this.linhasOutrasInformacoes[index].valor5AnosAnteriores
                this.outrasInformacoesPeriodo.valor10AnosAnteriores = this.linhasOutrasInformacoes[index].valor10AnosAnteriores
                this.outrasInformacoesPeriodo.perc_ah_mes_anterior = this.linhasOutrasInformacoes[index].perc_ah_mes_anterior
                this.outrasInformacoesPeriodo.perc_ah_ano_anterior = this.linhasOutrasInformacoes[index].perc_ah_ano_anterior
                this.outrasInformacoesPeriodo.perc_ah_3anos_anteriores = this.linhasOutrasInformacoes[index].perc_ah_3anos_anteriores
                this.outrasInformacoesPeriodo.perc_ah_5anos_anteriores = this.linhasOutrasInformacoes[index].perc_ah_5anos_anteriores
                this.outrasInformacoesPeriodo.perc_ah_10anos_anteriores = this.linhasOutrasInformacoes[index].perc_ah_10anos_anteriores
                this.outrasInformacoesPeriodo.status_ah_mes_anterior = this.linhasOutrasInformacoes[index].status_ah_mes_anterior
                this.outrasInformacoesPeriodo.status_ah_ano_anterior = this.linhasOutrasInformacoes[index].status_ah_ano_anterior
                this.outrasInformacoesPeriodo.status_ah_3anos_anteriores = this.linhasOutrasInformacoes[index].status_ah_3anos_anteriores
                this.outrasInformacoesPeriodo.status_ah_5anos_anteriores = this.linhasOutrasInformacoes[index].status_ah_5anos_anteriores
                this.outrasInformacoesPeriodo.status_ah_10anos_anteriores = this.linhasOutrasInformacoes[index].status_ah_10anos_anteriores
                this.outrasInformacoesPeriodo.cor_ah_mes_anterior = this.linhasOutrasInformacoes[index].cor_ah_mes_anterior
                this.outrasInformacoesPeriodo.cor_ah_ano_anterior = this.linhasOutrasInformacoes[index].cor_ah_ano_anterior
                this.outrasInformacoesPeriodo.cor_ah_3anos_anteriores = this.linhasOutrasInformacoes[index].cor_ah_3anos_anteriores
                this.outrasInformacoesPeriodo.cor_ah_5anos_anteriores = this.linhasOutrasInformacoes[index].cor_ah_5anos_anteriores
                this.outrasInformacoesPeriodo.cor_ah_10anos_anteriores = this.linhasOutrasInformacoes[index].cor_ah_10anos_anteriores
                this.outrasInformacoesPeriodo.index = index
                this.isShowModal = true  
                this.labelLinhaEdicao = this.outrasInformacoesPeriodo.outras_informacoes_versao_config.descricao
            },

            save: function(){
                var data
                
                data = {id: this.outrasInformacoesPeriodo.id,
                    periodo_perfil: this.outrasInformacoesPeriodo.periodo_perfil.id,
                    outras_informacoes_versao_config: this.outrasInformacoesPeriodo.outras_informacoes_versao_config.id,
                    valor: this.outrasInformacoesPeriodo.valor,
                    valorMesAnterior: this.outrasInformacoesPeriodo.valorMesAnterior,
                    valorAnoAnterior: this.outrasInformacoesPeriodo.valorAnoAnterior,
                    valor3AnosAnteriores: this.outrasInformacoesPeriodo.valor3AnosAnteriores,
                    valor5AnosAnteriores: this.outrasInformacoesPeriodo.valor5AnosAnteriores,
                    valor10AnosAnteriores: this.outrasInformacoesPeriodo.valor10AnosAnteriores,
                    perc_ah_mes_anterior: this.outrasInformacoesPeriodo.perc_ah_mes_anterior,
                    perc_ah_ano_anterior: this.outrasInformacoesPeriodo.perc_ah_ano_anterior,
                    perc_ah_3anos_anteriores: this.outrasInformacoesPeriodo.perc_ah_3anos_anteriores,
                    perc_ah_5anos_anteriores: this.outrasInformacoesPeriodo.perc_ah_5anos_anteriores,
                    perc_ah_10anos_anteriores: this.outrasInformacoesPeriodo.perc_ah_10anos_anteriores,
                    status_ah_mes_anterior: this.outrasInformacoesPeriodo.status_ah_mes_anterior,
                    status_ah_ano_anterior: this.outrasInformacoesPeriodo.status_ah_ano_anterior,
                    status_ah_3anos_anteriores: this.outrasInformacoesPeriodo.status_ah_mes_anterior,
                    status_ah_5anos_anteriores: this.outrasInformacoesPeriodo.status_ah_5anos_anteriores,
                    status_ah_10anos_anteriores: this.outrasInformacoesPeriodo.status_ah_10anos_anteriores,
                    cor_ah_mes_anterior: this.outrasInformacoesPeriodo.cor_ah_mes_anterior,
                    cor_ah_ano_anterior: this.outrasInformacoesPeriodo.cor_ah_ano_anterior,
                    cor_ah_3anos_anteriores: this.outrasInformacoesPeriodo.cor_ah_3anos_anteriores,
                    cor_ah_5anos_anteriores: this.outrasInformacoesPeriodo.cor_ah_5anos_anteriores,
                    cor_ah_10anos_anteriores: this.outrasInformacoesPeriodo.cor_ah_10anos_anteriores
                }
                http.put('/outrasInformacoesPeriodo/editar/'+this.outrasInformacoesPeriodo.id, data).then(response =>{
                    this.linhasOutrasInformacoes[this.outrasInformacoesPeriodo.index].valor = response.data.valor
                }).catch(e => {
                    console.log(e);
                })
            },

            processar: function() {
                if (this.cmbPerfil == 0) {
                    alert('Perfil não selecionado!')
                    return
                }

                if (this.cmbPeriodoPerfil == 0) {
                    alert('Período não selecionado!')
                    return
                }

                if (this.cmbOutrasInformacoesVersao == 0) {
                    alert('Outras informações não selecionada!')
                    return
                }

                if (window.confirm('Confirma o processamento?')) {
                    http.put('/outrasInformacoesPeriodo/processar/'+this.cmbPeriodoPerfil.id, this.cmbOutrasInformacoesVersao).then(response =>{
                        if (response.status == 204) {
                            this.filterRecords()
                            alert('Processamento concluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }

            }

        },

        mounted: function() {
        },
        
        created: function() {
            http.get('compartilharPerfis/listarPerfis').then(response => {
                    this.perfisUsuario = response.data.results
            })

            http.get('tiposPeriodo/listar').then(response => {
                this.tiposPeriodo = response.data.results
            })

        }

    }
</script>

<style scoped>
</style>