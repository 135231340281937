<template>
    <div>
        <br>
        <h5 class="title is-5">Ativos
            <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
                Cadastrar
            </button>  
        </h5>
        <form>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbAssetClass">
                                    <option value="0">TODAS AS CLASSES DE ATIVOS</option>
                                    <option v-for="assetclass in assetsclasses" :key="assetclass.id" :value="assetclass.id">
                                        {{assetclass.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  
                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>
            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Lançar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Classe</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="asset.classeAtivo">
                                            <option v-for="assetClass in assetsclasses" :key="assetClass.id" :value="assetClass">
                                                {{assetClass.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Empresa</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="asset.empresa">
                                            <option v-for="empresa in empresas" :key="empresa.id" :value="empresa">
                                                {{empresa.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Código</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-narrow">
                                    <input class="input" type="text" placeholder="Informe o codigo" v-model="asset.codigo">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Descrição</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-full">
                                    <input class="input" type="text" placeholder="Informe a descrição" v-model="asset.descricao">
                                </div>
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Moeda</small></label>
                            </div>  

                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select v-model="asset.moeda">
                                                <option value="BRL">Real</option>
                                                <option value="USD">Dólar</option>
                                                <option value="EUR">Euro</option>
                                                <option value="GBP">Libra Esterlina</option>
                                            </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Gera Renda?</small></label>
                            </div>  

                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select v-model="asset.geraRenda">
                                                <option value="N">Não</option>
                                                <option value="S">Sim</option>
                                            </select>
                                        </div>    
                                    </div>
                                </div>    
                            </div>
                        </div>  

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Segmento</small></label>
                            </div>                            

                            <div class="field-body">
                                <div class="field is-full">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="asset.segmento">
                                            <option value="0">Nenhum</option>
                                            <option v-for="segmento in segmentos" :key="segmento.id" :value="segmento">
                                                {{segmento.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns" v-show="this.showInfo">
            <div class="column"></div>
            <div class="column is-three-quarters">
                <div class="table-container">                
                    <table class="table is-narrow is-hoverable is-fullwidth is-striped">
                        <thead>
                            <tr>
                                <th><small>Classe de Ativo</small></th>
                                <th><small>Código</small></th>
                                <th><small>Descrição</small></th>
                                <th colspan="2" class="has-text-center"><small>Ações</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(asset, index) in assets" :key="asset.id">
                                <td><small>{{asset.classeAtivo.descricao}}</small></td>
                                <td><small>{{asset.codigo}}</small></td>
                                <td><small>{{asset.descricao}}</small></td>
                                <td class="has-text-center"><small><a @click="edit(index)"><span class="fas fa-edit"></span></a></small></td>
                                <td class="has-text-center"><small><a @click="erase(index)"><span class="fas fa-trash-alt"></span></a></small></td>
                            </tr>
                        </tbody>    

                    </table>
                </div>    

                <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                    <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.page == 1 || this.page == null">Anterior</a>
                    <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.page == this.numpages">Próxima</a>                
                </nav>
                <br>
            </div>
            <div class="column"></div>

        </div>    

    </div>        

</template>

<script>
    import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "Ativo",

        data(){
            return{
                asset: {
                    id: null,
                    descricao: null,
                    codigo: null,
                    classeAtivo: null,
                    empresa: null,
                    moeda: 'BRL',
                    geraRenda: 'N',
                    segmento: null,
                    index: null
                },
                assetsclasses: [],
                segmentos: [],
                empresas: [],
                assets: [],
                cmbAssetClass: null,
                previous: null,
                next: null,
                page: null,
                numpages: null,
                isShowModal: false,
                showInfo: false
            }
        },

        methods: {
            callListAPI: function(nome) {
                http.get(nome, {params: {
                    cmbClasseAtivo: this.cmbAssetClass,
                    paginacao: 'S'
                }}).then(response =>{
                    this.assets = response.data.results
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages
                    if (this.assets.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })
            },

            clearFields: function() {
                this.asset = { id: null,
                    descricao: null,
                    codigo: null,
                    classeAtivo: null,
                    empresa: null,
                    moeda: 'BRL',
                    geraRenda: 'N',
                    segmento: null,
                    index: null }
            },

            add: function() {
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                this.asset.id = this.assets[index].id
                this.asset.descricao = this.assets[index].descricao
                this.asset.codigo = this.assets[index].codigo
                this.asset.classeAtivo = this.assets[index].classeAtivo
                this.asset.empresa = this.assets[index].empresa
                this.asset.moeda = this.assets[index].moeda
                this.asset.geraRenda = this.assets[index].geraRenda
                this.asset.segmento = this.assets[index].segmento
                this.asset.index = index
                this.isShowModal = true              
            },

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    http.delete('/ativos/excluir/'+this.assets[index].id).then(response =>{
                        this.assets.splice(index, 1)
                        if (response.status == 204) {
                            alert('Registro excluído!')
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            save: function(){

                if (this.asset.classeAtivo == null) {
                    alert('Classe de ativo não informada!')
                    return
                }

                if (this.asset.empresa == null) {
                    alert('Empresa não informada!')
                    return
                }

                if (this.asset.codigo == null) {
                    alert('Código do ativo não informado!')
                    return
                }

                if (this.asset.descricao == null) {
                    alert('Descrição do ativo não preenchida!')
                    return
                }

                if (this.asset.moeda == null) {
                    alert('Moeda do ativo não preenchida!')
                    return
                }

                var data = {id: this.asset.id,
                    classeAtivo: this.asset.classeAtivo.id,
                    empresa: this.asset.empresa.id,
                    codigo: this.asset.codigo,
                    descricao: this.asset.descricao,
                    moeda: this.asset.moeda,
                    geraRenda: this.asset.geraRenda,
                    segmento: null
                }

                if (this.asset.segmento == "0" || this.asset.segmento == null) {
                    data.segmento = null
                } else {
                    data.segmento = this.asset.segmento.id
                }


                if (this.asset.id == null) {
                    http.post('/ativos/adicionar', data).then(response =>{
                        if (response.data.id != undefined && response.data.id != '') {
                            this.asset.id = response.data.id
                            this.assets.push(this.asset)
                            this.showInfo = true
                            this.clearFields()
                        } else {
                            alert(response.request.responseText)
                        }
                    }).catch(e => {
                        console.log(e);
                    })

                } else {
                    http.put('/ativos/editar/'+this.asset.id, data).then(response =>{
                        this.assets[this.asset.index].classeAtivo = this.asset.classeAtivo
                        this.assets[this.asset.index].empresa = this.asset.empresa
                        this.assets[this.asset.index].codigo = response.data.codigo
                        this.assets[this.asset.index].descricao = response.data.descricao
                        this.assets[this.asset.index].moeda = this.asset.moeda
                        this.assets[this.asset.index].geraRenda = this.asset.geraRenda
                        this.assets[this.asset.index].segmento = this.asset.segmento
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            nextPage: function(){
                if (this.page != this.numpages) {
                    this.callListAPI(this.next)
                }
            },
            previousPage: function(){
                if (this.page != 1 ) {
                    this.callListAPI(this.previous)
                }
            },
            filterRecords: function(){
                this.callListAPI('ativos/listar/')
            }
        },

        mounted: function() {
            http.get('classesativos/').then(response =>{
                this.assetsclasses = _.orderBy(response.data.results, ['descricao'], ['asc'])
                this.cmbAssetClass = "0"
            })

            http.get('empresas/listar', {params: {
                criteria: 0,
                argument: null,
                paginacao: "N"
            }}).then(response =>{
                this.empresas = response.data.results
                this.previous = response.data.previous
                this.next = response.data.next
                this.page = response.data.page
                this.numpages = response.data.numpages
            })
            http.get('segmentos/listar').then(response =>{
                this.segmentos = response.data.results
            })
        },
        
        created: function() {
        }

    }
</script>

<style scoped>
</style>